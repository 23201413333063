import {Injectable} from '@angular/core';
import {IDiscount} from '../model/discount';
import {DiscountsStore} from './discounts-store';
import {Facade} from './facade';
import {Observable} from "rxjs";
import {map} from "rxjs/operators";

@Injectable({ providedIn: 'root' })
export class DiscountsFacade extends Facade<IDiscount[]> {

    constructor(protected store: DiscountsStore) {
        super(store);
    }

    findByPackageTypeCode(packageTypeCode: string): Observable<IDiscount[]> {
        return this.store.state$.pipe(
            map((discounts) => discounts.filter((discount) => discount.packageTypeCode === packageTypeCode))
        );
    }
}
import {Injectable} from '@angular/core';
import {take} from 'rxjs/operators';
import {PolicyFacade} from 'src/app/shared/stores/policy-facade';
import {environment} from '../../../environments/environment';
import {Logger} from '../../root/logger/logger';

@Injectable({providedIn: 'root'})
export class TealiumService {
    script_src: string = '';

    // Typically set "noview" flag (no first page automatic view event) to true for Single Page Apps (SPAs)
    constructor(private policyFacade: PolicyFacade, private logger: Logger) {
        (window as any).utag_cfg_ovrd = {noview: true};
        (window as any).utag_data = {};
    }

    // Generic script loader with callback
    getScript(src: string, callback: Function) {
        if (!environment.enableTealium) {
            return;
        }

        const d = document;
        const o = {
            callback: callback || function() {
            }
        };
        let s, t;

        if (typeof src == 'undefined') {
            return;
        }

        s = d.createElement('script');
        s.language = 'javascript';
        s.type = 'text/javascript';
        s.async = 1;
        s.charset = 'utf-8';
        s.src = src;
        if (typeof o.callback == 'function') {
            if (d.addEventListener) {
                s.addEventListener('load', function() {
                    o.callback();
                }, false);
            } else {
                // old IE support
                s.onreadystatechange = function() {
                    if (this.readyState === 'complete' || this.readyState === 'loaded') {
                        this.onreadystatechange = null;
                        o.callback();
                    }
                };
            }
        }
        t = d.getElementsByTagName('script')[0];
        t.parentNode.insertBefore(s, t);
    }

    // Config settings used to build the path to the utag.js file
    setConfig(config: { account: string, profile: string, environment: string }) {
        if (!environment.enableTealium) {
            return;
        }

        if (config.account !== undefined && config.profile !== undefined && config.environment !== undefined) {
            this.script_src = 'https://tags.tiqcdn.com/utag/' + config.account + '/' + config.profile + '/' + config.environment + '/utag.js';
            this.logger.info('Tealium config set to ' + this.script_src);

        }
    }

    // Data layer is optional set of key/value pairs
    track(tealium_event: string, data?: any) {
        if (!environment.enableTealium) {
            return;
        }

        if (this.script_src === '') {
            this.logger.warn('Tealium config not set');
            return;
        }

        if (!((window as any).utag)) {
            this.getScript(this.script_src, () => {
                if ((window as any).utag) {
                    (window as any).utag.track(tealium_event, data);
                } else {
                    this.logger.warn('No utag object found in window');
                }
            });
        } else {
            (window as any).utag.track(tealium_event, data);
        }
    }

    view(data?: any) {
        this.policyFacade.state.pipe(take(1)).subscribe((policy) => {
            if (policy.sourceOfBusiness !== 'AI') {
                this.track('view', data);
            } else {
                return;
            }
        });
    }

    link(data?: any) {
        this.track('link', data);
    }

/////// DO NOT CHANGE CODE ABOVE THIS * ABOVE CODE IS DEFAULT TELIUM CODE /////////////////////////////

    tealiumViewLoad(sectionCode: string, payload: any): void {
        this.policyFacade.state.pipe(take(1)).subscribe((policy) => {
            if (policy.sourceOfBusiness !== 'AI') {
                const defaultPageLoad = this.getTealiumDefaultViewLoad(sectionCode);
                const finalPageLoad = {...defaultPageLoad, ...payload};
                this.view(finalPageLoad);
            } else {
                return;
            }
        });
    }

    tealiumSessionStart() {
        this.view({evar100: 'newSession'});
    }

    private getTealiumDefaultViewLoad(sectionCode: string): any {

        const sectionDetails = this.getSectionDetails(sectionCode);
        const display = 'web'; // conditionally write mobile tablet native

        return {
            page_type: sectionDetails.page_type, // The Tealium variable to identify the type of page or template eg. home,section,category,search,content,etc.
            page_name: sectionDetails.subSection, // The user-friendly name of the page
            site_section: 'Quote', // The top-level section of your site eg. Travel,Sports,News,etc.
            site_subsection1: sectionDetails.site_subsection1, // The 1st level category of the page hierarchy
            site_subsection2: sectionDetails.site_subsection2, // The 2nd level category of the page hierarchy
            site_language_code: 'en', // The two-character language code of the site eg. us,es,fr,etc.
            site_country_code: 'us', // The two-character country code of the site eg. us,uk,mx,ca,jp,etc.
            site_display_format: display, // The display format of the site in view eg. 'desktop','tablet',or 'mobile'

        };

    }

    // tslint:disable-next-line:max-func-body-length
    private getSectionDetails(sectionCode: string): ISectionDetails {

        let section: ISectionDetails;
        let subSection = '';
        let page_type: string;
        let site_subsection1: string;
        let site_subsection2: string;

        switch (sectionCode.toLowerCase()) {

            case 'quote-home':
                subSection = 'Quote home';
                page_type = 'Quote';
                site_subsection1 = 'Landing Page:Default';
                site_subsection2 = 'Landing Page:Default:zip entry';
                break;

            case 'contact-info-terms':
                page_type = 'Quote rate form';
                subSection = 'Quote:Quote Info:contact info & terms';
                site_subsection1 = 'Quote Info';
                site_subsection2 = 'contact info & terms';
                break;
            case 'quote-error':
                subSection = 'Quote:Error';
                page_type = '_Quote';
                site_subsection1 = 'Quote:Error:system error';
                site_subsection2 = 'Quote:Error:system error';
                break;
            case 'info-driver1':
                page_type = 'Quote rate form';
                subSection = 'Quote:Quote Info:driver1';
                site_subsection1 = 'Quote Info';
                site_subsection2 = 'Quote:Quote Info:driver1';
                break;

            case 'info-driver2':
                page_type = 'Quote rate form';
                subSection = 'Quote:Quote Info:driver2';
                site_subsection1 = 'Quote Info';
                site_subsection2 = 'driver 2';
                break;

            case 'info-driver3':
                page_type = 'Quote rate form';
                subSection = 'Quote:Quote Info:driver3';
                site_subsection1 = 'Quote Info';
                site_subsection2 = 'driver 3';
                break;

            case 'info-driver4':
                page_type = 'Quote rate form';
                subSection = 'Quote:Quote Info:driver4';
                site_subsection1 = 'Quote Info';
                site_subsection2 = 'driver 4';
                break;

            case 'info-vehicles':
                page_type = 'Quote rate form';
                subSection = 'Quote:Quote Info:your vehicles';
                site_subsection1 = 'Quote Info';
                site_subsection2 = 'your vehicles';
                break;

            case 'info-vehicles1':
                page_type = 'Quote rate form';
                subSection = 'Quote:Quote Info:vehicle 1';
                site_subsection1 = 'Quote Info';
                site_subsection2 = 'vehicle 1';
                break;

            case 'info-vehicles2':
                page_type = 'Quote rate form';
                subSection = 'Quote:Quote Info:vehicle 2';
                site_subsection1 = 'Quote Info';
                site_subsection2 = 'vehicle 2';
                break;

            case 'info-vehicles3':
                page_type = 'Quote rate form';
                subSection = 'Quote:Quote Info:vehicle 3';
                site_subsection1 = 'Quote Info';
                site_subsection2 = 'vehicle 3';
                break;

            case 'info-vehicles4':
                page_type = 'Quote rate form';
                subSection = 'Quote:Quote Info:vehicle 4';
                site_subsection1 = 'Quote Info';
                site_subsection2 = 'vehicle 4';
                break;

            case 'discounts':
                page_type = 'Quote rate form';
                subSection = 'Quote:Quote Info:discounts';
                site_subsection1 = 'Quote Info';
                site_subsection2 = 'discounts';
                break;

            case 'details':
                page_type = 'Quote rate form';
                subSection = 'Quote:Quote Info:quote details';
                site_subsection1 = 'Quote Info';
                site_subsection2 = 'details';
                break;

            case 'quote-processing':
                page_type = 'Quote rate form';
                subSection = 'Quote:Quote Info:quote processing';
                site_subsection1 = 'Quote Rate';
                site_subsection2 = 'quote processing';
                break;

            case 'declined':
                page_type = 'Quote rate form';
                subSection = 'Quote:Quote Info:quote declined';
                site_subsection1 = 'Quote Rate';
                site_subsection2 = 'quote declined';
                break;

            case 'quote-rate-shown':
                page_type = 'Quote rate form';
                subSection = 'Quote:Quote Info:quote rate shown';
                site_subsection1 = 'Quote Rate';
                site_subsection2 = 'quote rate shown';
                break;

            case 'quote-vehiclecoverage-edit':
                page_type = 'Quote rate form';
                subSection = 'Quote:Quote Info:quote rate vehiclecoverage-edit';
                site_subsection1 = 'Quote Rate';
                site_subsection2 = 'quote rate shown';
                break;

            case 'quote-personalcoverage-edit':
                page_type = 'Quote rate form';
                subSection = 'Quote:Quote Info:quote rate personalcoverage-edit';
                site_subsection1 = 'Quote Rate';
                site_subsection2 = 'quote rate shown';
                break;

            case 'quote-perks-edit':
                page_type = 'Quote rate form';
                subSection = 'Quote:Quote Info:quote rate perks-edit';
                site_subsection1 = 'Quote Rate';
                site_subsection2 = 'quote rate shown';
                break;

            case 'quote-liabilitycoverage-edit':
                page_type = 'Quote rate form';
                subSection = 'Quote:Quote Info:quote rate liabilitycoverage-edit';
                site_subsection1 = 'Quote Rate';
                site_subsection2 = 'quote rate shown';
                break;

            case 'quote-packagecoverage-edit':
                page_type = 'Quote rate form';
                subSection = 'Quote:Quote Info:quote rate packagecoverage-edit';
                site_subsection1 = 'Quote Rate';
                site_subsection2 = 'quote rate shown';
                break;

            case 'edit-drivers':
                page_type = 'Quote rate form';
                subSection = 'Quote:Quote Edit:drivers edit';
                site_subsection1 = 'Quote Edit';
                site_subsection2 = 'drivers edit';
                break;

            case 'edit-vehicles':
                page_type = 'Quote rate form';
                subSection = 'Quote:Quote Edit:vehicles edit';
                site_subsection1 = 'Quote Edit';
                site_subsection2 = 'vehicles edit';
                break;

            case 'edit-discounts':
                page_type = 'Quote rate form';
                subSection = 'Quote:Quote Edit:discounts edit';
                site_subsection1 = 'Quote Edit';
                site_subsection2 = 'discounts edit';
                break;

            case 'edit-details':
                page_type = 'Quote purchase form';
                subSection = 'Quote:Quote Edit:details edit';
                site_subsection1 = 'Quote Edit';
                site_subsection2 = 'details edit';
                break;

            case 'Quote:Quote Edit:driver details':
                page_type = 'Quote rate form';
                subSection = 'Quote:Purchase Info:driver details';
                site_subsection1 = 'Purchase Info';
                site_subsection2 = 'driver details';
                break;

            case 'finalDrivers':
                page_type = 'Quote purchase form';
                subSection = 'Quote:Purchase Info:driver details';
                site_subsection1 = 'Purchase Info';
                site_subsection2 = 'driver details';
                break;

            case 'Quote:Purchase Info:vehicle details':
                page_type = 'Quote purchase form';
                subSection = 'Quote:Purchase Info:vehicle details';
                site_subsection1 = 'Purchase Info';
                site_subsection2 = 'vehicle details';
                break;

            case 'Quote:Purchase Info:policy details':
                page_type = 'Quote purchase form';
                subSection = 'Quote:Purchase Info:policy details';
                site_subsection1 = 'Purchase Info';
                site_subsection2 = 'policy details';
                break;

            case 'Quote:Checkout:pay plan & method':
                page_type = 'Quote purchase form';
                subSection = 'Quote:Checkout:pay plan & method';
                site_subsection1 = 'Checkout';
                site_subsection2 = 'pay plan & method';
                break;

            case 'Quote:Checkout:purchase processing':
                page_type = 'Quote purchase form';
                subSection = 'Quote:Checkout:purchase processing';
                site_subsection1 = 'Checkout';
                site_subsection2 = 'purchase processing';
                break;

            case 'Quote:Checkout:purchase declined':
                page_type = 'Quote purchase form';
                subSection = 'Quote:Checkout:purchase declined';
                site_subsection1 = 'Checkout';
                site_subsection2 = 'purchase declined';
                break;

            case 'esignature':
                page_type = 'Quote purchase form';
                subSection = 'Quote:Checkout:forms & eSignature';
                site_subsection1 = 'Checkout';
                site_subsection2 = 'forms & eSignature';
                break;

            case 'Quote:Quote:Checkout:agent selection':
                page_type = 'Quote purchase form';
                subSection = 'Quote:Quote:Checkout:agent selection';
                site_subsection1 = 'Checkout';
                site_subsection2 = 'agent selection';
                break;

            case 'thankyou':
                page_type = 'Quote purchase form';
                subSection = 'Quote:Checkout:purchase complete';
                site_subsection1 = 'Checkout';
                site_subsection2 = 'purchase complete';
                break;

        }
        section = {
            subSection,
            page_type,
            site_subsection1,
            site_subsection2
        };

        return section;
    }

}

export interface ISectionDetails {
    subSection: string;
    page_type: string;
    site_subsection1: string;
    site_subsection2: string;
}

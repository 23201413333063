import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { environment } from 'src/environments/environment';
import { RatedAggregatorQuoteGuard } from './shared/guards/aggregators/rated-aggregator-quote-guard';
import { UnratedAggregatorQuoteGuard } from './shared/guards/aggregators/unrated-aggregator-quote-guard';
import { ExternallyInitiatedQuoteGuard } from './shared/guards/externally-initiated-quote-guard';

const routes: Routes = [
  { path: '', redirectTo: environment.contextRoot, pathMatch: 'full' },
  {
    path: environment.contextRoot,
    loadChildren: () =>
      import('./quote-modules/quote.module').then((m) => m.QuoteModule)
  },
  {
    path: `${environment.contextRoot}/quote`,
    loadChildren: () =>
      import('./quote-modules/quote.module').then((m) => m.QuoteModule)
  },
  {
    path: `${environment.contextRoot}/entry/retrievequote`,
    loadChildren: () =>
      import('./retrieve-module/retrieve/retrieve.module').then((m) => m.RetrieveModule)
  },
  {
    path: `${environment.contextRoot}/entry/esign`,
    loadChildren: () =>
      import('./esignentry/esignentry.module').then((m) => m.EsignentryModule)
  },
  {
    path: `${environment.contextRoot}/purchase`,
    loadChildren: () =>
      import('./purchase-modules/purchase.module').then((m) => m.PurchaseModule)
  },

  /**
   * Component-less routes: these routes just redirect to other routes in the application
   * based on logic contained within their associated canActivate guard
   */
  {
    path: `${environment.contextRoot}/entry/externalGetQuote`,
    canActivate: [ExternallyInitiatedQuoteGuard],
    children: []
  },
  // Route for Rated Aggregator Quote
  {
    path: `${environment.contextRoot}/buyquote`,
    canActivate: [RatedAggregatorQuoteGuard],
    children: []
  },
  // Route for Unrated Aggregator Quote (Unrated)
  {
    path: `${environment.contextRoot}/entry/aggregator/:aggregatorName/:id`,
    canActivate: [UnratedAggregatorQuoteGuard],
    children: []
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import { Injectable } from '@angular/core';
import { IRetrieve} from '../model/retrieve';
import { Facade } from './facade';
import { RetrieveStore } from './retrieve-store';

@Injectable({ providedIn: 'root' })
export class RetrieveFacade extends Facade<IRetrieve[]> {

    constructor(protected store: RetrieveStore) {
        super(store);
    }

    insertRetrieves(retrieves: IRetrieve[]): void {
        this.store.insertRetrieves(retrieves);
    }
}
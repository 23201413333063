export enum IncidentType {
    ACCIDENT = 'Acc',
    VIOLATION = 'Vio',
    CLAIM = 'Cla'
}

export enum IncidentSource {
    MANUAL = 'MANUALENT',
    SYSTEM = 'SYSTEM',
    CLIENT = 'MANUAL',
    AI_CLIENT = 'CLIENT',
    CLUE = 'CLUE',
    RMV = 'RMV'
}

export interface IIncident {
    id?: number;
    driverId?: number;
    type?: IncidentType;
    month?: string;
    year?: string;
    descId?: string;
    source?: IncidentSource;
    sequenceNum?: number;
    polStarAccidentId?: string;
}
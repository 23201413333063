import {Component, OnInit} from '@angular/core';
import {take, tap} from 'rxjs/operators';
import {TealiumService} from 'src/app/shared/services/tealium.service';
import {PolicyFacade} from 'src/app/shared/stores/policy-facade';

@Component({
    selector: 'app-technical-error',
    templateUrl: './technical-error.component.html',
    styleUrls: ['./technical-error.component.scss']
})
export class TechnicalErrorComponent implements OnInit {

    constructor(private tealium: TealiumService, private policyFacade: PolicyFacade) {
    }

    ngOnInit(): void {
        this.policyFacade.state.pipe(
            take(1),
            tap((policy) => {
                this.tealium.tealiumViewLoad('quote-error',
                    {
                        site_subsection3: 'Quote:Error:system error',
                        site_subsection4: 'Quote:Error',
                        event_name: `event54:${policy ? policy.policyNumber : ''}`
                    }
                );
            })
        ).subscribe();
    }

}

import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {AuthorizationInterceptor} from './authorization-interceptor';
import {HttpErrorInterceptor} from './http-error-interceptor';
import {LoadingMaskInterceptor} from './loading-mask-interceptor';
import {MDCInterceptor} from './mdc-interceptor';
import {SuspiciousFormFieldActionsInterceptor} from './suspicious-form-field-actions.interceptor';

export const httpInterceptorProviders = [
    { provide: HTTP_INTERCEPTORS, useClass: AuthorizationInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: MDCInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: SuspiciousFormFieldActionsInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoadingMaskInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true }
];
import { Injectable } from '@angular/core';
import { IAuthorizationContext } from '../model/authorization-context';
import { StorageBackedStore } from './storage-backed-store';

@Injectable({ providedIn : 'root' })
export class AuthorizationStore extends StorageBackedStore<IAuthorizationContext> {

    constructor() {
        super('auth-ctx', {
            token: null,
            quoteNumber: null
        });
    }

}
export class NumberUtils {
    private constructor() {}

    /**
     * Returns a string representing a number in fixed-point notation. If the number is an integer, then the number
     * is returned without a decimal point, regardless of the fractionDigits specified.
     * example:
     * toFixedDropTrailingZeros(2.5, 2) => '2.50'
     * toFixedDropTrailingZeros(2.0, 2) => '2'
     * @param number the number to convert
     * @param fractionDigits Number of digits after the decimal point. Must be in the range 0 - 20, inclusive.
     */
    static toFixedDropTrailingZeros(number: number, fractionDigits = 2): string {
        if (!number || Number.isInteger(number)) {
            return '' + number;
        }

        return number.toFixed(fractionDigits);
    }

    static fromString(str: string, defaultValue = 0): number {
        const results = str ? parseFloat(str) : defaultValue;
        return isNaN(results) ? defaultValue : results;
    }
}
<nav class="navbar navbar-light amber lighten-4 header desktop">
  <a class="navbar-brand">
    <img src="{{headerDesktopLogo}}" class="logo">
    <span *ngIf="_policy.brand && !uiState.defaultBrand">
      <img src="{{cobrandURL}}{{_policy.brand}}/images/aff_top.png" onerror="this.onerror=null;this.src='';" class="brand-logo">
    </span>
  </a>
</nav>
<nav class="navbar navbar-light amber lighten-4  mobile">
  <a class="navbar-brand">
    <img src="{{headerMobileLogo}}" class="logo">
    <span *ngIf="_policy.brand && !uiState.defaultBrand">
      <img src="{{cobrandURL}}{{_policy.brand}}/images/aff_top.png" class="brand-logo">
    </span>
  </a>
</nav>

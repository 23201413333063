import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UserIdleService } from 'angular-user-idle';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { SessionTimeoutCountDown } from '../../model/session-timeout-countdown';

@Component({
  selector: 'app-session-timeout-warning-modal',
  templateUrl: './session-timeout-warning-modal.component.html',
  styleUrls: ['./session-timeout-warning-modal.component.scss']
})
export class SessionTimeoutWarningModalComponent {

  remainingSeconds$ = this.sessionTimeoutCountdown.remainingSeconds$;

  constructor(public activeModal: NgbActiveModal, private sessionTimeoutCountdown: SessionTimeoutCountDown) { }

  continueSession(): void {
    this.activeModal.close();
  }

}

import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { IPolicy } from '../model/policy';
import { PolicyFacade } from '../stores/policy-facade';
import { Router } from '@angular/router';

/**
 * Adds headers required by backend services to populate their logging
 * Mapped Diagnostic Context (MDC)
 */
@Injectable({ providedIn: 'root' })
export class MDCInterceptor implements HttpInterceptor {

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return this.policyFacade.state.pipe(
            take(1),
            map((policy) => this.setMDCData(req, policy)),
            switchMap((_) => next.handle(_))
        );
    }

    private setMDCData(req: HttpRequest<any>, policy: IPolicy): HttpRequest<any> {

        if (this.router.url.includes('/esales-rider/purchase/thank-you')){
            return req.clone({
                setHeaders: {
                     qu: (policy ? policy.originalPolicyNumber : ''),
                }
            });
        } else {
        return req.clone({
            setHeaders: {
                qu: (policy ? policy.policyNumber : ''),
            }
        });
    }
    }

 
    constructor(private policyFacade: PolicyFacade, private router: Router) { }
}
import { Injectable } from '@angular/core';
import { IAgent } from '../model/agent';
import { StorageBackedStore } from './storage-backed-store';

@Injectable({ providedIn : 'root'})
export class AgentStore extends StorageBackedStore<IAgent> {

    constructor() {
        super('agent', null);
    }

    setAgent(agent: IAgent): void {
        this.setState({...agent});
    }
}
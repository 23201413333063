import { Injectable } from '@angular/core';
import { produce } from 'immer';
import { AddressType, IAddress } from '../model/address';
import { StorageBackedStore } from './storage-backed-store';
import * as _ from 'lodash';

@Injectable({ providedIn: 'root' })
export class AddressesStore extends StorageBackedStore<IAddress[]> {
    constructor() {
        super('addresses', []);
    }

    upsertAddressOfType(addressType: AddressType, address: IAddress): void {
        const nextState = produce(this.state, (draft: IAddress[]) => {
            const addressTypeIndex = draft.findIndex((a: IAddress) => a.type === addressType);

            if (addressTypeIndex < 0) {
                draft.push(address);
            } else {
                draft[addressTypeIndex] = address;
            }
        });

        this.setState(nextState);
    }

    upsertAddressOfTypeAndVehicleId(addressType: AddressType, vehicleId: number, address: IAddress) {
        const nextState = produce(this.state, (draft: IAddress[]) => {
            const addressTypeIndex = draft.findIndex((a: IAddress) => a.type === addressType && a.vehicleId === vehicleId);

            if (addressTypeIndex < 0 && address.addressLine1 && address.city && address.state)  {
                address.type=addressType;
                address.vehicleId=vehicleId;
                draft.push(address);
            } else {
                if(address.addressLine1 && address.city && address.state) {
                    draft[addressTypeIndex] = address;
                } else {
                    this.deleteByTypeAndVehicleId(addressType,vehicleId)
                }
                
            }
        });

        this.setState(nextState);
    }

    deleteByTypeAndVehicleId(type: AddressType, vehicleId: number) {
        const nextState = produce(this.state, (draft: IAddress[]) => {
            _.remove(draft, { type, vehicleId });
        });

        this.setState(nextState);
    }

}
import { Observable } from 'rxjs';
import { Store } from './store';

/**
 * Acts as a proxy for the underlying Store, hiding the implementation details
 * of the Store. Components that need to update or query state should only
 * do so using only methods exposed by the Facade. Components therefore should
 * only inject Facades as dependencies, not the Stores.
 */
export abstract class Facade<T> {

    constructor(protected store: Store<T>) { }

    get state(): Observable<T> {
        return this.store.state$;
    }

    patch(value: T): void {
        this.store.patch(value);
    }

    reset(): void {
        this.store.reset();
    }

}
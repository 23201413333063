import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn : 'root'})
export class LoadingMaskService {

    private isLoading = new BehaviorSubject<boolean>(false);
    private issuanceLoading = new BehaviorSubject<boolean>(false);

    loading$ = this.isLoading.asObservable();
    policyIssuanceLoading$=this.issuanceLoading.asObservable();
    
    setLoading(value: boolean) {
        this.isLoading.next(value);
    }

    setPolicyIssuanceLoading(value: boolean) {
      this.issuanceLoading.next(value);
    }
}
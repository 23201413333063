import { IZipLookupResponse } from '../services/zip.service';

export enum AddressType {
    RESIDENTIAL = 'RESIDENTIAL',
    MAILING = 'MAILING',
    GARAGING = 'GARAGING',
    ADDITIONAL_INTEREST = 'ADDITIONAL_INTEREST'
}

export class IAddress {
    id?: number;
    addressLine1?: string;
    addressLine2?: string;
    city?: string;
    state?: string;
    county?: string;
    zip?: string;
    vehicleId?: number;
    type?: AddressType;
    addressProId?: number;
}

export function fromZipLookupResponse(zipLookupResponse: IZipLookupResponse, addressType: AddressType): IAddress {
    return {
        city: zipLookupResponse.city,
        state: zipLookupResponse.state,
        zip: zipLookupResponse.zipCode,
        county: zipLookupResponse.county,
        type: addressType,
        addressProId: 0,
    }
}

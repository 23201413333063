import * as CryptoJS from 'crypto-js';

/**
 * Wrapper around sessionStorage or localStorage that hashes stored keys and encrypts stored values
 */
export class SecureStorage implements Storage {

    private static readonly CRYPT_KEY = '315784fdc37d87caf3202b7f6ad7786c';

    constructor(private storage: Storage) {}

    get length(): number {
        return this.storage.length;
    }

    clear(): void {
        this.storage.clear();
    }

    getItem(key: string): string {
        key = this.hash(key);

        const value = this.storage.getItem(key);

        if (!value) {
            return null;
        }

        return (typeof value !== 'string') ? value : this.decrypt(value);
    }

    key(index: number): string {
        return this.storage.key(index);
    }

    removeItem(key: string): void {
        this.storage.removeItem(this.hash(key));
    }

    setItem(key: string, value: string): void {
        key = this.hash(key);

        if (value) {
            value = (typeof value !== 'string') ? JSON.stringify(value) : value;
            value = this.encrypt(value);
        }

        this.storage.setItem(key, value);
    }

    private hash(key): string {
        return CryptoJS.SHA256(key, SecureStorage.CRYPT_KEY).toString();
    }

    private encrypt(data): string {
        return CryptoJS.AES.encrypt(data, SecureStorage.CRYPT_KEY).toString();
    }

    private decrypt(data): string {
        return CryptoJS.AES.decrypt(data, SecureStorage.CRYPT_KEY).toString(CryptoJS.enc.Utf8);
    }
}
import {Injectable} from '@angular/core';
import produce from 'immer';
import {ICoverage} from '../model/coverage';
import {StorageBackedStore} from './storage-backed-store';
import * as _ from 'lodash';

@Injectable({providedIn: 'root'})
export class CoveragesStore extends StorageBackedStore<ICoverage[]> {
    constructor() {
        super('coverages', []);
    }

    deleteCoveragesByPackageTypeCode(packageTypeCode: string): void {
        const nextState = produce(this.state, (draft: ICoverage[]) => {
            _.remove(draft, (coverage) => coverage.packageTypeCode === packageTypeCode);
        });

        this.setState(nextState);
    }

    addAll(items: ICoverage[]) {
        const nextState = produce(this.state, (draft: ICoverage[]) => {
            draft.push(...items);
        });

        this.setState(nextState);
    }
}

import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {AddressPoBoxValidatorDirective} from './directives/address-po-box-validator.directive';
import {NoCopyPasteDirective} from './directives/no-copy-paste.directive'
import {NoRightClickDirective} from './directives/norightclick.directive';
import {OnlynumbersDirective} from './directives/onlynumbers.directive';
import {PatternMaskDirective} from './directives/pattern-mask.directive';
import {PhonemaskDirective} from './directives/phonemask.directive';
import {PhonevalidationDirective} from './directives/phonevalidation.directive';
import {SATDirective} from './directives/sat.directive';
import {SecureUSDateFormatMaskDirective} from './directives/secure-us-date-format-mask.directive';
import {USDateFormatMaskDirective} from './directives/us-date-format-mask.directive';

@NgModule({
    declarations: [
        PhonemaskDirective,
        PhonevalidationDirective,
        OnlynumbersDirective,
        USDateFormatMaskDirective,
        SecureUSDateFormatMaskDirective,
        PatternMaskDirective,
        AddressPoBoxValidatorDirective,
        NoRightClickDirective,
        NoCopyPasteDirective,
        SATDirective
    ],
    imports: [CommonModule],
    exports: [
        PhonemaskDirective,
        PhonevalidationDirective,
        OnlynumbersDirective,
        USDateFormatMaskDirective,
        SecureUSDateFormatMaskDirective,
        PatternMaskDirective,
        AddressPoBoxValidatorDirective,
        NoRightClickDirective,
        NoCopyPasteDirective,
        SATDirective
    ]
})
export class UtilsModule {
}
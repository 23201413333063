import { Injectable } from '@angular/core';
import { IAuthorizationContext } from '../model/authorization-context';
import { AuthorizationStore } from './authorization-store';
import { Facade } from './facade';

@Injectable({ providedIn : 'root'})
export class AuthorizationFacade extends Facade<IAuthorizationContext> {

    constructor(protected store: AuthorizationStore) {
        super(store);
    }
}
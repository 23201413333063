import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { Observable, of } from 'rxjs';
import { map, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { Logger } from '../../root/logger/logger';
import { SortFunction } from '../../utils/sort-function';
import { IApplicationState } from '../model/application-state';
import { IPolicy, isAggregatorQuote } from '../model/policy';
import { CampaignKey, Variation } from '../model/vwo';
import { PolicyFacade } from '../stores/policy-facade';
import { TealiumService } from './tealium.service';
import { VwoService } from './vwo.service';
import { UIFacade } from '../stores/ui-facade';

@Injectable({ providedIn: 'root' })
export class QuickQuoteService {
    private apiUrl = environment.apiUrl;


    constructor(
        private policyFacade: PolicyFacade,
        private vwoService: VwoService,
        private logger: Logger,
        private http: HttpClient,
        private tealium: TealiumService,
        private uiFacade: UIFacade
    ){}

    initializeQuickQuoteViewMetadata(): Observable<any> {
        let walletRccEnabled: boolean = undefined;
        let newPaySysEnabled: boolean = undefined;

        return this.vwoService.getVariations([
            CampaignKey.RidersWalletRcc,
            CampaignKey.RidersNewPaySys
        ]).pipe(
            map((results) => {
                walletRccEnabled = results[CampaignKey.RidersWalletRcc] === Variation.WalletRccEnabled;
                newPaySysEnabled = results[CampaignKey.RidersNewPaySys] === Variation.NewPaySysEnabled;

                return {
                    walletRccEnabled,
                    newPaySysEnabled
                }
            }),
            tap((data) => {
                const {
                    walletRccEnabled,
                    newPaySysEnabled
                } = data;

                this.policyFacade.patch({ walletRccEnabled, newPaySysEnabled });
                this.uiFacade.patch({ walletRccEnabled, newPaySysEnabled })
            })
        )
    }
}
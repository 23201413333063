import { Injectable } from '@angular/core';
import { IPolicy, QUOTE_TYPE } from '../model/policy';
import { StorageBackedStore } from './storage-backed-store';

@Injectable({ providedIn: 'root' })
export class PolicyStore extends StorageBackedStore<IPolicy> {

    // tslint:disable-next-line: max-func-body-length
    constructor() {
        const initialState = {
            // Base policy properties
            policyKey: 0,
            policyNumber: '',
            originalPolicyNumber: null,
            state: null,
            channel: '',
            term: 0,
            uwCompany: '',
            company: '',
            plfCode: '',
            lob: 'MC',
            iRefid: '',
            iRefClickid: '',
            sourceOfEntry: 'DIRECT',
            sourceOfBusiness: 'ESALES',
            saveQuoteIndicator: null,
            quoteType: QUOTE_TYPE.QUOTE,
            originalSourceOfData: null,
            isDirectBusiness: false,
            declined: false,
            declineReason: null,
            declineMessageTitle: null,
            declineMessages: [],
            moratoriumIndMsg:null,

            // Customer Info
            effectiveDate: null,
            homePhoneNumber: '',

            // Policy Discounts
            pdPrudential: { discountId: 0, value: null },
            pdCommercialAuto: { discountId: 0, value: null },
            pdMotorClub: null,
            pdTeacher: null,
            pdEDocument: null,
            affinityGroupCode: null,
            prudentialCenterBenefitIndicator: 'N',
            policyRewardsPackages: [],
            // callRate: null,
            // ***removed later just for Damodar's Testing ***********

            // Details
            homeInsuranceIndicator: null,
            homeInsuranceType: null,
            homeInsuranceCompany: null,
            allWithHealthCareIndicator: null,
            medicareIndicator: null,

            // Details - Prior Carrier
            currentCarrier: null,
            currentCarrierName: null,
            activeCurrentPolicyInd: null,
            priorCarrierPolicyEffectiveDate: null,
            priorCarrUnpaidPremiumAmt: null,

            // Quote
            selectedPackage: null,
            accidentForgivenessIndicator: null,
            premiumChanged: false,

            // Final Policy Details
            mailingAddressDifferent: null,
            accidentsInLoanedVehicle: null,

            // AARP
            aarpMemberId: null,
            aarpMembershipStatus: null,
            aarpMembershipDate: null,

            // HO Cross-sell related
            homeInsPolicyKey: 0,
            crossSellHOQuoteNumber: null,
            homePromoPremium: 0,
            creditStatus: null,

            // Policy Pro MC_ID
            mcId: 0
        };

        super('policy', initialState);
    }

}
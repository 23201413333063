import {Injectable} from '@angular/core';
import * as moment from 'moment';
import {forkJoin, Observable} from 'rxjs';
import {distinctUntilChanged, switchMap, take, tap} from 'rxjs/operators';
import {IPolicy} from '../model/policy';
import {IUIState} from '../model/ui-state';
import {PolicyFacade} from '../stores/policy-facade';
import {UIFacade} from '../stores/ui-facade';
import {ConfigService} from './config.service';

@Injectable({ providedIn : 'root' })
export class UIStateInitializationService {

    initialize(): Observable<IUIState> {
        return this.policyFacade.state.pipe(
            distinctUntilChanged((prev, next) =>
                prev.state === next.state
                && prev.channel === next.channel
                && prev.effectiveDate === next.effectiveDate
            ),
            switchMap((policy) => this.getUIStateConfigs(policy)),
            tap((config) => this.uiFacade.patch({...config})),
            switchMap(() => this.uiFacade.state.pipe(take(1)))
        );
    }

    private getUIStateConfigs(policy: IPolicy): Observable<IUIState> {
        const state = policy.state;
        const channel = policy.channel;
        const asOfDate = policy.effectiveDate || moment().format('MM/DD/YYYY');

        return forkJoin({
            isManagePaymentsEnabled : this.configService.isManagePaymentsEnabled(policy.effectiveDate).pipe(take(1))
        });
    }

    constructor(
        private policyFacade: PolicyFacade,
        private configService: ConfigService,
        private uiFacade: UIFacade
    ) {}
}
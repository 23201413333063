export enum PLFCode {
    HIPT = 'HIPT',
    PDTL = 'PDTL',
    PALS = 'PALS',
    TEAC = 'TEAC',
    MWAC = 'MWAC',
    PRAC = 'PRAC',
    PILG = 'PILG',
    BKHL = 'BKHL',
    PRCA = 'PRCA',
    PGCA = 'PGCA',
    PENS = 'PENS'
}
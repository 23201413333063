import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class ZipService {

  private apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) { }

  lookUp(request: IZipLookUpRequest): Observable<IZipLookupResponse> {
    return this.http.post<IZipLookupResponse>(this.apiUrl + '/thirdparty/zip2state', request);
  }

}

export interface IZipLookUpRequest {
  zip: string;
}

export interface IZipLookupResponse {
  state: string;
  city: string;
  county: string;
  zipCode: string;
}
import { Injectable } from '@angular/core';
import produce from 'immer';
import * as _ from 'lodash';
import { SortFunction } from 'src/app/utils/sort-function';
import { IVehicle, VehicleSource } from '../model/vehicle';
import { StorageBackedStore } from './storage-backed-store';

@Injectable({ providedIn: 'root' })
export class VehiclesStore extends StorageBackedStore<IVehicle[]> {

    constructor() {
        super('vehicles', []);
    }

    insertVehicle(vehicle: IVehicle) {
        const nextState = produce(this.state, (draft: IVehicle[]) => {
            draft.push(vehicle);
            this.sequentializeSequenceNumbers(draft);
        });

        this.setState(nextState);
    }

    deleteVehicleByVinAndSeqNum(vinNumber: string, seqNum: number) {
        const nextState = produce(this.state, (draft: IVehicle[]) => {
            _.remove(draft, { vinNumber, seqNum });
            this.sequentializeSequenceNumbers(draft);
        });

        this.setState(nextState);
    }

    deleteVehicleByVin(vinNumber: any): void {
        const nextState = produce(this.state, (draft: IVehicle[]) => {
            _.remove(draft, { vinNumber });
            this.sequentializeSequenceNumbers(draft);
        });

        this.setState(nextState);
    }

    deleteVehicleById(vehicleId: number) {
        const nextState = produce(this.state, (draft: IVehicle[]) => {
            _.remove(draft, { vehicleId });
            this.sequentializeSequenceNumbers(draft);
        });

        this.setState(nextState);
    }

    updateVehicleByVinAndSeqNum(vin: string, seqNum: number, vehicle: IVehicle) {
        const nextState = produce(this.state, (draft: IVehicle[]) => {
            const index = draft.findIndex((v) => v.vinNumber === vin && v.seqNum === seqNum);
            if (index > -1) {
                draft[index] = vehicle;
            }
        });

        this.setState(nextState);
    }

    /**
     * Sorts the vehicles contained by this store such that prefill vehicles, sorted by descending
     * model year, are placed at the beginning of the vehicle list, followed by manually added
     * vehicles in the order that they were added by the user.
     */
    defaultSort(): void {
        const nextState = produce(this.state, (draft: IVehicle[]) => {
            if (!draft || !draft.length) {
                return;
            }

            const prefillVehicles = draft.filter((v) => !!v.vehicleThirdPartyId) || [];
            prefillVehicles.sort(SortFunction.of('year', 'desc'));

            const manuallyAddedVehicles = draft.filter((v) => !v.vehicleThirdPartyId || v.source === VehicleSource.MANUAL) || [];

            // Empty draft
            draft.splice(0, draft.length);

            // Sorted prefill vehicles by desc model year come first in the vehicle list
            draft.push(...prefillVehicles);

            // Followed by manually added vehicles in the order they were added by the user
            draft.push(...manuallyAddedVehicles);

            this.sequentializeSequenceNumbers(draft);
        });

        this.setState(nextState);
    }

    /**
     * Sets each vehicle's seqNumber with the starting element set at 1, the next
     * element at 2, and so on
     */
    private sequentializeSequenceNumbers(vehicles: IVehicle[]) {
        if (!vehicles || !vehicles.length) {
            return;
        }

        vehicles.forEach((vehicle, index) => vehicle.seqNum = ++index);
    }

}
import { Component, OnInit, Input, ViewChild, ElementRef, } from '@angular/core';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import {Router} from '@angular/router';
import { IAgent } from '../../model/agent';
import { PLFCode } from '../../model/plf-code';
import { IPolicy } from '../../model/policy';
import { IUIState } from '../../model/ui-state';
import { TealiumService } from 'src/app/shared/services/tealium.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-nb-moratorium-hold-modal',
  templateUrl: './nb-moratorium-hold-modal.component.html',
  styleUrls: ['./nb-moratorium-hold-modal.component.scss']
})
export class NBMoratoriumHold implements OnInit { // NBMoratoriumHold
  @ViewChild('NBMoratoriumHoldModalTrigger') NBMoratoriumHoldModalTrigger: ElementRef;
  private _agent: IAgent;
  private _policy: IPolicy;
  _uiState: IUIState;
  servicePhoneNumber;
  showPhoneNumber: boolean = false;
  hideCloseButton = false;
  
  @Input() set agent(agent: IAgent) {
    this._agent = agent;
  }

  get agent(): IAgent {
    return this._agent || {};
  }

  @Input() set policy(policy: IPolicy) {
    this._policy = policy || {};
  }

  @Input() routeUrl?: string;

  get policy(): IPolicy {
    return this._policy || {};
  }

  get message(): string {
    return this.policy.moratoriumIndMsg;
    /* this.servicePhoneNumber = this.getServicePhoneNumber() ? parsePhoneNumberFromString(this.getServicePhoneNumber(), 'US').formatNational() : '';
    // const servicePhoneNumberMarkup = `<a href="tel:${servicePhoneNumber}">${servicePhoneNumber}</a>`
    //  const content = "(this.policy.notBindingMessages || [])
    //   .filter((message) => !!message)
    //   .map((message) => '<p>' + message + '</p>')
    //   .join('<br/>');    
    // return `<p>Thanks for your interest in a Plymouth Rock auto insurance quote. Please call to complete your transaction.</p>` */
  }

  @Input() set uiState(uiState: IUIState) {
    this._uiState = uiState;
  }

  get uiState(): IUIState {
    return this._uiState;
  }
  
   private getServicePhoneNumber(): string {
    const notBindingPhoneNumber = this.policy.channel === 'DIRECT' ? '8442483568' : '8442483568';
    return notBindingPhoneNumber;
    // return this.policy.notBindingPhoneNumber || notBindingPhoneNumber;
  }
  /**
     * This is Heap Config is not trigger when model pop up opened
     */
  private triggerFalseClick(): void {
    this.tealium.view({prop47: this.policy.policyNumber});
    const el: HTMLElement = this.NBMoratoriumHoldModalTrigger.nativeElement;
    el.click();
  }

  onClose(): void {
    this.activeModal.dismiss();
    if (this.routeUrl) {
      if (this.routeUrl === 'esales-rider/quote/quotePreview') {
        this.routeUrl = 'esales-rider/discounts/perks';
      }
      if (this.routeUrl === 'esales-rider/purchase/esign') {
        this.routeUrl = 'esales-rider/purchase/checkout';
      } 
      this.router.navigate([this.routeUrl]);
    }
  }

  // onServicePhoneNumberClick(): void {
  //   this.hideCloseButton = true;
  //   if(!this._uiState.isMobile){
  //     this.showPhoneNumber = this.hideCloseButton;
  //   }
  // }
  constructor(private tealium: TealiumService,
    public activeModal: NgbActiveModal,
    private router: Router,
    ) { }

  ngOnInit() {
    this.tealium.view({prop47: this.policy.policyNumber});
    this.triggerFalseClick();    
   }
  
}

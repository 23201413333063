export enum CampaignKey {
    RidersWalletRcc = "RidersWalletRcc",
    RidersNewPaySys = 'RidersNewPaySys',
}

export enum Variation {
    Control = 'Control',
    WalletRccEnabled = "WalletRccEnabled",
    NewPaySysEnabled = "NewPaySysEnabled",
}

export enum GoalIdentifier {
    DriverListPage = 'DriverListPage',
    QuoteSummaryPage = 'QuoteSummaryPage',
    CheckoutNewDesignGoal = 'CheckoutNewDesignGoal',
    ESignaturePage = 'ESignaturePage',
    ThankYouPage = 'ThankYouPage',
    CheckoutPage = "CheckoutPage",
}
/* tslint:disable:member-ordering */
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import * as _ from 'lodash';
import {Observable} from 'rxjs';
import {switchMap, take, withLatestFrom} from 'rxjs/operators';
import {StringUtils} from '../../utils/string-utils';
import {IPolicy} from '../model/policy';
import {IUIState} from '../model/ui-state';
import {PolicyFacade} from '../stores/policy-facade';
import {UIFacade} from '../stores/ui-facade';
import {RefererFacade} from '../stores/referer-facade';
import {IRefererState} from '../stores/referer-store';
@Injectable({providedIn: 'root'})
export class SuspiciousFormFieldActionsInterceptor implements HttpInterceptor {

    private static readonly NEGATIVE_VALUES = ['n', 'N', 'f', 'F'];
    private static readonly AFFIRMATIVE_VALUES = ['y', 'Y', 't', 'T'];
    private static readonly FORM_FIELD_TRACKING_TOKEN_LENGTH: number = 20;

    constructor(private uiFacade: UIFacade, private policyFacade: PolicyFacade,private refererFacade:RefererFacade) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return this.uiFacade.state.pipe(
            take(1),
            withLatestFrom(this.policyFacade.state, this.refererFacade.state),
           switchMap(([uiState, policy, refererState]) => {
                return next.handle(req.clone({
                        setHeaders: {'x-es': SuspiciousFormFieldActionsInterceptor.generateToken(uiState, policy, refererState)
                    },
                    })
                );
            })
        );
    }

    private static generateToken(uistate:IUIState,policy: IPolicy, refererState: IRefererState): string {
        // Generate a 20-char random string
        let cnpFields =[];
        cnpFields=uistate.suspiciousFormFieldActions;

        const randomString = StringUtils.randomString(SuspiciousFormFieldActionsInterceptor.FORM_FIELD_TRACKING_TOKEN_LENGTH);

        // Replace 3rd char of random string with 'y'/'Y'/'t'/'T' if firstName was copy-pasted,
        // otherwise replace it with 'n'/'N'/'f'/'F'
        let randomStringOverlayedWithSuspiciousActionFlags = StringUtils.replaceCharAt(randomString, 2,
            cnpFields && cnpFields.indexOf('CP_firstName') !== -1
                ? SuspiciousFormFieldActionsInterceptor.AFFIRMATIVE_VALUES[_.random(0, 3)]
                : SuspiciousFormFieldActionsInterceptor.NEGATIVE_VALUES[_.random(0, 3)]
        );

        // Replace 7th char of random string with 'y'/'Y'/'t'/'T' if lastName was copy-pasted,
        // otherwise replace it with 'n'/'N'/'f'/'F'
        randomStringOverlayedWithSuspiciousActionFlags = StringUtils.replaceCharAt(randomStringOverlayedWithSuspiciousActionFlags, 6,
            cnpFields && cnpFields.indexOf('CP_lastName') !== -1
                ? SuspiciousFormFieldActionsInterceptor.AFFIRMATIVE_VALUES[_.random(0, 3)]
                : SuspiciousFormFieldActionsInterceptor.NEGATIVE_VALUES[_.random(0, 3)]
        );

        // Replace 10th char of random string with 'y'/'Y'/'t'/'T' if firstName was speed-typed,
        // otherwise replace it with 'n'/'N'/'f'/'F'
        randomStringOverlayedWithSuspiciousActionFlags = StringUtils.replaceCharAt(randomStringOverlayedWithSuspiciousActionFlags, 9,
            cnpFields && cnpFields.indexOf('ST_firstName') !== -1
                ? SuspiciousFormFieldActionsInterceptor.AFFIRMATIVE_VALUES[_.random(0, 3)]
                : SuspiciousFormFieldActionsInterceptor.NEGATIVE_VALUES[_.random(0, 3)]
        );

        // Replace 14th char of random string with 'y'/'Y'/'t'/'T' if lastName was speed-typed,
        // otherwise replace it with 'n'/'N'/'f'/'F'
        randomStringOverlayedWithSuspiciousActionFlags = StringUtils.replaceCharAt(randomStringOverlayedWithSuspiciousActionFlags, 13,
            cnpFields && cnpFields.indexOf('ST_lastName') !== -1
                ? SuspiciousFormFieldActionsInterceptor.AFFIRMATIVE_VALUES[_.random(0, 3)]
                : SuspiciousFormFieldActionsInterceptor.NEGATIVE_VALUES[_.random(0, 3)]
        );

        return SuspiciousFormFieldActionsInterceptor.getMcRefToken(randomStringOverlayedWithSuspiciousActionFlags,policy,refererState);
    }

    private static getMcRefToken(randomString :string,policy: IPolicy, refererState: IRefererState): string {
        if(refererState.validRefURL) {
            return SuspiciousFormFieldActionsInterceptor.generateMcRefToken(randomString, policy, refererState);
        }else {
            return SuspiciousFormFieldActionsInterceptor.generateNonMcRefToken(randomString, policy, refererState);
        }
    }

    private static generateMcRefToken(randomString :string,policy: IPolicy,refState:IRefererState): string {
        let tokenWithRiderRefURL = StringUtils.replaceCharAt(randomString, 4,SuspiciousFormFieldActionsInterceptor.AFFIRMATIVE_VALUES[_.random(0, 3)]);
        return `${refState.referrerURL}~${policy.policyNumber}~${tokenWithRiderRefURL}`;
    }

    private static generateNonMcRefToken(randomString :string,policy: IPolicy,refState:IRefererState): string {
        let tokenWithNoRiderRefURL = StringUtils.replaceCharAt(randomString, 4,SuspiciousFormFieldActionsInterceptor.NEGATIVE_VALUES[_.random(0, 3)]);
        return `${refState.referrerURL}~${policy.policyNumber}~${tokenWithNoRiderRefURL}`;
    }
}
import * as moment from 'moment';

export class DateUtils {

    private constructor() {}

    /**
     * Returns true if the given refDate is the same or after the date specified by the given key in
     * lookupMap, false otherwise. If the key is not present lookupMap, returns false
     */
    // tslint:disable-next-line: function-name
    static isSameOrAfterDate(lookupMap: Map<string, string>, key: string, pattern: string, refDate: string, refDatePattern: string): boolean {
        const value = lookupMap.get(key);
        const valueAsMoment = value ? moment(value, pattern) : null;

        return valueAsMoment ? moment(refDate, refDatePattern).isSameOrAfter(valueAsMoment, 'day') : false;
    }

    /**
     * Returns true if otherDate falls within the date range specified by startKey and endKey contained by lookupMap.
     * The range check is inclusive of the start and end dates. If there is no value specified by endKey, checks whether
     * otherDate is not before the date specified by startDate.
     * Returns false if:
     *
     * 1. otherDate is not within the range of the dates specified by startKey and endKey, if endKey is specified 
     * 2. otherDate is before the date specified by startKey, if endKey is not specified
     * 3. the value of startKey is blank
     * 4. props is null
     *
     * @param lookupMap the Map from which to retrieve the startKey and endKey values
     * @param startKey the key referring to the start of the date range. The value must be a date string conforming to the given pattern.
     * @param endKey the key referring to the start of the date range. The value must be a date string conforming to the given pattern.
     * @param pattern the pattern of the date specified by startKey and endKey
     * @param otherDateStr the date to check as to whether it falls within the date range specified by startKey and endKey
     * @param otherDateFormat the format of otherDate
     */
    // tslint:disable-next-line: function-name
    static isWithinRange(lookupMap: Map<string, string>, startKey: string, endKey: string, pattern: string, otherDateStr: string, otherDateFormat: string): boolean {
        if (!lookupMap || !lookupMap.get(startKey) || !otherDateStr) {
            return false;
        }

        const otherDate = moment(otherDateStr, otherDateFormat);

        const startDate = moment(lookupMap.get(startKey), pattern);
        const endDate = lookupMap.get(endKey) ? moment(lookupMap.get(endKey), pattern) : null;

        return !endDate ? otherDate.isSameOrAfter(startDate, 'day') : otherDate.isBetween(startDate, endDate, 'day', '[]');
    }

    /**
     * Formats the given milliseconds since epoch to the given format. The format specified
     * must be a valid format recognized by the moment api
     */
    // tslint:disable-next-line: function-name
    static format(millis: number, format: string) {
        return moment(millis).format(format);
    }

    static getYears(): Array<{ [propKey: string]: string | number }> {
        let currentYear = new Date().getFullYear(),
          years = [
            {
              name: 'Year',
              value: ''
            },
            {
              name: currentYear,
              value: currentYear
            }
          ],
          totalYears = 30;
        for (let index = 2; index < totalYears; index++) {
          currentYear++;
          years[index] = {
            name: currentYear,
            value: currentYear
          };
        }
        return years;
      }
    
    static getMonths(): Array<{ [propKey: string]: string }> {
        const months = [
          {
            name: 'Month',
            value: ''
          }
        ];
        for (let index = 1; index <= 12; index++) {
          let formattedIndex = index > 9 ? `${index}` : `0${index}`;
          months[index] = {
            name: formattedIndex,
            value: formattedIndex
          };
        }
        return months;
      }

      static addLeadingZeros(userDate:string) {
        const parts = userDate.split('/');
        if ((parts[0] || '').length === 1) {
            parts[0] = '0' + parts[0];
        }
        if ((parts[1] || '').length === 1) {
            parts[1] = '0' + parts[1];
        }
        return parts[0] + '/' + parts[1] + '/' + parts[2];
      }


}
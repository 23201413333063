import { Injectable } from '@angular/core';
import { StorageBackedStore } from './storage-backed-store';

@Injectable({ providedIn: 'root' })
export class RefererStore extends StorageBackedStore<IRefererState> {
    constructor() {
        super('referer', {
            referrerURL: '',
            validRefURL:false
    
        });
    }


}


export interface IRefererState {
    referrerURL: string;
    validRefURL: boolean
}
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AddressType, IAddress } from '../model/address';
import { AddressesStore } from './addresses-store';
import { Facade } from './facade';

@Injectable({ providedIn : 'root'})
export class AddressesFacade extends Facade<IAddress[]> {

    constructor(protected store: AddressesStore) {
        super(store);
    }

    findByType(addressType: AddressType): Observable<IAddress> {
        return this.store.state$.pipe(
            map((addresses) => addresses.find((address) => address.type === addressType))
        );
    }

    findByTypeAndVehicleId(addressType: AddressType, vehicleId: number): Observable<IAddress> {
        return this.store.state$.pipe(
            map((addresses) => addresses.find((address) => address.type === addressType && address.vehicleId === vehicleId))
        );
    }

    upsertAddressOfType(addressType: AddressType, address: IAddress): void {
        this.store.upsertAddressOfType(addressType, address);
    }

    upsertAddressOfTypeAndVehicleId(addressType: AddressType, vehicleId: number, address: IAddress): void {
        this.store.upsertAddressOfTypeAndVehicleId(addressType, vehicleId, address);
    }

    deleteByTypeAndVehicleId(addressType: AddressType, vehicleId: number): void {
        this.store.deleteByTypeAndVehicleId(addressType, vehicleId);
    }
}
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, ParamMap, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';
import { Logger } from 'src/app/root/logger/logger';
import { environment } from 'src/environments/environment';
import { IApplicationState } from '../../model/application-state';
import { ApplicationStateService } from '../../services/application-state.service';
import { ApplicationFacade } from '../../stores/application-facade';

// tslint:disable: no-backbone-get-set-outside-model
@Injectable({ providedIn: 'root'})
export class UnratedAggregatorQuoteGuard implements CanActivate {

    constructor(
        private applicationStateService: ApplicationStateService,
        private applicationFacade: ApplicationFacade,
        private router: Router,
        private logger: Logger
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
        this.applicationFacade.reset();

        const aggregatorName = route.paramMap.get('aggregatorName');
        const id = route.paramMap.get('id');

        if (!aggregatorName || !id) {
            this.navigateToZipEntryPage();
            return false;
        }

        return this.applicationStateService.initializeByAggregatorNameAndId(aggregatorName, id).pipe(
            take(1),
            tap((applicationState) => this.onApplicationStateInitialized(applicationState, route)),
            map(() => false)
        );
    }

    private onApplicationStateInitialized(applicationState: IApplicationState, route: ActivatedRouteSnapshot): void {
        if (!applicationState) {
            this.logger.warn('No unrated aggregator quote found for', route.paramMap);
            this.navigateToZipEntryPage();
            return;
        }

        const queryParams = route.queryParams;

        if (queryParams && Object.keys(queryParams).length) {
            this.router.navigate([`/${environment.contextRoot}/custinfo/address`], {queryParams});
        } else {
            this.router.navigate([`/${environment.contextRoot}/custinfo/address`]);
        }
    }

    private navigateToZipEntryPage(): void {
        this.router.navigate([`/${environment.contextRoot}/entry/start`]);
    }

}
// tslint:enable: no-backbone-get-set-outside-model

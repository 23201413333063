import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { Observable, of } from 'rxjs';
import { map, pluck, take } from 'rxjs/operators';
import { IAgent } from '../model/agent';
import { IAgentContract } from '../model/agent-contract';
import { AgentStore } from './agent-store';
import { Facade } from './facade';

import {IPolicy} from '../../shared/model/policy'
@Injectable({ providedIn: 'root' })
export class AgentFacade extends Facade<IAgent> {

    static readonly INACTIVE_CONTRACT_STATUSES = ['INACTIVE', 'IN-ACTIVE'];

    private static LOB_PERSONAL_AUTO = 'Personal Auto';
    private static LOB_COMMERCIAL_AUTO = 'Commercial Auto';
    private static LOB_HOMEOWNERS = 'Homeowners';
    private static LOB_ALL_LINES = 'All Lines';

    constructor(protected store: AgentStore) {
        super(store);
    }

    setAgent(agent: IAgent) {
        this.store.setAgent(agent);
    }

    hasPersonalAutoContractsInState(state: string, policy:IPolicy): Observable<boolean> {
        if(policy.sourceOfBusiness === 'AI') {
            return of(true);
        } else {
            return this.hasContractsInStateAndLOBs(state, [AgentFacade.LOB_PERSONAL_AUTO, AgentFacade.LOB_ALL_LINES]);
        }
        
    }

    hasContractsInStateAndLOBs(state: string, contractLOBs: string[]): Observable<boolean> {
        if (!state || !contractLOBs || !contractLOBs.length) {
            return of(false);
        }

        return this.state.pipe(
            take(1),
            pluck('contracts'),
            map((contracts: IAgentContract[]) => {
                if (!contracts || !contracts.length) {
                    return false;
                }

                const matchingActiveContracts = contracts.filter((contract) =>
                    (contract.state || '').toUpperCase() === state.toUpperCase()
                        && _.includes(contractLOBs.map((lob) => (lob || '').toUpperCase()), (contract.lob || '').toUpperCase())
                        && !_.includes(AgentFacade.INACTIVE_CONTRACT_STATUSES, (contract.contractStatus || '').toUpperCase())
                );

                return matchingActiveContracts && matchingActiveContracts.length > 0;
            })
        );
    }
}
import {Injectable} from '@angular/core';
import * as _ from 'lodash';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {CoverageCode, CUSTOM_PACKAGE, ICoverage} from '../model/coverage';
import {CoveragesStore} from './coverages-store';
import {Facade} from './facade';

@Injectable({providedIn: 'root'})
export class CoveragesFacade extends Facade<ICoverage[]> {
    constructor(protected store: CoveragesStore) {
        super(store);
    }

    findCoveragesByPackageTypeCode(packageTypeCode: string): Observable<ICoverage[]> {
        return this.store.state$.pipe(
            map((coverages) => coverages.filter((coverage) => coverage.packageTypeCode === packageTypeCode))
        );
    }

    findByPackageTypeCodeAndCoverageCodeAndVehicleId(packageTypeCode: string, coverageCodes: string[] | CoverageCode[], vehicleId: number): Observable<ICoverage[]> {
        return this.store.state$.pipe(
            map((coverages) => coverages
                .filter((coverage) => coverage.packageTypeCode === packageTypeCode)
                .filter((coverage) => (coverageCodes || []).indexOf(coverage.coverageCode) !== -1)
                .filter((coverage) => vehicleId ? coverage.vehicleId === vehicleId : true)
            )
        );
    }

    hasCustomCoverages(): Observable<boolean> {
        return this.findCoveragesByPackageTypeCode(CUSTOM_PACKAGE).pipe(
            map((customCoverages) => !_.isEmpty(customCoverages))
        );
    }

    deleteCoveragesByPackageTypeCode(packageTypeCode: string): void {
        this.store.deleteCoveragesByPackageTypeCode(packageTypeCode);
    }

    addAll(items: ICoverage[]): void {
        this.store.addAll(items);
    }
}

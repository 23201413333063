import { environment } from 'src/environments/environment';
import { SecureStorage } from '../services/secure-storage';
import { Store } from './store';
import * as _ from 'lodash';
import {StringUtils} from "../../utils/string-utils";

export class StorageBackedStore<T> extends Store<T> {

    constructor(private itemId: string, private defaultInitialState: T, private storage: Storage = sessionStorage) {
        super(defaultInitialState);

        if (environment.secureStorage) {
            this.storage = new SecureStorage(storage);
        }

        this.itemId = itemId;

        if (this.storage.getItem(this.qualifiedItemId())) {
            this.setState(this.deserializeState());
        }
    }

    protected setState(nextState: T): void {
        super.setState(nextState);
        this.storage.setItem(this.qualifiedItemId(), JSON.stringify(nextState));
    }

    protected deserializeState(): T {
        return JSON.parse(this.storage.getItem(this.qualifiedItemId()));
    }

    reset() {
        this.storage.removeItem(this.qualifiedItemId());
        super.reset();
    }

    private qualifiedItemId(): string {
        return StringUtils.prependIfMissing(this.itemId, `${environment.contextRoot}.`);
    }
}
import { NgxLoggerLevel } from 'ngx-logger';
import { environment } from 'src/environments/environment';

export const loggerConfig = {
    // only log messages of this level or higher (OFF disables the logger for the client).
    level: environment.production ? NgxLoggerLevel.INFO : NgxLoggerLevel.DEBUG,

    // disables console logging, while still alerting the log monitor.
    disableConsoleLogging: false,

    // only send log messages of this level or higher to the server (OFF disables the logger for the server).
    serverLogLevel: environment.production ? NgxLoggerLevel.INFO : NgxLoggerLevel.DEBUG,

    // URL to POST logs
    serverLoggingUrl: environment.apiUrl + '/ui/log',

    enableSourceMaps: true
};
<div *ngIf="loading" class="loader-main">
  <div class="spinner">
    <img src="assets/imgs/loading.gif">
    <img class="blink-image" src="assets/imgs/spinner-logo.png">
  </div>
</div>
<div *ngIf="policyIssuanceLoading" class="loader-main">
  <div class="spinner policy-issuance">
    <div class="wait-header">Please wait....</div>
    <img src="assets/imgs/loading.gif">
    <p class="progress-text">Policy Issuance in Progress...</p>
    <div class="wait-content">
      <p>
        Please do not press the Back button or refresh your screen until policy issuance is completed. This will ensure
        your policy is issued correctly and only once.</p>
      <p>
        The policy issuance might take more than a minute. When issuance is complete, you will see a confirmation screen
        that provides you with access to your signed forms, evidence of insurance and a registration link for eService.
      </p>
      <p>
        Thank you for your cooperation.
      </p>
    </div>
  </div>
</div>

<app-header [policy]="policy$ | async" [uiState]="uiState$ | async"></app-header>
<router-outlet></router-outlet>
<app-state-printer *ngIf="displayAppState"></app-state-printer>
<div *ngIf="showFooter">
  <app-footer></app-footer>
</div>

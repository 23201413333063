import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { LoadingMaskService } from '../services/loading-mask.service';
import { Router } from '@angular/router';
import * as _ from 'lodash';

@Injectable({ providedIn: 'root' })
export class LoadingMaskInterceptor implements HttpInterceptor {

    /**
     * The current number of HTTP requests waiting for a response
     */
    private requestCount = 0;

    constructor(private loadingMaskService: LoadingMaskService,private router: Router) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        if (this.shouldSkip(req)) {
            return next.handle(req);
        }

        if (_.includes(req.url, '/ui/esign/purchase/submit/policydocuments')) {
            this.loadingMaskService.setPolicyIssuanceLoading(true);
        } else {
            this.loadingMaskService.setLoading(true);
        }

        this.requestCount++;

        return next.handle(req)
            .pipe(finalize(() => {
                this.requestCount--;

                // Once all HTTP requests are done, remove the loading mask
                if (this.requestCount === 0) {
                    this.loadingMaskService.setLoading(false);
                    this.loadingMaskService.setPolicyIssuanceLoading(false);
                }
            })
        );
    }
      
    private shouldSkip(req: HttpRequest<any>) {
        return req.url.endsWith('ui/log'); // Do not show loading mask for log requests
    }
}
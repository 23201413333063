<div class="modal-header">
    <h5 class="modal-title">Contact Us</h5>
    <button type="button" class="close" aria-label="Close" (click)="onClose()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div [innerHTML]="message"></div>
    <!-- <div class="mtb-25 text-center">
        <span class="cus-btn" *ngIf="_uiState.isMobile">           
            <a href="tel:{{servicePhoneNumber}}" (click)="onServicePhoneNumberClick()">Click to call</a>
        </span>
        <span class="cus-btn" *ngIf="!_uiState.isMobile">
               <a href="tel:{{servicePhoneNumber}}"
                (click)="onServicePhoneNumberClick()">{{showPhoneNumber? servicePhoneNumber: 'Click to call'}}</a>
        </span>
    </div> -->
</div>

import { Injectable } from '@angular/core';
import { IVehicle } from '../model/vehicle';
import { StorageBackedStore } from './storage-backed-store';
import produce from 'immer';

@Injectable({ providedIn : 'root'})
export class PrefillVehiclesStore extends StorageBackedStore<IVehicle[]> {

    constructor() {
        super('prefillvehicles', []);
    }

    addVehicle(vehicle: IVehicle) {
        const nextState = produce(this.state, (draft: IVehicle[]) => {
            const index = draft.findIndex((v) => v.vinNumber===vehicle.vinNumber );
            if (index > -1) {
              // do nothing 
            } else {
                draft.push(vehicle);
            }
        });

        this.setState(nextState);
    }
    updatevehicle(vehicle: IVehicle) {
        const nextState = produce(this.state, (draft: IVehicle[]) => {
            const index = draft.findIndex((v) => v.vinNumber ===vehicle.vinNumber);
            if (index > -1) {
                draft[index] = vehicle;
            }
        });

        this.setState(nextState);
    }
    
}
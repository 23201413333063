/* tslint:disable:member-ordering */
import {Injectable} from '@angular/core';
import {NgbModal, NgbModalOptions, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import {forkJoin} from 'rxjs';
import {pluck, take, tap, withLatestFrom} from 'rxjs/operators';
import {NumberUtils} from '../../utils/number-utils';
import {AddressValidationModalComponent} from '../components/address-validation-modal/address-validation-modal.component';
import {AlertmodalComponent} from '../components/alertmodal/alertmodal.component';
import {ConfirmationModalComponent} from '../components/confirmation-modal/confirmation-modal.component';
import {DeclinedPolicyModalComponent} from '../components/declined-policy-modal/declined-policy-modal.component';
import {NBMoratoriumHold} from '../components/nb-moratorium-hold-modal/nb-moratorium-hold-modal.component';
import {SessionTimeoutWarningModalComponent} from '../components/session-timeout-warning-modal/session-timeout-warning-modal.component';
import {TechnicalErrorComponent} from '../components/technical-error/technical-error.component';
import {formattedAgentPhone, IAgent} from '../model/agent';
import {isAggregatorQuote, MAX_INSURABLE_WEIGHT, MAX_POLICY_DRIVERS, MAX_POLICY_VEHICLES} from '../model/policy';
import {IVehicle, SubClass} from '../model/vehicle';
import {AgentFacade} from '../stores/agent-facade';
import {PolicyFacade} from '../stores/policy-facade';
import {UIFacade} from '../stores/ui-facade';
import {IAddressValidationModalConfig, IConfirmationModalConfig} from './modal-configs-index';

// tslint:disable: max-line-length
@Injectable({ providedIn: 'root' })
export class ModalService {
    private technicalErrorModal: NgbModalRef;

    constructor(
        private ngbModal: NgbModal,
        private agentFacade: AgentFacade,
        private policyFacade: PolicyFacade,
        private uiFacade: UIFacade) {
        window.onpopstate = () => this.closeTechnicalErrorModal();
    }

    openAlertModal(title: string, text: string, options?: NgbModalOptions, hideCloseButton = false, showOKButton = false): NgbModalRef {
        const modalRef = this.ngbModal.open(AlertmodalComponent, options);
        modalRef.componentInstance.title = title;
        modalRef.componentInstance.text = text;
        modalRef.componentInstance.hideCloseButton = hideCloseButton;
        modalRef.componentInstance.showOKButton = showOKButton;
        return modalRef;
    }

    openTechnicalErrorModal(): NgbModalRef {
        this.technicalErrorModal = this.ngbModal.open(TechnicalErrorComponent, {
            centered: true,
            scrollable: true,
            backdrop: 'static', // Prevent close on click
            keyboard: false // Prevent close on Esc key
        });

        return this.technicalErrorModal;
    }

    closeTechnicalErrorModal(): void {
        if (this.technicalErrorModal) {
            this.technicalErrorModal.close();
            this.technicalErrorModal = null;
        }
    }

    openNoEligibleDriversModal() {
        const modalText = 'At least one operator must be rated on this policy and have a valid license.';
        this.openAlertModal('Oops!', modalText, { centered: true });
    }

    openClosableMaxVehiclesExceededModal(): void {
        this.agentFacade.state.pipe(
            take(1),
            tap((agent) =>
                this.openAlertModal('Attention', 'If there are more than '
                    + MAX_POLICY_VEHICLES + ' motorcycles/ATVs that should be included in this quote, please call us at '
                    + formattedAgentPhone(agent)
                    + ' for a quote.', {
                    centered: true,
                    scrollable: true
                })
            )
        ).subscribe();
    }

    openUnclosableMaxVehiclesExceededModal(): void {
        this.agentFacade.state.pipe(
            withLatestFrom(this.policyFacade.state),
            tap(([agent, policy]) => {
                const title = 'Maximum Vehicles Exceeded';
                const text = isAggregatorQuote(policy) ?
                    ModalService.getAggregatorQuoteMaxVehiclesOrDriversExceededMessage(agent) :
                    `Your quote includes more than ${MAX_POLICY_VEHICLES} vehicles.
                    Please call ${formattedAgentPhone(agent)} to complete your quote.`;

                this.openAlertModal(title, text, {
                    centered: true,
                    scrollable: true,
                    backdrop : 'static',
                    keyboard: false
                }, true);
            })
        ).subscribe();
    }

    openOverweightVehiclesModal() {
        this.openAlertModal('Overweight Vehicle(s)',
            'We are sorry but you do not qualify for insurance coverage with us '
            + 'because a vehicle weighing ' + MAX_INSURABLE_WEIGHT + ' pounds or more '
            + 'is not eligible for coverage.', {
            centered: true
        });
    }

    openClosableMaxDriversExceededModal(onClose?: () => void): void {
        this.agentFacade.state.pipe(
            take(1),
            tap((agent) => {
                const modalRef = this.openAlertModal('Attention', 'If there are more than '
                    + MAX_POLICY_DRIVERS + ' people that should be included in this quote, licensed or not, please call us at '
                    + formattedAgentPhone(agent)
                    + ' for a quote.', {
                    centered: true,
                    scrollable: true
                });

                if (_.isFunction(onClose)) {
                    modalRef.result.then(() => onClose(), () => onClose());
                }
            })
        ).subscribe();
    }

    openUnclosableMaxDriversExceededModal(): void {
        this.agentFacade.state.pipe(
            withLatestFrom(this.policyFacade.state),
            tap(([agent, policy]) => {
                const title = 'Maximum Drivers Exceeded';
                const text = isAggregatorQuote(policy) ?
                    ModalService.getAggregatorQuoteMaxVehiclesOrDriversExceededMessage(agent) :
                    `Your quote includes more than ${MAX_POLICY_DRIVERS} drivers.
                    Please call ${formattedAgentPhone(agent)} to complete your quote.`;

                this.openAlertModal(title, text, {
                    centered: true,
                    scrollable: true,
                    backdrop : 'static',
                    keyboard: false
                }, true);
            })
        ).subscribe();
    }

    openAddressValidationModal(config: IAddressValidationModalConfig, options?: NgbModalOptions): void {
        const modalRef = this.ngbModal.open(AddressValidationModalComponent, {
            size: 'lg',
            scrollable: false,
            centered: true,
            ...(options || {})
        });

        modalRef.componentInstance.addressValidationResponse = config.addressValidationResponse;

        modalRef.result.then((answer) => {
            if (answer === 'original') {
                config.onUseOriginal(config.addressValidationResponse ? config.addressValidationResponse.inputAddr : null);
            }

            if (answer === 'suggested') {
                config.onUseSuggested(config.addressValidationResponse ? config.addressValidationResponse.suggestedAddr : null);
            }
        },
            () => { });
    }

    openConfirmationModal(config: IConfirmationModalConfig, options?: NgbModalOptions): void {
        const modalRef = this.ngbModal.open(ConfirmationModalComponent, {
            scrollable: true,
            centered: true,
            ...(options || {})
        });

        modalRef.componentInstance.config = config;

        modalRef.result.then((value) => {
            if (value && config.onConfirm) {
                config.onConfirm();
            }

            if (!value && config.onCancel) {
                config.onCancel();
            }
        },
            () => { });
    }

    openSaveFormChangesConfirmationModal(onConfirm: () => void, onCancel?: () => void): void {
        this.openConfirmationModal({
            title: 'Save Changes?',
            text: 'Do you want to save the change(s) you made on this page?',
            confirmText: 'Yes',
            cancelText: 'No',
            onConfirm,
            onCancel
        });
    }

    openSaveQuoteChangesConfirmationModal(onConfirm: () => void, onCancel?: () => void): void {
        this.openConfirmationModal({
            title: 'Important Message!',
            text: '<p>You have updated your coverage selections but you have not yet received a revised premium quote.<p>' +
                '<p>If you want to update your quote with these changes, click on the Recalculate My Rate button.</p>' +
                '<p>If you want to discard these changes, click on the Back button.</p>',
            confirmText: 'Recalculate My Rate',
            cancelText: 'Back',
            onConfirm,
            onCancel,
            hideCloseBtn: true
        }, {
            backdrop: 'static',
            keyboard: false
        });
    }

    openVinNotFoundModal(): void {
        this.agentFacade.state.pipe(
            take(1),
            tap((agent) =>
                this.openAlertModal('VIN Error',
                    'We are unable to find the motorcycle associated to the vehicle identification number you provided. If you have confirmed it is correct, please call us at ' + formattedAgentPhone(agent) + ' to complete the process.'
                )
            )
        ).subscribe();
    }

    openDuplicateVinModal(): void {
        this.openAlertModal('Duplicate VIN',
        'The specified VIN is already in your quote\'s vehicles.', {
            centered: true,
            scrollable: true
        });
    }

    openEserviceRegistrationAlert(): void {
        const title = 'Manage Your Policy';
        const text = '<p>Thank you for your interest in registering for Plymouth Rock\'s eService. </p> <p>Your new policy is not yet eligible for registration. Please come back to register as soon as you receive an email from Plymouth Rock confirming the purchase of your new policy.</p>';
        this.openAlertModal(title, text);
    }

    openEdocsThankYouPageAlert(): void {
        const title = 'Time to Sign Up for eDocuments';
        const text = '<p>To receive your policy and billing documents by email and to keep your eDocument policy discount, you will need to follow the link on an email you will receive in the next few minutes from us. Enrolling now will allow you to save a tree and save money!</p>';
        this.openAlertModal(title, text);
    }

    openQuotePremiumChangedModal(onConfirm: () => void, onCancel?: () => void, oldAmount: number = 0, newAmount: number = 0): void {
        this.openConfirmationModal({
            title: `Your quoted premium has changed`,
            text: `<p>While verifying driving records and reviewing the additional information you just provided, we found a discrepancy that is causing your quote to change from <strong>$${NumberUtils.toFixedDropTrailingZeros(oldAmount)}</strong> to <strong>$${NumberUtils.toFixedDropTrailingZeros(newAmount)}</strong>.</p>
            <p><strong>IMPORTANT:</strong> It is possible that one or more coverages you requested was removed from your quote based on the new information, so make sure to review your coverages prior to purchasing a policy.</p>`,
            confirmText: 'Continue with Purchase',
            cancelText: 'Go Back to My Quote',
            onConfirm,
            onCancel,
            hideCloseBtn: true
        }, {
            backdrop: 'static', // Prevent close on click
            keyboard: false // Prevent close on Esc key
        });
    }

    openDeclinationModal(): void {
        if (this.ngbModal.hasOpenModals()) {
            this.ngbModal.dismissAll();
        }

        forkJoin([
            this.policyFacade.state.pipe(take(1)),
            this.agentFacade.state.pipe(take(1)),
            this.uiFacade.state.pipe(take(1), pluck('declinePhone'))
        ])
        .pipe(
            tap(([policy, agent, declinePhone]) => {
                const modalRef = this.ngbModal.open(DeclinedPolicyModalComponent, {
                    centered: true,
                    scrollable: true,
                    backdrop: 'static', // Prevent close on click
                    keyboard: false // Prevent close on Esc key
                });

                modalRef.componentInstance.agent = agent;
                modalRef.componentInstance.policy = policy;
                modalRef.componentInstance.declinePhone = declinePhone;
            })
        ).subscribe();
    }

    openNBMoratoriumHoldModal(routeUrl?: string): void {
        if (this.ngbModal.hasOpenModals()) {
            this.ngbModal.dismissAll();
        }

        forkJoin([
            this.policyFacade.state.pipe(take(1)),
            this.agentFacade.state.pipe(take(1)),
            this.uiFacade.state.pipe(take(1))
        ])
        .pipe(
            tap(([policy, agent, uiState]) => {
                const modalRef = this.ngbModal.open(NBMoratoriumHold, {
                    centered: true,
                    scrollable: true,
                    backdrop: 'static', // Prevent close on click
                    keyboard: false // Prevent close on Esc key
                });

                modalRef.componentInstance.agent = agent;
                modalRef.componentInstance.policy = policy;
                modalRef.componentInstance.uiState = uiState;
                modalRef.componentInstance.routeUrl = routeUrl;
            })
        ).subscribe();
    }

    openAgentNotAuthorizedInStateModal(): void {
        if (this.ngbModal.hasOpenModals()) {
            this.ngbModal.dismissAll();
        }

        this.agentFacade.state.pipe(
            take(1),
            tap((agent) => {
                const modalText = 'We cannot offer you an online quote at this time ' +
                    'but the following agent will be happy to assist you by phone:<br><br>' +
                    `${agent ? (agent.name || 'No available agent') : ''}` + '<br>' +
                    `${formattedAgentPhone(agent)}`;

                this.openAlertModal('Sorry!', modalText, {
                    centered: true,
                    scrollable: true,
                    backdrop: 'static', // Prevent close on click
                    keyboard: false // Prevent close on Esc key
                }, true);
            })
        ).subscribe();
    }

    openAggregatorQuoteWelcomeModal(): void {
        const title = 'Welcome!';
        const text = `Welcome to Plymouth Rock!<br><br>Please review the information in this quote to ensure it is accurate.
            You may be asked to provide additional details. Please note it is possible these details,
            driving history reports, and/or other consumer reports may affect your rate.`;

        this.openAlertModal(title, text, { scrollable: true, centered: true });
    }

    openSessionTimeoutWarningModal(onContinueSession: () => void): NgbModalRef {
        const modalRef = this.ngbModal.open(SessionTimeoutWarningModalComponent, {
            scrollable: true,
            centered: true,
            backdrop: 'static',
            keyboard: false
        });

        modalRef.result.then(() => onContinueSession(), () => {});
        return modalRef;
    }

    openInvalidSubClassModal(subClass: SubClass | string): void {
        switch (_.toUpper(_.trim(subClass))) {
            case SubClass.SIDECAR:
                this.openAlertModal(
                    'Side Cars',
                    `Side cars will be covered for Comprehensive and/or Collision with the bike up to the policy limit
                    as long as it is listed and valued in NADA and installed by a factory-authorized installer. Sidecars
                    do not need to be listed separately. Please remove this vehicle.`);
                break;

            case SubClass.AUTOCYCLE:
            case SubClass.SNOWMOBILE:
                this.openAlertModal(
                    'We\'re Sorry',
                    'We are sorry but this type of vehicle subclass does not qualify for a motorcycle policy with this company');
                break;
        }
    }

    openVehicleSubClassInvalidModal(vehicle): void {
        const message = vehicle
            ? `We currently cannot provide coverage for the following vehicle:<br><br>${vehicle.year} ${vehicle.make} ${vehicle.model}<br>VIN: ${vehicle.vinNumber}`
            : 'We currently do not provide coverage for autocycles, sidecars, and snowmobiles';

        this.openAlertModal('Sorry!', message);
    }

    openPhysicalDamageCoverageRequiredModal(): void {
        this.openConfirmationModal({
            hideCancel: true,
            title: 'Physical Damage Coverage Required',
            text: `You have indicated at least one vehicle is leased or financed. Lease and finance companies require
                you to carry Comprehensive and Collision coverage on any vehicle you lease or finance. Please add
                these coverages to the vehicle(s) you lease or finance.`,
            onConfirm : null
        });
    }

    openVinDoesNotMatchVehicleSpecsModal(): void {
        this.openAlertModal('Vehicle Identification Number (VIN)', 'The VIN you provided does not match the make or model of the vehicle you added to your quote');
    }

    openElectricVehicleUninsurableModal(vehicle?: IVehicle): void {
        const message = vehicle
            ? `We currently cannot provide coverage for the following electric vehicle:<br><br>${vehicle.year} ${vehicle.make} ${vehicle.model}<br>VIN: ${vehicle.vinNumber}`
            : 'We currently do not provide coverage for electric vehicles.';

        this.openAlertModal('Sorry!', message);
    }

    openVehicleHasMissingHLDIDataModal(vehicle?: IVehicle): void {
        this.agentFacade.state.pipe(
            take(1),
            tap((agent) => {
                let modalText;

                if (vehicle) {
                    modalText = `We couldn't find all the information we need for the following vehicle:<br><br>
                        ${vehicle.year} ${vehicle.make} ${vehicle.model}<br>
                        VIN: ${vehicle.vinNumber}<br><br>
                        Please add this vehicle by year/make/model by clicking the 'Add a Vehicle' button or call us for help:<br><br>
                        <a href="tel:${formattedAgentPhone(agent)}">${formattedAgentPhone(agent)}</a>`;

                } else {
                    modalText = `We couldn't find all the information we need. Please add your vehicle by year/make/model or call us for help:<br><br>
                        <a href="tel:${formattedAgentPhone(agent)}">${formattedAgentPhone(agent)}</a>`;
                }

                this.openAlertModal('Sorry!', modalText, {
                    scrollable: true
                });
            })
        ).subscribe();
    }

    openDuplicateDriverModal(): void {
        const title = 'Oops! Duplicate rider(s) found';
        const modalText = 'You have entered duplicate rider(s) on your quote. Please remove any duplicate riders to continue.';
        this.openAlertModal(title, modalText, { centered: true });
    }

    private static getAggregatorQuoteMaxVehiclesOrDriversExceededMessage(agent: IAgent): string {
        return `Thank you for your interest in Plymouth Rock Assurance.
        <br/><br/>Because your quote includes more than ${MAX_POLICY_VEHICLES} motorcycles or ${MAX_POLICY_DRIVERS} drivers,
        you will need to call ${formattedAgentPhone(agent)} to complete your quote.
        We look forward to hearing from you soon!`;
    }

}

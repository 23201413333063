import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {StringUtils} from '../../utils/string-utils';
import {IPolicy} from '../model/policy';
import {Facade} from './facade';
import {PolicyStore} from './policy-store';

@Injectable({ providedIn: 'root' })
export class PolicyFacade extends Facade<IPolicy> {

    constructor(protected store: PolicyStore) {
        super(store);
    }

    getPrefixedSelectedPackage(): Observable<string> {
        return this.state.pipe(
            map((policy) => StringUtils.prependIfMissing(policy.selectedPackage, 'PACKAGE'))
        );
    }

}


export enum PaymentPlan {
    PP_1PAY = 'PP_1PAY',
    PP_4PAY = 'PP_4PAY'
}

export function overwritePaymentPlan() {
    (PaymentPlan as any).PP_1PAY = "HELLO";
    (PaymentPlan as any).PP_1PAY = "HELLO2";
    (PaymentPlan as any).PP_2PAY = "HELLO3";
}

export enum PaymentMethod {
    CARD = 'CREDIT_DEBIT_CARD'
}

export enum InstallmentsPaymentMethod {
    CHECKING_ACCOUNT = 'CHECKING_ACCOUNT',
    SAVINGS_ACCOUNT = 'SAVING_ACCOUNT'
}

export interface IPaymentOption {
    paymentPlanId?: number;
    policyKey?: number;
    packageTypeCode?: string;
    paymentTypeCode?: string;
    totalPremiumAmount?: number;
    downPaymentAmount?: number;
    installmentAmount?: number;
    numOfInstallments?: number;
    selectedPaymentPlanInd?: number | string;
    isEftAllowed?: string;
}

export function calculatePremiumFor(paymentOption: IPaymentOption): number {
    return (paymentOption.downPaymentAmount || 0) + ((paymentOption.installmentAmount || 0) * (paymentOption.numOfInstallments || 0));
}

export class IPayment {
    paymentPlan?: PaymentPlan; // The selected payment plan
    paymentMethod?: PaymentMethod; // Always set PaymentMethod.CARD
    debit?: boolean; // true if card should be processed as a debit card, false if card should be processed as credit
    paymentId?: string;
    // Downpayment Details
    cardholderName?: string; // The cardholder name
    cardNumber?: string; // This should be encrypted using EncryptionService.encrypt()
    decryptedCardNumber?: string; // This should not be sent to backend
    expirationDate?: string; // 'MM/YYYY' format
    securityCode?: string; // This should be encrypted using EncryptionService.encrypt()
    downPayment?: number; // The down payment amount to pay in the selected payment plan

    // Billing Address Details
    billingAddressZip?: string; // The billing address zip code

    // Installment Payment Details - EFT
    /**
     * Set as 'N' if user checks 'I do not want to enroll in EFT – Automatic Bank Withdrawals. I will initiate each payment to the company.' option
     * Set as 'Y' if user checks 'EFT - Automatic Bank Withdrawal' or if pay plan is EFT only (i.e. PP_6PAY or PP_12PAY)
     */
    futureInstallmentAutomaticWithdrawal?: string;
    installmentsAccountNumber?: string; // The checking/savings account number
    installmentsRoutingNumber?: string; // The bank routing number
    installmentsPaymentMethod?: InstallmentsPaymentMethod; // Whether the bank account is checking or savings

    // Manage Payments
    saveCard?: boolean;
}

<div class="modal-header">
    <h5 class="modal-title">Oops!</h5>
  </div>
  <div class="modal-body">
    <img src="../../../../assets/imgs/technical-issue-icon.png" class="technical-issue-icon">
    <h3 class="red title">We're Having a Technical Issue</h3>
    <p>
       We are unable to complete your transaction right now due to a technical issue.
       We fully expect this situation to be resolved shortly!
    </p>
    <p>
      Please retrieve your information and try again in a few hours.
      We appreciate your patience.
    </p>
  </div>
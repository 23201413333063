import { IIncident } from './incident';
import { IPaymentOption } from './payment';

export const AGGREGATOR_INCIDENTS_BY_DRIVER_SEQ_NUM_PATH = 'aggregator.incidents.byDriverSequenceNumber';
export const AGGREGATOR_INCIDENTS_IGNORED_DRIVER_SEQ_NUMS_PATH = 'aggregator.incidents.ignoredDriverSequenceNumbers';

/**
 * Store for state information that are used by the UI only. These fields do not get transmitted
 * or used by the backend services in any way.
 */
export interface IUIState {
    maxTransactionProgress?: number; // The maximum value of TransactionProgress that the UI is currently allowed to proceed with
    declinePhone?: string;
    defaultBrand?: boolean;
    servicePhone?: string;
    affinityGroupName?: string;
    productFromDateMillis?: number;
    productToDateMillis?: number;
    productFromDateMessage?: string;
    productToDateMessage?: string;
    hoCrossSellEnabled?: boolean;

    /**
     * Flag that indicates whether the user has already gone through the available policy rewards packages
     * and saved his selection. Used to determine whether or not to pre-select policy rewards packages
     * associated with a brand when presenting available rewards packages to the user
     */
    hasUserSelectedPolicyRewardsPackages?: boolean;

    /**
     * Flag that indicates whether the user has confirmed to change his/her PIP HealthCare Selection coverage
     */
    changePIPHealthCareSelection?: boolean;

    /**
     * Contains the payment plans available before the final rating in the checkout page, used as a basis for
     * determining whether the premium has changed since the last rating operation.
     */
    initialPaymentOptions?: IPaymentOption[];

    aggregator?: {
        /**
         * Incident state associated with an unrated aggregator quote
         */
        incidents?: {
            /**
             * Object whose keys are driver sequence numbers and values are arrays of IIncident.
             * Used to track associations between drivers and their corresponding incidents for drivers
             * that do not yet have a server-generated driver ID, as is the case for retrieved unrated
             * aggregator quotes.
             */
            byDriverSequenceNumber?: {[driverSequenceNumber: number]: IIncident[]};

            /**
             * Array of driver sequence numbers whose associated incidents will not be consumed because
             * they have already been consumed by the component that needs these incidents and do not need
             * to be consumed again
             */
            ignoredDriverSequenceNumbers: number[];
        };
    };

    /**
     * Suspicious actions done on form fields. The format of the strings contained by this field is action prefix + form control name
     * Entries for copy-pasted-fields would a prefix of 'CP_' (ex. 'CP_firstName'), while speed-typed fields would have a prefix of
     * 'ST_' (ex. 'ST_firstName')
     */
    suspiciousFormFieldActions?: string[];

    isManagePaymentsEnabled?: boolean;

    newPaymentPlanEnabled?: boolean;

    walletRccEnabled?: boolean;
    newPaySysEnabled?: boolean;
}
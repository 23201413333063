import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IIncident, IncidentType, IncidentSource } from '../model/incident';
import { Facade } from './facade';
import { IncidentsStore } from './incidents-store';

@Injectable({ providedIn: 'root' })
export class IncidentsFacade extends Facade<IIncident[]> {
    constructor(protected store: IncidentsStore) {
        super(store);
    }

    insertIncident(incident: IIncident): void {
        this.store.addIncident(incident);
    }

    deleteByDriverId(driverId: number): void  {
        this.store.deleteByDriverId(driverId);
    }

    findByDriverId(driverId: number): Observable<IIncident[]> {
        return this.store.state$.pipe(
            map((incidents) => incidents.filter((incident) => incident.driverId === driverId))
        );
    }

    findManuallyEnteredIncidentsByDriverId(driverId: number): Observable<IIncident[]> {
        return this.findByDriverId(driverId).pipe(
            map((incidents) => (incidents || []).filter((incident) => incident.source === IncidentSource.MANUAL))
        );
    }

}
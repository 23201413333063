export class SortFunction {
    private constructor() {}

    /**
     * Returns a sort function for the given field name field and direction
     * @param field the field name to sort by
     * @param direction the direction of the sort
     */
    // tslint:disable-next-line: function-name
    static of(field: string, direction: 'asc' | 'desc'): (a: any, b: any) => number {
        return (a, b) => {
            if (a[field] === b[field]) {
                return 0;
            }
            const result = a[field] > b[field] ? 1 : -1;
            return direction === 'asc' ? result : (result * -1);
        };
    }
}
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, ParamMap, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';
import { Logger } from 'src/app/root/logger/logger';
import { AggregatorService, IRetrieveRatedQuoteRequest, IRetrieveRatedQuoteResponse } from 'src/app/shared/services/aggregator.service';
import { environment } from 'src/environments/environment';
import { ApplicationFacade } from '../../stores/application-facade';

// tslint:disable: no-backbone-get-set-outside-model
@Injectable({ providedIn: 'root' })
export class RatedAggregatorQuoteGuard implements CanActivate {

    constructor(
        private aggregatorService: AggregatorService,
        private applicationFacade: ApplicationFacade,
        private logger: Logger,
        private router: Router
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        this.applicationFacade.reset();

        return this.retrieveQuote(route.queryParamMap).pipe(
            take(1),
            tap((response) => this.onQuoteRetrieved(response, route.queryParamMap)),
            map(() => false)
        );
    }

    private retrieveQuote(params: ParamMap): Observable<IRetrieveRatedQuoteResponse> {
        return this.aggregatorService.retrieveRatedQuote(this.toRatedQuoteRetrieveRequest(params));
    }

    private toRatedQuoteRetrieveRequest(params: ParamMap): IRetrieveRatedQuoteRequest {
        return {
            policyNumber: params.get('quoteNumber') || '',
            email: params.get('email') || '',
            lastName: params.get('lastName') || '',
            dob: params.get('dob') || '',
            zip: params.get('zip') || '',
            sourceOfEntry: params.get('source') || ''
        };
    }

    private onQuoteRetrieved(response: IRetrieveRatedQuoteResponse, params: ParamMap): void {
        const source = params.get('source');

        const baseQueryParams = {
            iRefid: source,
            iRefclickid: 'prepop',
            utm_campaign: source,
            utm_medium: 'ppc',
            utm_content: 'affiliate'
        };

        let queryParams;
        let redirectUrl;

        if (!response) {
            this.logger.warn('No rated aggregator quote found for', params);

            queryParams = { ...baseQueryParams, utm_source: source };
            redirectUrl = 'start';

        } else {
            this.logger.info('Successfully retrieved rated aggregator policy : ' + response.policyNumber);

            queryParams = {
                ...baseQueryParams,
                Quote_Number: response.policyNumber,
                zip: params.get('zip'),
                originalSourceOfData: response.originalSourceOfData,
            };

            redirectUrl = 'retrievequote';
        }

        this.router.navigate([`/${environment.contextRoot}/entry/${redirectUrl}`], { queryParams });
    }
}
// tslint:enable: no-backbone-get-set-outside-model
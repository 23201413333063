/* tslint:disable:function-name */
import * as _ from 'lodash';
import {environment} from '../../../environments/environment';

export class Referrer {
    private static value: string;

    static init() {
        Referrer.value = document.referrer;
    }

    static refValue(): string {
       return Referrer.value;
    }
}
import {Injectable, OnDestroy} from '@angular/core';
import {Router} from '@angular/router';
import {UserIdleService} from 'angular-user-idle';
import {forkJoin, Observable, Subject} from 'rxjs';
import {map, take, takeUntil, tap} from 'rxjs/operators';
import {Logger} from 'src/app/root/logger/logger';
import {environment} from 'src/environments/environment';
import {SessionTimeoutCountDown} from '../model/session-timeout-countdown';
import {AgentFacade} from '../stores/agent-facade';
import {ApplicationFacade} from '../stores/application-facade';
import {PolicyFacade} from '../stores/policy-facade';
import {ModalService} from './modal.service';

@Injectable({ providedIn : 'root' })
export class SessionListener implements OnDestroy {
    private cmpDestroyed$ = new Subject<boolean>();

    constructor(
        private userIdle: UserIdleService,
        private modalService: ModalService,
        private policyFacade: PolicyFacade,
        private agentFacade: AgentFacade,
        private applicationFacade: ApplicationFacade,
        private router: Router,
        private logger: Logger,
        private sessionTimeoutCountDown: SessionTimeoutCountDown
    ) {}

    initialize(): void {
        this.userIdle.startWatching();

        this.userIdle.onTimerStart().pipe(
            tap((remainingSeconds) => this.onCountdownTimerTick(remainingSeconds)),
            takeUntil(this.cmpDestroyed$)
        ).subscribe();

        this.userIdle.onTimeout().pipe(
            tap(() => this.onSessionTimeout()),
            takeUntil(this.cmpDestroyed$)
        ).subscribe();
    }

    protected onCountdownTimerTick(remainingSeconds: number): void {
        // remaininingSeconds is an increasing value (i.e. 1, 2, 3...).
        // This converts it to decreasing value (i.e. 3, 2, 1...)
        const countdown = environment.sessionExpWarningTime - remainingSeconds + 1;

        // userIdle.onTimeout() occurs 1 tick before countdown reaches 0, so we pass the value of countdown
        // advanced by 1 tick as the remainingSeconds$ value to be in sync with when onTimeout() gets called
        this.sessionTimeoutCountDown.remainingSeconds$.next(countdown - 1);

        if (countdown === environment.sessionExpWarningTime) {
            this.modalService.openSessionTimeoutWarningModal(
                () => {
                    this.userIdle.resetTimer();
                    this.userIdle.startWatching();
                }
            );
        }
    }

    protected onSessionTimeout(): void {
        this.navigateToEntryPage().pipe(
            tap((_) => this.applicationFacade.reset())
        ).subscribe();
    }

    private navigateToEntryPage(): Observable<any> {
        return this.getEntryPageRedirectQueryParams().pipe(
            take(1),
            tap((queryParams) => this.router.navigate(['/'], { queryParams }))
        );
    }

    private getEntryPageRedirectQueryParams(): Observable<any> {
        return forkJoin([
            this.policyFacade.state.pipe(take(1)),
            this.agentFacade.state.pipe(take(1)),
        ]).pipe(
            take(1),
            map(([policy, agent]) => ({
                PLF_Code: policy.plfCode || '',
                lob: policy.lob || '',
                channel: policy.channel || '',
                Grpid: policy.affinityGroupCode || '',
                agentId: agent ? (agent.producerCode || '') : '',
                iRefid: policy.iRefid || '',
                iRefclickid: policy.iRefClickid || '',
                sob: policy.sourceOfBusiness || '',
                referralSourceCD: policy.brand
            }))
        );
      }

    ngOnDestroy(): void {
        this.cmpDestroyed$.next(true);
        this.cmpDestroyed$.complete();
    }

}
import {Injectable} from '@angular/core';
import {Observable, throwError, timer} from 'rxjs';
import {mergeMap} from 'rxjs/operators';
import {Logger} from '../../root/logger/logger';

@Injectable({ providedIn: 'root' })
export class BackoffStrategy {

    constructor(private logger: Logger) {
    }

    /**
     * Creates an exponential backoff strategy (i.e. increases wait time in between retries).
     * @param additionalLogData data to log when attempting to retry the failed operation, or when the maximum number of attempts is reached
     * @param maxRetryAttempts the maximum number of retries to attempt
     * @param scalingDuration the interval in milliseconds to use as the factor for the delay between retry attempts
     */
    create(additionalLogData: any = {}, maxRetryAttempts = 6, scalingDuration = 3000): (errors) => Observable<any> {
        return (errors) => errors.pipe(
            mergeMap((error, i) => {
                const retryAttempt = i + 1;

                if (retryAttempt > maxRetryAttempts) {
                    this.logger.error('Max retry attempts reached', error, additionalLogData);
                    return throwError(error);
                }

                this.logger.warn(`Attempt ${retryAttempt}: retrying in ${retryAttempt * scalingDuration} ms.`, error, additionalLogData);
                // retry after .5s, 1s, 1.5s, 2, 2.5, 3s (if the default maxRetryAttempts and scalingDuration are used)
                return timer(retryAttempt * scalingDuration);
            })
        );
    }

}
import {Injectable} from '@angular/core';
import {IPayment} from '../model/payment';
import {Facade} from './facade';
import {PaymentStore} from './payment-store';

@Injectable({providedIn: 'root'})
export class PaymentFacade extends Facade<IPayment> {

    constructor(protected store: PaymentStore) {
        super(store);
    }
}
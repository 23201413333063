import {Injectable} from '@angular/core';
import * as _ from 'lodash';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {MAX_INSURABLE_WEIGHT} from '../model/policy';
import {IVehicle, PurchaseMethod} from '../model/vehicle';
import {Facade} from './facade';
import {VehiclesStore} from './vehicles-store';

@Injectable({ providedIn: 'root' })
export class VehiclesFacade extends Facade<IVehicle[]> {

    constructor(protected store: VehiclesStore) {
        super(store);
    }

    insertVehicle(vehicle: IVehicle): void {
        this.store.insertVehicle(vehicle);
        this.store.defaultSort();
    }

    deleteVehicleById(vehicleId: number): void {
        this.store.deleteVehicleById(vehicleId);
        this.store.defaultSort();
    }

    deleteVehicleByVin(vin: string): void {
        this.store.deleteVehicleByVin(vin);
        this.store.defaultSort();
    }

    deleteVehicleByVinAndSeqNum(vin: string, seqNum: number): void {
        this.store.deleteVehicleByVinAndSeqNum(vin, seqNum);
        this.store.defaultSort();
    }

    updateVehicleByVinAndSeqNum(vin: string, seqNum: number, vehicle: IVehicle): void {
        this.store.updateVehicleByVinAndSeqNum(vin, seqNum, vehicle);
    }

    findVehicleBySeqNum(vehicleSeqNum: number): Observable<IVehicle> {
        return this.state.pipe(
            map((vehicles) => vehicles.find((vehicle) => vehicle.seqNum === vehicleSeqNum))
        );
    }

    findVehicleById(vehicleId: number): Observable<IVehicle> {
        return this.state.pipe(
            map((vehicles) => vehicles.find((vehicle) => vehicle.vehicleId === vehicleId))
        );
    }

    getOverweightVehicles(): Observable<IVehicle[]> {
        return this.state.pipe(
            map((vehicles) => vehicles.filter((vehicle) => vehicle.weight >= MAX_INSURABLE_WEIGHT))
        );
    }

    getAllVehicleIds(): Observable<number[]> {
        return this.state.pipe(
            map((vehicles) => vehicles.map((vehicle) => vehicle.vehicleId))
        );
    }

    /**
     * Returns vehicles whose vinNumbers is equal to the given vinNumber and whose seqNum is not equal to seqNum
     */
    findVehiclesByVinWithSeqNumNotEqualTo(seqNum: number, vinNumber: string): Observable<IVehicle[]> {
        return this.state.pipe(
            map((vehicles) => vehicles.filter(
                (vehicle) => vehicle.seqNum !== seqNum && vehicle.vinNumber === vinNumber
            ))
        );
    }

    findByPurchaseMethod(...purchaseMethods: string[] | PurchaseMethod[]): Observable<IVehicle[]> {
        return this.state.pipe(
            map((vehicles) => vehicles.filter(
                (v) => _.includes(purchaseMethods, v.purchaseMethod))
            )
        );
    }

}
/* tslint:disable:member-ordering */
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import * as _ from 'lodash';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {environment} from 'src/environments/environment';
import {PrefillService} from '../../quote-modules/drivers/services/prefill.service';
import {UWService} from '../../quote-modules/your-quote/services/uw.service';
import {Logger} from '../../root/logger/logger';
import {IApplicationState} from '../model/application-state';
import {TransactionProgress} from '../model/transaction-progress';
import {ModalService} from '../services/modal.service';
import {ApplicationFacade} from '../stores/application-facade';
import {UIFacade} from '../stores/ui-facade';

@Injectable({providedIn: 'root'})
export class HttpErrorInterceptor implements HttpInterceptor {
    private static readonly ESIGN_ERROR_MARKER = 'ESIGN';
    private static readonly ISSUE_POLICY_ERROR_MARKER = 'ISSUE POLICY';
    private static readonly PAYMENT_ERROR_MARKER = 'PAYMENT';

    constructor(
        private modalService: ModalService,
        private router: Router,
        private route: ActivatedRoute,
        private logger: Logger,
        private applicationFacade: ApplicationFacade,
        private uiFacade: UIFacade
    ) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (HttpErrorInterceptor.shouldSkip(req)) {
            return next.handle(req);
        }

        return next.handle(req).pipe(
            catchError((error: HttpErrorResponse) => this.onError(error, req))
        );
    }

    private static shouldSkip(req: HttpRequest<any>) {
        return req.url.endsWith('ui/log' || 'doubleclick' || '.js' || '.png' || '.jpg' || '.svg' || '.html' || '.ico');
    }

    // tslint:disable-next-line:max-func-body-length
    private onError(error: HttpErrorResponse, req: HttpRequest<any>): Observable<any> {
        // 409 Conflict means there is a conflict between the UI state and the backend application state (for instance,
        // the policy is already 'issued' in the backend while the UI state is still indicating that the policy is not
        // yet issued. For this case, we update the UI state with the application state from the backend to sync them up
        if (error.status === 409) {
            this.onConflict(error);

        } else if (error.status === 455) {
            this.logError(HttpErrorInterceptor.ESIGN_ERROR_MARKER, error, 'warn');

            this.modalService.openAlertModal('E-signature Verification Failed',
                'Please enter your name exactly as you entered it on the first page of this quote.',
                {scrollable: true},
                true,
                true
            );

        } else if (error.status === 460) {
            this.logError(HttpErrorInterceptor.ESIGN_ERROR_MARKER, error);

            this.modalService.openAlertModal('E-signature Error', 'E-signature process complete for this policy', {scrollable: true});

        } else if (error.status === 461) {
            this.logError(HttpErrorInterceptor.ESIGN_ERROR_MARKER, error);

            this.modalService.openAlertModal('E-signature Error', 'E-signature expired for this policy', {scrollable: true});

        } else if (error.status === 462) {
            this.logError(HttpErrorInterceptor.ESIGN_ERROR_MARKER, error);

            this.modalService.openAlertModal('E-signature Error', 'E-signature stopped by agent', {scrollable: true});

        } else if (error.status === 463) {
            this.logError(HttpErrorInterceptor.ESIGN_ERROR_MARKER, error);

            this.modalService.openAlertModal('E-signature Error',
                'There is no active e-signature document on  this policy. Please contact your agent.',
                {scrollable: true}
            );

        } else if (error.status === 456) {
            this.logError(HttpErrorInterceptor.ISSUE_POLICY_ERROR_MARKER, error);

            this.router.navigate([`/${environment.contextRoot}/purchase/checkout`]);

            this.modalService.openAlertModal('Payment Error',
                'Our payment system is currently experiencing technical difficulties. We expect this issue to be resolved shortly so please retrieve your quote in a few hours and try again.',
                {scrollable: true}
            );

        } else if (error.status === 402) {
            this.logError(HttpErrorInterceptor.PAYMENT_ERROR_MARKER, error);

            this.router.navigate([`/${environment.contextRoot}/purchase/checkout`]);

            this.modalService.openAlertModal('Payment Error', error.error, {scrollable: true});

        } else if (HttpErrorInterceptor.showTechnicalErrorModal(req)) {
            this.modalService.openTechnicalErrorModal();
        }

        return throwError(error);
    }

    private onConflict(error: HttpErrorResponse): void {
        const updatedApplicationState: IApplicationState = error.error;

        this.logError(HttpErrorInterceptor.ISSUE_POLICY_ERROR_MARKER, error, 'warn');

        if (!_.isEmpty(updatedApplicationState)) {
            this.applicationFacade.patch(updatedApplicationState, true);
        }

        if (!_.isEmpty(updatedApplicationState) && updatedApplicationState.policy && updatedApplicationState.policy.issued) {
            this.uiFacade.patch({maxTransactionProgress: TransactionProgress.THANKYOU});
            this.router.navigate([`/${environment.contextRoot}/purchase/thank-you`]);

        } else {
            this.modalService.openTechnicalErrorModal();
        }
    }

    private logError(marker: string, error: HttpErrorResponse, level: 'info' |'warn' | 'error' = 'error'): void {
        const message = `[${marker}: Service Error] Service call returned ${error.status} ${error.statusText || ''}: ${error.message || 'No associated error message'}`;
        const details = (error.error || '');

        switch (level) {
            case 'error':
                this.logger.error(message, details);
                break;
            case 'warn':
                this.logger.warn(message, details);
                break;
            default:
                this.logger.info(message, details);
                break;
        }
    }

    private static showTechnicalErrorModal(req: HttpRequest<any>) {
        return !req.url.endsWith(PrefillService.GET_PREFILL_DATA_ENDPOINT)
            && !req.url.endsWith(UWService.UW_VALIDATE_ADDRESS_ENDPOINT);
    }
}
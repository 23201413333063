import { Component, Input,OnInit} from '@angular/core';
import { IPolicy } from 'src/app/shared/model/policy';
import { IUIState } from 'src/app/shared/model/ui-state';
import { environment } from 'src/environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  cobrandURL=environment.cobrandURL;
  headerDesktopLogo='assets/imgs/header-logo-desktop.png';
  headerMobileLogo='assets/imgs/header-logo-mobile.png';
  _policy: IPolicy;
  plfcode=this.getUrlParameter('PLF_Code');
  @Input() set policy(policy: IPolicy) {
    this._policy=policy;
    if(policy.brand === 'nj_teac' ||this.plfcode === 'TEAC' || policy.plfCode === 'TEAC' ) {
      this.setTEACBranding();
    } else {
      this.unSetTEACBranding();
    }
  }

  @Input() uiState: IUIState;
  constructor() { }

  ngOnInit() {
  
   if(this.plfcode === 'TEAC') {
    this.setTEACBranding();
   }
  
  }
  setTEACBranding() {
    this.headerDesktopLogo='assets/imgs/teac-desk.png';
    this.headerMobileLogo='assets/imgs/teac-mobile.png';
  }

  unSetTEACBranding() {
  this.headerDesktopLogo='assets/imgs/header-logo-desktop.png';
  this.headerMobileLogo='assets/imgs/header-logo-mobile.png';
  }

  getUrlParameter(name) { // we are using this because header not loads under any active route 
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    var results = regex.exec(location.search);
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
};

  
}

/* tslint:disable:member-ordering */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { Logger } from 'src/app/root/logger/logger';
import { IPolicy } from 'src/app/shared/model/policy';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class PrefillService {
  static readonly GET_PREFILL_DATA_ENDPOINT = '/policy/vehicleprefill';

  private apiUrl = environment.apiUrl;

  constructor(private http: HttpClient, private logger: Logger) { }

  getPrefillData(): Observable<IPrefillResponse> {
    return this.http.post<IPrefillResponse>(this.apiUrl + PrefillService.GET_PREFILL_DATA_ENDPOINT, null);
  }

  savePrefillVehicles(vins: string[], policy: IPolicy, lookupSourceCode: LookupSourceCode, appendToExistingPrefill = false): Observable<string[]> {
    if (!vins || !vins.length) {
      return of([]);
    }

    const request = {
      policyKey: policy.policyKey,
      lookupSourceCode,
      lookupSourceVehicles: vins
    };

    const serviceUrl = this.apiUrl + '/policy/prefill/vehicles';
    const serviceCall$ = appendToExistingPrefill ? this.http.put(serviceUrl, request) : this.http.post(serviceUrl, request);

    this.logger.info('Saving vins as prefill vehicles', vins, lookupSourceCode);

    return serviceCall$.pipe(map(() => vins));
  }

}

export enum LookupSourceCode {
  RMV = 'RMV',
  PREFILL = 'PREFILL'
}

export interface IPrefillResponse {
  policyKey: number;
  vehicles: IPrefillVehicle[];
  currentPolicyInForce: string;
  policyInForce: string;
  lengthOfInsurance: string;
  policyCarrierName: string;
  policyCarrierCode: string;
  priorLimitRange: string;
  priorLimitValue: string;
  policyCarrierCategory: string;
  responseId: string;
  policyThirdPartyId: number;
  status: string;
}

export interface IPrefillVehicle {
  vehicleBusinessIndicator: string;
  primaryUseCode: string;
  vehicleLeasedIndicator: string;
  purchasedOrLeasedNewInd: string;
  vehicleLeasedFinancedIndicator: string;
  customizedEquipmentIndicator: string;
  model: string;
  make: string;
  makeDescription: string;
  bodyTypeCode: string;
  modelYear: number;
  garagingAddressId: number;
  policyKey: number;
  thirdPartyDataIndicator: string;
  vin: string;
  altGaragingIndicator: string;
  compCollIndicator: string;
  primaryLoadTypeCode: string;
  vehicleCompOnlyIndicator: string;
  highTheftIndicator: string;
  vehicleTypeCode: string;
  annualMileage: string;
  calculatedAnnualMileage: string;
  odometerReadingDate: string;
  odometerReadingValue: string;
  priorOdometerReadingValue: string;
  priorOdometerReadingDate: string;
  vehicleOwnershipTypeCode: string;
  vehicleDrivers: string;
  additionalInterests: string;
  vehicleLoads: string;
  vehiclePackages: string;
  vehicleId: number;
  vehSeqNum: number;
  antiTheftDevices: string;
  vehicleMilageUnder1000IND: string;
  snowPlowEquipIndicator: string;
  symbol: string;
  costNewAmt: number;
  vehicleThirdPartyId: number;
  weight: number;
  newUsedVehicleCd: string;
  tncUsageIndicator: string;
  tncVehicleCompanyCode: string;
  tncUsageLevelIndicator: string;
  tncVehicleDriverName: string;
  passiveRestraintIndicator: string;
  tncVehicleCoverageVersion: string;
  safetyRestraintType: string;
  tncUsageIndicatorCode: string;
  rmvLienCodeUpdtIndicator: string;
  daytimeRunningLightsIndicator: string;
  daytimeRunningLightsCode: string;
  antiLockBrakesCode: string;
  antiLockBrakesIndicator: string;
  leasedLess6MonthsIndicator: string;
  otherRegisteredOwnerName: string;
  inspectionIndicator: string;
  ratingTerritoryCd: string;
  lookupSourceCode: string;
}

export interface IUpdatePrefillVehiclesRequest {
  policyKey: number;
  lookupSourceCode: LookupSourceCode;
  lookupSourceVehicles: string[]; // VINs
}
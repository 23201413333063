import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';

@Injectable({providedIn: 'root'})
export class UWService {

    public static readonly UW_VALIDATE_ADDRESS_ENDPOINT = '/uw/uwaddressverification';

    constructor(private http: HttpClient) {
    }

    validateAddress(): Observable<null> {
        return this.http.post<null>(environment.apiUrl + UWService.UW_VALIDATE_ADDRESS_ENDPOINT, null);
    }
}

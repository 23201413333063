import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { IAddressValidationAddress, IAddressValidationResponse } from '../../services/address-validation-service';

@Component({
  selector: 'app-address-validation-modal',
  templateUrl: './address-validation-modal.component.html',
  styleUrls: ['./address-validation-modal.component.scss']
})
export class AddressValidationModalComponent {

  @Input() addressValidationResponse: IAddressValidationResponse;

  constructor(public activeModal: NgbActiveModal) { }

  get suggestedAddress(): IAddressValidationAddress {
    return this.addressValidationResponse ? this.addressValidationResponse.suggestedAddr : null;
  }

  get originalAddress(): IAddressValidationAddress {
    return this.addressValidationResponse ? this.addressValidationResponse.inputAddr : null;
  }

  onUseOriginalAddressClick(): void {
    this.activeModal.close('original');
  }

  onUseCorrectAddressClick(): void {
    this.activeModal.close('suggested');
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { CookieService } from 'ngx-cookie-service';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';

import { Logger } from 'src/app/root/logger/logger';
import { PolicyFacade } from 'src/app/shared/stores/policy-facade';
import { environment } from 'src/environments/environment';

import { CampaignKey, Variation } from '../model/vwo';
import {Cookie} from '../model/cookie';

@Injectable({ providedIn: 'root' })
export class VwoService {
    private static readonly VWO_SERVICE_URL = `${environment.apiUrl}/vwo`;
    private static readonly VWO_SERVICE_URL_THANK_YOU = `${environment.apiUrl}/vwo`;
    private static readonly DEFAULT_STAGING_CAMPAIGN_KEY_SUFFIX = 'STAGING';

    constructor(
        private http: HttpClient,
        private policyFacade: PolicyFacade,
        private logger: Logger,
        private cookieService: CookieService) { }

    /**
 * Returns the variation for the given campaign key and custom variables based on the current policy number as the VWO user id.
 * If there is a need to fetch variations for multiple campaigns in one go, consider using getVariations() instead.
 * @param campaignKey the campaign key for which variations will be fetched
 * @param customVariables (optional) the custom variables to use for user segmentation
 */
    getVariation(campaignKey: CampaignKey, customVariables?: { [variableName: string]: any }): Observable<string> {
        let customVariablesWrapper = null;

        if (customVariables) {
            customVariablesWrapper = {};
            customVariablesWrapper[campaignKey] = customVariables;
        }

        return this.getVariations([campaignKey], customVariablesWrapper).pipe(
            map((results) => {
                return results[campaignKey]
            })
        );
    }

    /**
     * Returns the variations for the given campaign keys based on the current policy number as the VWO user id. The returned
     * object will have campaign keys as its properties and the property values will be their variations.
     * Example:
     *   Given that campaignKeys=[Campaign1, Campaign2, Campaign3], the returned object will be:
     *   {
     *       Campaign1: "FooVariation",
     *       Campaign2: "BarVariation",
     *       Campaign3: "Control"
     *   }
     * @param campaignKeys array of CampaignKeys for which variations will be fetched
     * @param customVariables (optional) object whose key/s are the campaign keys and whose values are objects containing
     * the custom variables for user segmentation per campaign key.
     * Example:
     *   Given that campaignKeys=[Campaign1, Campaign2, Campaign3], if user segments within Campaign1 and Campaign3 need to
     *   be targeted using customVariables, then the customVariables should be defined as:
     *
     *   customVariables = {
     *       Campaign1: {
     *           foo: "foo",
     *           bar: true
     *           ...
     *       },
     *       Campaign3: {
     *           baz: 3,
     *           ...
     *       }
     *   }
     */
    // tslint:disable-next-line:max-func-body-length
    getVariations(campaignKeys: CampaignKey[], customVariables?: { [campaignKey: string]: { [variableName: string]: any } }): Observable<{ [campaignKey: string]: string }> {
        if (!campaignKeys) {
            return of({});
        }


        const mapping = customVariables;

        const activateCalls$: Observable<string>[] = campaignKeys.map((campaignKey) => {
            return this.policyFacade.state.pipe(
                take(1),
                map((policy): IVWOSdkParams => {
                    const nCampaignKey = VwoService.normalizedKey(campaignKey);
                    const userId = policy.policyNumber;
                    const options = mapping && mapping[campaignKey] ? { customVariables: mapping[campaignKey] } : null;
                    const context = { udid: this.cookieService.get(Cookie.UserDeviceId) };
                    return ({
                        campaignKey: nCampaignKey,
                        userId,
                        options,
                        context
                    })
                }),
                switchMap((request) => {
                    return this.http.post<string>(`${VwoService.VWO_SERVICE_URL}/activate`, request).pipe(
                        tap((variation) => {
                            if (!variation) {
                                this.logger.info('[VWO UI] No variation returned by VWO API for request', request);
                            }
                        }),
                        map((variation) => variation || Variation.Control),
                        // This will implemented when we have more 'campaignKey' available
                        // tap((variation) => {this.uiFacade.addVwoProps(campaignKey, variation);}),
                        catchError(() => of(Variation.Control))
                    );
                }
                )
            );
        });

        return forkJoin(activateCalls$).pipe(
            map((variations) => _.zipObject(campaignKeys, variations))
        );
    }

    /**
     * Returns the environment-specific campaign key for the given campaignKey (i.e. production vs non-production).
     * By default, the non-production campaign key would have '-STAGING' appended to the CampaignKey associated with
     * this campaign. If a suffix is provided, then that suffix will be used instead of 'STAGING'.
     *
     * Example:
     * Production environment:
     *     normalizedKey(CampaignKey.QuickQuote) -> 'QuickQuote'
     *     normalizedKey(CampaignKey.QuickQuote, 'fooSuffix') -> 'QuickQuote'
     *
     * Non-Production environment:
     *     normalizedKey(CampaignKey.QuickQuote) -> 'QuickQuote-STAGING'
     *     normalizedKey(CampaignKey.QuickQuote, 'fooSuffix') -> 'QuickQuote-fooSuffix'
     */
    private static normalizedKey(campaignKey: CampaignKey, suffix?: string): string {
        return environment.profile === 'production' ? campaignKey : campaignKey + '-' + (suffix || VwoService.DEFAULT_STAGING_CAMPAIGN_KEY_SUFFIX);
    }
}

export interface IVWOSdkParams {
    userId: string;
    campaignKey: string;
    goalIdentifier?: string; // Used in 'track' calls
    variableKey?: string; // Used in 'getFeatureVariableValue' calls
    options?: {
        customVariables?: { [key: string]: any };
        variationTargetingVariables?: { [key: string]: any };
        goalTypeToTrack?: 'REVENUE_TRACKING' | 'CUSTOM_GOAL' | 'ALL';
        shouldTrackReturningUser?: boolean
    };
    context?: IVWOContext;
}

export interface IVWOContext {
    udid: string; // User device id
}
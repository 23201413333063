import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { IConfirmationModalConfig } from '../../services/modal-configs-index';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss']
})
export class ConfirmationModalComponent {
  title: string;
  text: string;
  cancelText = 'Cancel';
  confirmText = 'OK';
  onConfirm: () => void;
  onCancel: () => void;
  hideCancel = false;
  hideCloseBtn=false;
  @Input() set config(config: IConfirmationModalConfig) {
    Object.assign(this, config || {});
  }

  constructor(public activeModal: NgbActiveModal) { }

  onResponse(value: boolean): void {
    this.activeModal.close(value);
  }
}

import { BehaviorSubject, Observable } from 'rxjs';

export abstract class Store<T> {
    readonly state$: Observable<T>;
    private readonly stateSubject: BehaviorSubject<T>;
    private initialState: T;

    protected constructor(initialState: T) {
        this.stateSubject = new BehaviorSubject(initialState);
        this.state$ = this.stateSubject.asObservable();
        this.initialState = initialState;
    }

    protected get state(): T {
        return this.stateSubject.getValue();
    }

    protected setState(nextState: T): void {
        this.stateSubject.next(nextState);
    }

    patch(values: T): void {
        if (Array.isArray(values)) {
            this.setState([...values] as unknown as T);
        } else {
            this.setState({ ...this.state, ...values });
        }
    }

    reset() {
        this.setState(this.initialState);
    }
}
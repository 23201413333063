/* tslint:disable:member-ordering */
import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable, Injector } from '@angular/core';
import * as StackTrace from 'stacktrace-js';
import { Logger } from '../logger/logger';

@Injectable()
export class GlobalErrorHandler extends ErrorHandler {
    private static readonly CHUNK_LOAD_FAILED_MESSAGE: RegExp = /Loading chunk [\d]+ failed/i;

    constructor(private injector: Injector) {
        super();
    }

    handleError(error: any): void {
        super.handleError(error);

        try {
            if (GlobalErrorHandler.isChunkLoadError(error)) {
                window.location.reload();
                return;
            }

            if (error instanceof Error) {
                this.logStackTrace(error, StackTrace.fromError(error));
            } else {
                this.logStackTrace(error, Promise.resolve(['']));
            }
        } catch (e) {
            this.getLogger().error('Error handler threw exception : ', e, 'Error for handling was : ', error);
        }
    }

    private logStackTrace(error: any, stack: Promise<any>) {
        const logger = this.getLogger();
        const errorMessage = GlobalErrorHandler.getErrorMessage(error);
        const url = this.getUrl(error);

        stack.then((stackFrames) => Promise.resolve(stackFrames.map((sf) => sf.toString()).join('\n')))
            .then((stackString) => logger.error(GlobalErrorHandler.createLoggerPayload(errorMessage, url, stackString)))
            .catch((err) => logger.error('Unable to generate error stack trace : ' + GlobalErrorHandler.getErrorMessage(err), 'Original error : ' + errorMessage));
    }

    private static createLoggerPayload(errorMessage: string, url = '', stackString = ''): any {
        return {
            url,
            message: JSON.stringify({
                errorMessage,
                stackTrace: stackString
            }, null, 0),
        };
    }

    private getLogger(): Logger {
        return this.injector.get(Logger);
    }

    private getUrl(error: any): string {
        if (error instanceof HttpErrorResponse) {
            return error.url;

        } else {
            const location = this.injector.get(LocationStrategy);
            return location instanceof PathLocationStrategy ? location.path() : '';
        }
    }

    private static getErrorMessage(error: any): string {
        return error.message ? error.message : error.toString();
    }

    private static isChunkLoadError(error: any): boolean {
        return GlobalErrorHandler.CHUNK_LOAD_FAILED_MESSAGE.test(GlobalErrorHandler.getErrorMessage(error));
    }
}
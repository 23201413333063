<div class="modal-header">
  <h5 class="modal-title">{{title}}</h5>
  <button *ngIf="!hideCloseBtn" type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <p [innerHTML]="text"></p>
</div>
<div class="modal-footer" [class.justify-content-center]="hideCancel">
  <button *ngIf="!hideCancel" type="button" class="btn btn-secondary" (click)="onResponse(false)">{{ cancelText }}
  </button>
  <button type="button" class="btn btn-primary" (click)="onResponse(true)">{{ confirmText }}</button>
</div>

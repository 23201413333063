import {StorageBackedStore} from "./storage-backed-store";
import {QuotePageState} from "../model/quote-page-state";
import {Injectable} from "@angular/core";
import {ICoverage} from "../model/coverage";
import produce from "immer";
import * as _ from 'lodash';

@Injectable({providedIn: 'root'})
export class QuotePageStore extends StorageBackedStore<QuotePageState> {
    constructor() {
        super('quote-page', {
            activePackage: '',
            defaultPackage: '',
            pendingChanges: []
        });
    }

}
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, ParamMap, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';
import { Logger } from 'src/app/root/logger/logger';
import { environment } from 'src/environments/environment';
import { IRuleQuery, IVariance, RuleEvaluationService } from '../services/rule-evaluation-service';
import { ZipService } from '../services/zip.service';

/**
 * Intercepts requests to /entry/externalGetQuote route and redirects to the appropriate landing
 * page based on the insurance type
 */
// tslint:disable: no-backbone-get-set-outside-model
@Injectable({ providedIn : 'root'})
export class ExternallyInitiatedQuoteGuard implements CanActivate {

    private static readonly SUPPORTED_HOME_QUOTE_STATES = ['MA', 'NH', 'CT', 'NJ', 'PA', 'NY'];
    private static readonly DEFAULT_HOME_LANDING_PAGE = 'https://www.plymouthrock.com/insurance/home';

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        const quoteType = route.queryParamMap.get('type');

        if (quoteType === 'home') {
            this.redirectToHomeQuoteLandingPage(route);
        } else {
            this.redirectToAutoQuoteLandingPage(route);
        }
        return false;
    }

    private redirectToAutoQuoteLandingPage(route: ActivatedRouteSnapshot): void {
        this.router.navigate([`${environment.contextRoot}/entry/start`], { queryParams : route.queryParams});
    }

    private redirectToHomeQuoteLandingPage(route: ActivatedRouteSnapshot): void {
        this.getState(route.queryParamMap.get('zipcode') || '').pipe(
            take(1),
            switchMap((state) => this.getHomeQuoteLandingPageUrl(state, route.queryParamMap)),
            tap((landingPageUrl) => window.location.replace(landingPageUrl || ExternallyInitiatedQuoteGuard.DEFAULT_HOME_LANDING_PAGE))
        ).subscribe();
    }

    private getHomeQuoteLandingPageUrl(state: string, queryParamMap: ParamMap): Observable<string> {
        const zip = queryParamMap.get('zipcode');
        const quoteType = queryParamMap.get('type');
        const affinityGroup = queryParamMap.get('Grpid');

        const varianceState = (state && ExternallyInitiatedQuoteGuard.SUPPORTED_HOME_QUOTE_STATES.indexOf(state) !== -1 ? state : '*');

        const variance: IVariance = {
            company: '*',
            channel: '*',
            lob: '*',
            state: varianceState
        };

        const ruleQuery: IRuleQuery = {
            screen: 'url',
            field : (affinityGroup === 'MA1' ? 'ma1_home_insurance_redirect' : 'home_insurance_redirect')
        };

        const urlState = (state && ExternallyInitiatedQuoteGuard.SUPPORTED_HOME_QUOTE_STATES.indexOf(state) !== -1 ? state : '');

        return this.ruleEvalService.evalForStringByVarianceAndQuery(variance, ruleQuery).pipe(
            take(1),
            map((url) => url ? `${url}?zip=${zip}&policyType=${quoteType}&Zip=${zip}&State=${urlState}` : '')
        );
    }

    private getState(zip: string): Observable<string> {
        return !zip ? of('') : this.zipService.lookUp({zip}).pipe(
            take(1),
            map((result) => result ? result.state : ''),
            catchError((error) => {
                this.logger.warn('[ExternallyInitiatedQuoteGuard] An error occurred while looking up state for zip', zip, error);
                return of('');
            })
        );
    }

    constructor(
        private router: Router,
        private ruleEvalService: RuleEvaluationService,
        private zipService: ZipService,
        private logger: Logger
    ) {}

}
// tslint:enable: no-backbone-get-set-outside-model
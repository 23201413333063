import { Injectable } from '@angular/core';
import { Facade } from './facade';
import { RefererStore, IRefererState } from './referer-store';

@Injectable({ providedIn: 'root' })
export class RefererFacade extends Facade<IRefererState> {

    constructor(protected store: RefererStore) {
        super(store);
    }
 

}
import * as _ from 'lodash';
import * as moment from 'moment';

export enum RELATIONSHIP_TO_INSURED {
    PRIMARY_INSURED = 'I',
    SPOUSE_OR_DOMESTIC_PARTNER = 'S',
    DOMESTIC_PARTNER = 'D',
    CHILD = 'C',
    OTHER_RELATIVE = 'R',
    NON_RELATIVE = 'O'
}

export enum MARITAL_STATUS {
    SINGLE = 'S',
    MARRIED = 'M',
    DIVORCED = 'D',
    WIDOWED = 'W',
}

export enum LICENSE_STATUS {
    VALID = 'A',
    SUSPENDED = 'S',
    REVOKED = 'R',
    NEVER_LICENSED = 'N',
    NO_LONGER_LICENSED = 'NL',
    PERMIT = 'P'
}

export enum MOTORCYCLE_LICENSE_TYPE {
    LICENSE = 'LIC',
    PERMIT = 'P'
}

export interface IDriverDiscounts {
    driverId: number;
    code: string;
}

export enum DISCOUNT {
    D_MC_SAFETY_COURSE = 'DMS',
    D_MC_SAFETY_INSTRUCTOR_COURSE = 'DMSI'
}

export class Driver {
    id?: number;
    firstName?: string;
    middleName?: string;
    lastName?: string;
    suffix?: string;
    email?: string;
    currentDriver?: boolean;
    relationshipToInsured?: RELATIONSHIP_TO_INSURED;
    birthDate?: string; // MM/dd/yyyy format
    gender?: string;
    otherGenderDesc?: string;
    maritalStatus?: MARITAL_STATUS;
    driverSeqNum?: number;
    hasOwnInsurancePolicy?: string; // Y/N : 'driverStatus' in old code
    livesWithInsured?: string; // Y/N : 'liveWithYou' in old code
    currentInsuranceCompany?: string;
    awayInMilitaryService?: string; // Y/N
    ageFirstLicensed?: number;
    licensedDate?: string; // MM/dd/yyyy format
    maFirstLicenseDate?: string; // MM/dd/yyyy format
    licenseStatus?: LICENSE_STATUS;
    driverStatusCode?: string; // ** 'INS_POL', 'R' - seems to be populated on server side, must be updated in UI after service call to save date
    discounts?: string[]; // Selected driver discounts in Discounts page
    licenseCountry?: string; // Final Drivers Page - License Country
    licenseState?: string; // Final Drivers Page - License State
    licenseNumber?: string; // Final Drivers Page - License Number
    driverSafetyCourseCompletionDate?: string; // Final Drivers Page - MM/dd/yyyy format
    driverSafetyInstructorCourseCompletionDate?: string; //Final Drivers Page - MM/dd/yyyy format
    manualAccViolationInd?: string; // Y/N : Mapped to 'Have you had any accidents, violations, or claims in the past 5 years?'

    // Motorcycle-specific fields
    motorcycleLicenseType: MOTORCYCLE_LICENSE_TYPE | string; // Mapped to 'Does this driver have a valid motorcycle license/permit?'
    hasValidMopedLicenseOrPermit: string; // Y/N : Does this driver have a valid moped license or permit?
    ageAtFirstOnRoadRide: number; // Mapped to 'At what age did you first ride your motorcycle on-road?'
    // End of Motorcycle-specific fields

    customerProId?: string; // PolicyPro Integration

    age(asOf?: string, asOfFormat = 'M/D/YYYY'): number {
        const asOfMoment = asOf ? moment(asOf, asOfFormat) : moment();
        return this.birthDate ? asOfMoment.diff(moment(this.birthDate, 'M/D/YYYY'), 'years') : 0;
    }

    getFullName(): string {
        return _.join(
            // tslint:disable-next-line: underscore-consistent-invocation
            _.compact([
                _.trim(this.firstName),
                _.trim(this.middleName),
                _.trim(this.lastName),
                _.trim(this.suffix)
            ]), ' ');
    }

    isPrimaryInsured(): boolean {
        return this.relationshipToInsured === RELATIONSHIP_TO_INSURED.PRIMARY_INSURED;
    }

    isSpouseOrDomesticPartnerOfPrimaryInsured(): boolean {
        return this.relationshipToInsured === RELATIONSHIP_TO_INSURED.SPOUSE_OR_DOMESTIC_PARTNER;
    }

    hasLicense(): boolean {
        return this.isDriversLicenseStatusAnyOf([LICENSE_STATUS.VALID, LICENSE_STATUS.SUSPENDED])
            || this.hasMotorcycleLicenseOrPermit();
    }

    hasValidLicense(): boolean {
        return this.isDriversLicenseStatusAnyOf([LICENSE_STATUS.VALID]) || this.hasMotorcycleLicenseOrPermit();
    }

    isEligibleAsVehiclePrimaryDriver() {
        return true;
    }

    isDriversLicenseStatusAnyOf(licenseStatuses: LICENSE_STATUS[]): boolean {
        return this.licenseStatus && licenseStatuses.indexOf(this.licenseStatus) !== -1;
    }

    hasMotorcycleLicenseOrPermit(): boolean {
        return this.motorcycleLicenseType
            && [MOTORCYCLE_LICENSE_TYPE.LICENSE, MOTORCYCLE_LICENSE_TYPE.PERMIT].indexOf(this.motorcycleLicenseType as MOTORCYCLE_LICENSE_TYPE) !== -1;
    }

}
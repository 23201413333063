import { Component, Input } from '@angular/core';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import * as _ from 'lodash';
import { IAgent } from '../../model/agent';
import { PLFCode } from '../../model/plf-code';
import { IPolicy } from '../../model/policy';

@Component({
  selector: 'app-declined-policy-modal',
  templateUrl: './declined-policy-modal.component.html',
  styleUrls: ['./declined-policy-modal.component.scss']
})
export class DeclinedPolicyModalComponent {

  private static readonly DECLINE_TITLE_DEFAULT = 'We are sorry but you do not qualify for insurance coverage with us for the following reason(s):';
  private static readonly DECLINE_TITLE_NH = `Please call (844) 419-6407. We're sorry but we are unable to offer you insurance coverage online for the following reason(s):`;

  private _agent: IAgent;
  private _policy: IPolicy;
  private _declinePhone: string;

  @Input() set agent(agent: IAgent) {
    this._agent = agent;
  }

  get agent(): IAgent {
    return this._agent || {};
  }

  @Input() set policy(policy: IPolicy) {
    this._policy = policy || {};
  }

  get policy(): IPolicy {
    return this._policy || {};
  }

  @Input() set declinePhone(declinePhone: string) {
    this._declinePhone = declinePhone;
  }

  get declinePhone(): string {
    return this._declinePhone || '';
  }

  get title(): string {
    return this.policy && this.policy.state === 'NH' ? DeclinedPolicyModalComponent.DECLINE_TITLE_NH : DeclinedPolicyModalComponent.DECLINE_TITLE_DEFAULT;
  }

  get text(): string {
    const content = (this.policy.declineMessages || [])
      .filter((message) => !!message)
      .map((message) => '<p>' + message + '</p>')
      .join('<br/>');

    if (this.policy.state === 'NH') {
      return content;
    }

    const servicePhoneNumber = _.trim(this.getServicePhoneNumber());

    return content
      + 'Please call '
      + (servicePhoneNumber ? parsePhoneNumberFromString(servicePhoneNumber, 'US').formatNational() : '')
      + ' for further assistance.';
  }

  private getServicePhoneNumber(): string {
    return (this.agent.externalAgent || this.policy.plfCode !== PLFCode.PRAC) ?
      this.agent.phone : this.declinePhone;
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { PolicyFacade } from '../stores/policy-facade';

@Injectable({ providedIn: 'root'})
export class RuleEvaluationService {
    private apiUrl = environment.apiUrl;

    constructor(private http: HttpClient, private policyFacade: PolicyFacade) {}

    evalForStringByVarianceAndQuery(variance: IVariance, rule: IRuleQuery): Observable<string> {
        return this.http.post<string>(this.apiUrl + '/rules/business-rules', { variance, rule });
    }
}

export interface IRuleQueryResult {
    key: string;
    response: string;
    message: string;
}

export interface IVariance {
    state: string;
    company: string;
    lob: string;
    channel: string;
}

export interface IRuleQuery {
    screen: string;
    field?: string;
}

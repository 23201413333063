import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IAggregatorInitializedApplicationState } from '../model/application-state';

@Injectable({ providedIn: 'root' })
export class AggregatorService {
    private apiUrl = environment.apiUrl;

    constructor(private http: HttpClient) {}

    retrieveRatedQuote(request: IRetrieveRatedQuoteRequest): Observable<IRetrieveRatedQuoteResponse> {
        return this.http.post<IRetrieveRatedQuoteResponse>(`${this.apiUrl}/aggregator/${request.sourceOfEntry}/quotes`, request);
    }

    retrieveUnratedQuote(aggregatorName: string, id: string): Observable<IAggregatorInitializedApplicationState> {
        return this.http.get<IAggregatorInitializedApplicationState>(`${this.apiUrl}/ui/quote/aggregator/unrated/${aggregatorName}/${id}`);
    }
}

export interface IRetrieveRatedQuoteRequest {
    policyNumber: string;
    email: string;
    lastName: string;
    dob: string; // MM/DD/YYYY format
    zip: string;
    sourceOfEntry: string;
}

export interface IRetrieveRatedQuoteResponse {
    policyKey: number;
    policyNumber: string;
    dateCreated: string;
    lastSavedDate: string;
    source: string;
    channel: string;
    state: string;
    compnay: string;
    lob: string;
    originalSourceOfData: string;
}
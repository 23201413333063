import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, take, withLatestFrom, switchMap, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Channel } from '../model/channel';
import { PolicyFacade } from '../stores/policy-facade';
import { IPolicy } from '../model/policy';
import { UpdateType } from '../model/update-type';
import { PaymentPlan, overwritePaymentPlan } from '../model/payment';
import { UIFacade } from '../stores/ui-facade';

@Injectable({ providedIn: 'root' })
export class PolicyProService {
    private apiUrl = environment.apiUrl;
    constructor(private http: HttpClient, private policyFacade: PolicyFacade, private uiFacade: UIFacade) { }

    newPaymentPlansEnable(): Observable<boolean> {
        return this.policyFacade.state.pipe(take(1), switchMap((policy) => {
            return of(false); // this.http.get<boolean>(`${this.apiUrl}/${policy.state}/${policy.channel}/company/uwCompany/policypro`);
        }), tap(isNew => {
            if (isNew) {
                overwritePaymentPlan()
            }
            this.uiFacade.patch({ newPaymentPlanEnabled: isNew })
        }))
    }

}
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {Event, NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router} from '@angular/router';
import {Subject} from 'rxjs';
import {take, takeUntil, tap, map} from 'rxjs/operators';
import {TealiumService} from 'src/app/shared/services/tealium.service';
import {UIFacade} from 'src/app/shared/stores/ui-facade';
import {environment} from '../environments/environment';
import {ConfigService} from './shared/services/config.service';
import {LoadingMaskService} from './shared/services/loading-mask.service';
import {SessionListener} from './shared/services/session-listener';
import {UIStateInitializationService} from './shared/services/ui-state-initialization-service';
import {ApplicationFacade} from './shared/stores/application-facade';
import {PolicyFacade} from './shared/stores/policy-facade';
import {Referrer} from './shared/model/referrer';
import {RefererFacade} from './shared/stores/referer-facade';
import {PolicyProService} from './shared/services/policy-pro.service';
import { PaymentPlan, overwritePaymentPlan } from './shared/model/payment';

//declare var dT_;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  private cmpDestroyed$ = new Subject<boolean>();

  loading: boolean;
  policyIssuanceLoading: boolean;
  title = 'eSales Rider-Plymouth Rock';
  showFooter = false;

  policy$ = this.policyFacade.state;
  uiState$ = this.uiFacade.state;
  displayAppState = environment.displayAppState;

  constructor(
    private router: Router,
    private loadingMaskService: LoadingMaskService,
    private applicationFacade: ApplicationFacade,
    private policyFacade: PolicyFacade,
    private configService: ConfigService,
    private uiStateInitializationService: UIStateInitializationService,
    private uiFacade: UIFacade,
    private tealium: TealiumService,
    private sessionListener: SessionListener,
    private http: HttpClient,
    private refererFacade:RefererFacade,
    private policyProService: PolicyProService
  ) {
    this.tealium.setConfig({ account: environment.tealiumAccount, profile: environment.tealiumProfile, environment: environment.tealiumEnvironment });

    router.events.pipe(takeUntil(this.cmpDestroyed$)).subscribe((routerEvent: Event) => {
      if (routerEvent instanceof NavigationStart) {
        this.loadingMaskService.setLoading(true);
        this.showFooter = false;
      } else if (
        routerEvent instanceof NavigationCancel ||
        routerEvent instanceof NavigationError || routerEvent instanceof NavigationEnd
      ) {
        this.loadingMaskService.setLoading(false);
        this.showFooter = true;
      }
    });

    /*if (dT_ && dT_.initAngularNg) {
      dT_.initAngularNg(http, HttpHeaders);
    }*/
  }

  ngOnInit(): void {

  this.refererFacade.state.pipe(take(1)).subscribe((refState)=>{
    if(refState.referrerURL) {
   // its a page refresh do not do anything
    } else {
      if(Referrer.refValue()) {
        this.configService.validateReferer({referrer:Referrer.refValue()}).pipe(take(1)).subscribe((res) =>{
          this.refererFacade.patch({referrerURL:Referrer.refValue(), validRefURL: res.valid});
        })
      } else {
        this.refererFacade.patch({referrerURL:location.href,validRefURL:false});
      }
    }
  })

 this.loadingMaskService.loading$.pipe(
      takeUntil(this.cmpDestroyed$)
    ).subscribe((val) => setTimeout(() => this.loading = val));
    this.loadingMaskService.policyIssuanceLoading$.pipe(
      takeUntil(this.cmpDestroyed$)
    ).subscribe((val) => setTimeout(() => this.policyIssuanceLoading = val));

    this.sessionListener.initialize();

    this.configService.fetchAppConfig().pipe(
      take(1),
      tap(() => this.uiStateInitializationService.initialize().pipe(takeUntil(this.cmpDestroyed$)).subscribe())
    ).subscribe();

    this.uiFacade.state.pipe(take(1), map((state) => state.newPaymentPlanEnabled)).subscribe((enabled) => {
      if (enabled) {
        overwritePaymentPlan()
      }
    })
  }

  ngOnDestroy(): void {
    this.applicationFacade.reset();
    this.cmpDestroyed$.next(true);
    this.cmpDestroyed$.complete();
  }


}

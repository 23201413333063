import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {switchMap, take} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {AuthorizationFacade} from '../stores/authorization-facade';

@Injectable({ providedIn : 'root' })
export class AuthorizationInterceptor implements HttpInterceptor {

    private static readonly DEFAULT_AUTH_TOKEN = 'esales-rider-default-auth-token';

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return this.authFacade.state.pipe(
            take(1),
            switchMap((authContext) => {
                if (!authContext || !authContext.token) {
                    return next.handle(req.clone({
                            setHeaders: {
                                Authorization: `Bearer ${AuthorizationInterceptor.DEFAULT_AUTH_TOKEN}`,
                                pracjwt: AuthorizationInterceptor.DEFAULT_AUTH_TOKEN
                            },
                            withCredentials: environment.xhrWithCredentials
                        })
                    );
                }

                return next.handle(req.clone({
                    setHeaders: {
                        Authorization: `Bearer ${authContext.token}`,
                        pracjwt: authContext.token
                    },
                    withCredentials: environment.xhrWithCredentials
                }));
            })
        );
    }

    constructor(private authFacade: AuthorizationFacade) {}
}
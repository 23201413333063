import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { Logger } from 'src/app/root/logger/logger';
import { IVehicle } from 'src/app/shared/model/vehicle';
import { PolicyFacade } from 'src/app/shared/stores/policy-facade';
import { environment } from 'src/environments/environment';
import { IGetPrefillDataStrategy, IPrefillData } from './get-prefill-data-strategy-factory';

@Injectable({ providedIn: 'root'})
export class GetPrefillDataFromDB implements IGetPrefillDataStrategy {
    private apiUrl = environment.apiUrl;

    constructor(private http: HttpClient, private policyFacade: PolicyFacade, private logger: Logger) { }

    getPrefillData(): Observable<IPrefillData> {
        const result: IPrefillData = {
            policyThirdPartyId: 0,
            prefillVehicles: []
        };

        return this.policyFacade.state.pipe(
            take(1),
            tap((policy) => this.logger.debug('Retrieving prefill data from DB for policy key', policy.policyKey)),
            tap((policy) => result.policyThirdPartyId = policy.policyThirdPartyId),
            switchMap((policy) => this.getPrefillVehicles(policy.policyKey)),
            map((response) => this.mapToVehicles(response)),
            tap((vehicles) => result.prefillVehicles = (vehicles || [])),
            map(() => result)
        );
    }

    private getPrefillVehicles(policyKey: number): Observable<IGetPrefillVehiclesByPolicyKeyResponse[]> {
        return this.http.get<IGetPrefillVehiclesByPolicyKeyResponse[]>(this.apiUrl + `/policy/${policyKey}/prefill/vehicles`);
    }

    private mapToVehicles(response: IGetPrefillVehiclesByPolicyKeyResponse[]): IVehicle[] {
        if (!response || !response.length) {
            return[];
        }

        return response.map(
            (v) => ({
                vehicleThirdPartyId: v. vehicleThirdPartyId,
                vinNumber: v.vin,
                year: v.modelYear,
                make: v.make,
                makeDescription: v.makeDescription,
                model: v.model,
                body: v.bodyTypeCode,
                vehicleTypeCode: v.vehicleTypeCode,
                weight: v.weight
            })
        );
    }

}

export interface IGetPrefillVehiclesByPolicyKeyResponse {
    vehicleThirdPartyId: number;
    vin: string;
    modelYear: number;
    make: string;
    makeDescription: string;
    model: string;
    bodyTypeCode: string;
    vehicleTypeCode: string;
    weight: number;
}
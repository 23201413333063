import { Injectable } from '@angular/core';
import produce from 'immer';
import * as _ from 'lodash';
import { IIncident } from '../model/incident';
import { StorageBackedStore } from './storage-backed-store';

@Injectable({ providedIn: 'root' })
export class IncidentsStore extends StorageBackedStore<IIncident[]> {
    constructor() {
        super('incidents', []);
    }

    addIncident(incident: IIncident) {
        const nextState = produce(this.state, (draft: IIncident[]) => {
            draft.push(incident);
        });

        this.setState(nextState);
    }

    deleteByDriverId(driverId: number) {
        const nextState = produce(this.state, (draft: IIncident[]) => {
            _.remove(draft, { driverId });
        });

        this.setState(nextState);
    }
}
/**
 * Value object representing transaction progress
 */
import * as _ from 'lodash';
import { environment } from 'src/environments/environment';
import {IPolicy, isEffectiveDateStale} from './policy';

export class TransactionProgress {
    static readonly ENTRY = 0;
    static readonly CUSTOMER_INFO = 10;
    static readonly DRIVER_1 = 21;
    static readonly DRIVER_2 = 22;
    static readonly DRIVER_3 = 23;
    static readonly DRIVER_4 = 24;
    static readonly VEHICLES = 30;
    static readonly VEHICLE_1 = 31;
    static readonly VEHICLE_2 = 32;
    static readonly VEHICLE_3 = 33;
    static readonly VEHICLE_4 = 34;
    static readonly DETAILS = 40;
    static readonly DISCOUNTS = 50;
    static readonly PERKS = 51;
    static readonly RATE = 60;
    static readonly FINAL_DRIVERS = 70;
    static readonly FINAL_VEHICLES = 80;
    static readonly FINAL_POLICY = 90;
    static readonly CHECKOUT = 100;
    static readonly DOCUMENTS = 110;
    static readonly THANKYOU = 120;

    constructor(private value: number) { }

    // tslint:disable-next-line: function-name
    static forPath(path: string): TransactionProgress {
        // tslint:disable-next-line: max-func-body-length
        if (_.includes(path, 'entry') || _.includes(path, 'custinfo')) {
            return new TransactionProgress(this.CUSTOMER_INFO);
        }

        if (_.includes(path, 'drivers')) {
            return new TransactionProgress(this.DRIVER_1);
        }

        if (_.includes(path, 'vehicles')) {
            return new TransactionProgress(this.VEHICLES);
        }

        if (_.includes(path, 'discounts/perks')) {
            return new TransactionProgress(this.PERKS);
        }

        if (_.includes(path, 'discounts')) {
            return new TransactionProgress(this.DISCOUNTS);
        }

        if (_.includes(path, 'coverages')) {
            return new TransactionProgress(this.DETAILS);
        }

        if (_.includes(path, 'quote')) {
            return new TransactionProgress(this.RATE);
        }

        if (_.includes(path, 'finaldriverdetails')) {
            return new TransactionProgress(this.FINAL_DRIVERS);
        }

        if (_.includes(path, 'finalvehicledetails')) {
            return new TransactionProgress(this.FINAL_VEHICLES);
        }

        if (_.includes(path, 'finalpolicydetails')) {
            return new TransactionProgress(this.FINAL_POLICY);
        }

        if (_.includes(path, 'checkout')) {
            return new TransactionProgress(this.CHECKOUT);
        }

        if (_.includes(path, 'esign')) {
            return new TransactionProgress(this.DOCUMENTS);
        }

        if (_.includes(path, 'thank-you')) {
            return new TransactionProgress(this.THANKYOU);
        }

        throw Error('No transaction progress mapped for path ' + path);
    }

    // tslint:disable-next-line: function-name
    static redirectURLFor(policy: IPolicy): string {
        if (policy.selectedPackage === '1' && policy.transactionProgress >= this.RATE) {
            policy.transactionProgress = this.PERKS;
        }
        const transactionProgress = policy ? +policy.transactionProgress : TransactionProgress.ENTRY; // Coerce to a number, just in case it is a string

        if (isEffectiveDateStale(policy)) {
            return `${environment.contextRoot}/custinfo/pedate`;
        }

        if (this.DRIVER_1 <= transactionProgress && transactionProgress <= this.DRIVER_4) {
            return `${environment.contextRoot}/drivers`;
        }

        if (this.VEHICLES <= transactionProgress && transactionProgress <= this.VEHICLE_4) {
            return `${environment.contextRoot}/vehicles`;
        }

        switch (transactionProgress) {
            case this.DETAILS: return `${environment.contextRoot}/coverages`;
            case this.DISCOUNTS: return `${environment.contextRoot}/discounts`;
            case this.PERKS: return `${environment.contextRoot}/discounts/perks`;
            case this.RATE: return `${environment.contextRoot}/quote/quotePreview`;
            case this.FINAL_DRIVERS: return `${environment.contextRoot}/purchase/finaldriverdetails/0`;
            case this.FINAL_VEHICLES: return `${environment.contextRoot}/purchase/finalvehicledetails/0`;
            case this.FINAL_POLICY: return `${environment.contextRoot}/purchase/finalpolicydetails`;
            case this.CHECKOUT: return `${environment.contextRoot}/purchase/checkout`;
            case this.THANKYOU: return `${environment.contextRoot}/purchase/thank-you`;

            default: return `${environment.contextRoot}/custinfo`;
        }

    }

    getValue(): number {
        return this.value;
    }

    /**
     * Returns the next transaction progress value
     */
    next(): number {
        switch (this.value) {
            case TransactionProgress.CUSTOMER_INFO: return TransactionProgress.DRIVER_1;

            case TransactionProgress.DRIVER_1:
            case TransactionProgress.DRIVER_2:
            case TransactionProgress.DRIVER_3:
            case TransactionProgress.DRIVER_4: return TransactionProgress.VEHICLES;

            case TransactionProgress.VEHICLES:
            case TransactionProgress.VEHICLE_1:
            case TransactionProgress.VEHICLE_2:
            case TransactionProgress.VEHICLE_3:
            case TransactionProgress.VEHICLE_4: return TransactionProgress.DETAILS;

            case TransactionProgress.DETAILS: return TransactionProgress.DISCOUNTS;
            case TransactionProgress.DISCOUNTS: return TransactionProgress.PERKS;
            case TransactionProgress.PERKS: return TransactionProgress.RATE;
            case TransactionProgress.RATE: return TransactionProgress.FINAL_DRIVERS;
            case TransactionProgress.FINAL_DRIVERS: return TransactionProgress.FINAL_VEHICLES;
            case TransactionProgress.FINAL_VEHICLES: return TransactionProgress.FINAL_POLICY;
            case TransactionProgress.FINAL_POLICY: return TransactionProgress.CHECKOUT;
            case TransactionProgress.CHECKOUT: return TransactionProgress.DOCUMENTS;
            case TransactionProgress.DOCUMENTS:
            case TransactionProgress.THANKYOU: return TransactionProgress.THANKYOU;
        }

    }

}
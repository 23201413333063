import { Injectable } from '@angular/core';
import { IPremium } from '../model/premium';
import { Facade } from './facade';
import { PremiumsStore } from './premiums-store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class PremiumsFacade extends Facade<IPremium[]> {

    constructor(protected store: PremiumsStore) {
        super(store);
    }

    findPremiumByPackageTypeCodeAndVehicleId(packageTypeCode: string, vehicleId: number): Observable<IPremium> {
        return  this.store.state$.pipe(
          map((premiumData) => premiumData.find(premium=> premium.vehicleId === vehicleId && premium.packageTypeCode === packageTypeCode))
        );
      }
}
import {parsePhoneNumberFromString} from 'libphonenumber-js';
import * as _ from 'lodash';
import { IAgentContract } from './agent-contract';

export interface IAgent {
    id?: string;
    agencyId?: string;
    agencyHierId?: string;
    producerHierId?: string;
    branchHierId?: string;
    producerCode?: string;
    branchId?: string;
    email?: string;
    phone?: string;
    fiservAuthID?: string;
    name?: string;
    address1?: string;
    city?: string;
    state?: string;
    zip?: string;
    servicePhone?: string;
    serviceEmail?: string;
    campaignPhone?: string;
    writeTeachInd?: boolean;
    writeAarpInd?: boolean;
    externalAgent?: boolean;
    contracts?: IAgentContract[];
}

export function formattedAgentPhone(agent: IAgent): string {
    return agent && _.trim(agent.phone) ? parsePhoneNumberFromString(agent.phone, 'US').formatNational() : '';
}
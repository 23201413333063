import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { forkJoin, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { IAgent } from 'src/app/shared/model/agent';
import { IApplicationState } from 'src/app/shared/model/application-state';
import { IPayment } from '../model/payment';
import { AddressesFacade } from './addresses-facade';
import { AgentFacade } from './agent-facade';
import { AuthorizationFacade } from './authorization-facade';
import { CoveragesFacade } from './coverages-facade';
import { DiscountsFacade } from './discounts-facade';
import { DriversFacade } from './drivers-facade';
import { EligibilitiesFacade } from './eligibilities-facade';
import { EsignFacade } from './esign-facade';
import { Facade } from './facade';
import { IncidentsFacade } from './incidents-facade';
import { PaymentFacade } from './payment-facade';
import { PolicyFacade } from './policy-facade';
import { PrefillVehiclesFacade } from './prefillvehicles-facade';
import { PremiumsFacade } from './premiums-facade';
import {QuotePageFacade} from './quote-page-facade';
import { RetrieveFacade } from './retrieve-facade';
import { UIFacade } from './ui-facade';
import { VehiclesFacade } from './vehicles-facade';

@Injectable({ providedIn: 'root' })
export class ApplicationFacade extends Facade<IApplicationState> {

    get state(): Observable<IApplicationState> {
        return forkJoin({
            agent: this.getCuratedAgentState().pipe(take(1)),
            policy: this.policyFacade.state.pipe(take(1)),
            addresses: this.addressesFacade.state.pipe(take(1)),
            drivers: this.driversFacade.state.pipe(take(1)),
            incidents: this.incidentsFacade.state.pipe(take(1)),
            vehicles: this.vehiclesFacade.state.pipe(take(1)),
            coverages: this.coveragesFacade.state.pipe(take(1)),
            premiums: this.premiumsFacade.state.pipe(take(1)),
            eligibilities: this.eligibilitiesFacade.state.pipe(take(1)),
            payment: this.paymentFacade.state.pipe(take(1), map((payment: IPayment) => _.omit(payment, 'decryptedCardNumber'))),
            esignInfo: this.esignFacade.state.pipe(take(1)),
            discounts: this.discountsFacade.state.pipe(take(1))
        }).pipe(
            map((_) => {
                return {
                    agent: _.agent,
                    policy: _.policy,
                    addresses: _.addresses,
                    drivers: _.drivers,
                    incidents: _.incidents,
                    vehicles: _.vehicles,
                    coverages: _.coverages,
                    premiums: _.premiums,
                    eligibilities: _.eligibilities,
                    payment: _.payment,
                    esignInfo: _.esignInfo,
                    discounts: _.discounts
                };
            })
        );
    }

    /**
     * Returns subset of agent state fields needed by the backend service
     */
    private getCuratedAgentState(): Observable<IAgent> {
        return this.agentFacade.state.pipe(
            map((_) => {
                return {
                    agencyId: _.agencyId,
                    agencyHierId: _.agencyHierId,
                    producerHierId: _.producerHierId,
                    branchHierId: _.branchHierId,
                    producerCode: _.producerCode,
                    branchId: _.branchId,
                    email: _.email,
                    phone: _.phone,
                    fiservAuthID: _.fiservAuthID,
                    campaignPhone: _.campaignPhone
                };
            })
        );
    }

    patch(applicationState: IApplicationState, patchAgent = false) {
        this.policyFacade.patch(applicationState.policy);
        this.addressesFacade.patch(applicationState.addresses);
        this.driversFacade.patch(applicationState.drivers);
        this.incidentsFacade.patch(applicationState.incidents);
        this.vehiclesFacade.patch(applicationState.vehicles);
        this.coveragesFacade.patch(applicationState.coverages);
        this.premiumsFacade.patch(applicationState.premiums);
        this.discountsFacade.patch(applicationState.discounts);
        this.eligibilitiesFacade.patch(applicationState.eligibilities);
        this.esignFacade.patch(applicationState.esignInfo);
        this.paymentFacade.patch(applicationState.payment);
        if (patchAgent) {
            this.agentFacade.patch(applicationState.agent);
        }
    }

    reset(): void {
        this.agentFacade.reset();
        this.policyFacade.reset();
        this.addressesFacade.reset();
        this.driversFacade.reset();
        this.incidentsFacade.reset();
        this.vehiclesFacade.reset();
        this.coveragesFacade.reset();
        this.premiumsFacade.reset();
        this.discountsFacade.reset();
        this.eligibilitiesFacade.reset();
        this.paymentFacade.reset();
        this.prefillVehiclesFacade.reset();
        this.uiFacade.reset();
        this.retrieveFacade.reset();
        this.authFacade.reset();
        this.esignFacade.reset();
        this.quotePageFacade.reset();
        sessionStorage.clear();
    }

    constructor(
        private agentFacade: AgentFacade,
        private policyFacade: PolicyFacade,
        private addressesFacade: AddressesFacade,
        private driversFacade: DriversFacade,
        private incidentsFacade: IncidentsFacade,
        private vehiclesFacade: VehiclesFacade,
        private coveragesFacade: CoveragesFacade,
        private premiumsFacade: PremiumsFacade,
        private discountsFacade: DiscountsFacade,
        private eligibilitiesFacade: EligibilitiesFacade,
        private paymentFacade: PaymentFacade,
        private prefillVehiclesFacade: PrefillVehiclesFacade,
        private uiFacade: UIFacade,
        private retrieveFacade: RetrieveFacade,
        private esignFacade: EsignFacade,
        private authFacade: AuthorizationFacade,
        private quotePageFacade: QuotePageFacade
    ) {
        super(null);
    }

}
import { Injectable } from '@angular/core';
import { IEsign } from '../model/esign';
import { Facade } from './facade';
import { ESignStore } from './esign-store';

@Injectable({ providedIn: 'root' })
export class EsignFacade extends Facade<IEsign> {
    constructor(protected store: ESignStore) {
        super(store);
    }
}
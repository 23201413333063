import { Injectable } from '@angular/core';
import produce from 'immer';
import * as _ from 'lodash';
import { IEligibility } from '../model/eligibility';
import { StorageBackedStore } from './storage-backed-store';

@Injectable({ providedIn: 'root' })
export class EligibilitiesStore extends StorageBackedStore<IEligibility[]> {
    constructor() {
        super('eligibilities', []);
    }

    upsertEligibility(eligibility: IEligibility) {
        const nextState = produce(this.state, (draft: IEligibility[]) => {
            const index = draft.findIndex((e: IEligibility) => e.question === eligibility.question);

            if (index < 0) {
                draft.push(eligibility);
            } else {
                draft[index] = eligibility;
            }
        });

        this.setState(nextState);
    }

    deleteByQuestion(question: any) {
        const nextState = produce(this.state, (draft: IEligibility[]) => {
            _.remove(draft, { question });
        });

        this.setState(nextState);
    }

}
import {Facade} from "./facade";
import {QuotePageState} from "../model/quote-page-state";
import {Injectable} from "@angular/core";
import {QuotePageStore} from "./quote-page-store";
import {ICoverage} from "../model/coverage";
import {Observable, of} from "rxjs";
import * as _ from 'lodash';
import {map} from "rxjs/operators";

@Injectable({ providedIn: 'root'})
export class QuotePageFacade extends Facade<QuotePageState> {

    constructor(protected store: QuotePageStore) {
        super(store);
    }

    hasPendingChanges(): Observable<boolean> {
        return this.state.pipe(
            map(state => !_.isEmpty(state.pendingChanges))
        );
    }

    findPendingChangesByVehicleIdAndCoverageCode(vehicleId: number, coverageCodes: string[]): Observable<ICoverage[]> {
        if (_.isEmpty(coverageCodes)) {
            return of([]);
        }

        return this.state.pipe(
            map((state) => state.pendingChanges || []),
            map((pendingChanges) => pendingChanges
                .filter((coverage) => coverageCodes.indexOf(coverage.coverageCode) !== -1)
                .filter((coverage) => vehicleId ? coverage.vehicleId === vehicleId : true)
            )
        );
    }

    findPendingChangesByPackageTypeCode(packageTypeCode: string): Observable<ICoverage[]> {
        return this.state.pipe(
            map((state) => state.pendingChanges || []),
            map((pendingChanges) => pendingChanges.filter((coverage) => coverage.packageTypeCode === packageTypeCode))
        );
    }


    clearPendingChanges(): void {
        this.store.patch({pendingChanges: []});
    }

    getActivePackage(): Observable<string> {
        return this.state.pipe(map((e) => e.activePackage));
    }

    getDefaultPackage(): Observable<string> {
        return this.state.pipe(map((e) => e.defaultPackage));
    }

}
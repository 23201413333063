import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import * as _ from 'lodash';
import {Observable, of} from 'rxjs';
import {map, retryWhen, tap} from 'rxjs/operators';
import {Logger} from 'src/app/root/logger/logger';
import {UIFacade} from 'src/app/shared/stores/ui-facade';
import {environment} from 'src/environments/environment';
import {DateUtils} from '../../utils/date-utils';
import {IAgent} from '../model/agent';
import { IPolicy } from '../model/policy';
import {BackoffStrategy} from './backoff-strategy';

@Injectable({ providedIn: 'root' })
export class ConfigService {

    private apiUrl = environment.apiUrl;
    private appConfig: Map<string, string>;

    constructor(private http: HttpClient, private logger: Logger, private uiFacade: UIFacade, private backoffStrategy: BackoffStrategy) {}

    fetchAppConfig(): Observable<Map<string, string>> {
        const url = this.apiUrl + '/getEffProps';

        return this.http.get<Map<string, string>>(url).pipe(
            retryWhen(this.backoffStrategy.create(url)),
            map((d) => new Map(_.entries(d))),
            tap((dd) => this.appConfig = dd)
        );
    }

    fetchPolicyConfig(request: IGetPolicyConfigRequest): Observable<IGetPolicyConfigResponse> {
        return this.http.post<IGetPolicyConfigResponse>(this.apiUrl + '/getAppConfigs', request).pipe(
            tap((policyConfig) => this.onPolicyConfigRetrieved(policyConfig, request))
        );
    }

    private onPolicyConfigRetrieved(policyConfig: IGetPolicyConfigResponse, request: IGetPolicyConfigRequest): void {
        if (!policyConfig) {
            this.logger.error('No object was returned by getAppConfigs for request', request);
            return;
        }

        const essentialProps = ['channel', 'company', 'lob', 'plf', 'policyTerm', 'uwCompany'];

        const missingProps = [];

        essentialProps.forEach((prop) => {
            if (!policyConfig[prop]) {
                missingProps.push(prop);
            }
        });

        if (missingProps.length > 0) {
            this.logger.error('The following properties returned by getAppConfigs are missing or are expected to have a non-blank value : ' + _.toString(missingProps) + '. Request is ', request);
        }

        this.uiFacade.patch({
            productFromDateMillis: policyConfig.fromDate ? policyConfig.fromDate.millis : 0,
            productToDateMillis: policyConfig.toDate ? policyConfig.toDate.millis : 0,
            productFromDateMessage: policyConfig.fromMessage,
            productToDateMessage: policyConfig.toMessage,
            hoCrossSellEnabled: policyConfig.hoCrossSellEnabled
        });
    }

    validateReferer(request: IRefererRequest): Observable<IRefererResponse> {
        return this.http.post<IRefererResponse>(this.apiUrl + '/validate', request);
    }

    isManagePaymentsEnabled(asOfDate: string, asOfDateFormat = 'M/D/YYYY'): Observable<boolean> {
        return (!asOfDate)
            ? of(false)
            : this.getAppConfig().pipe(
                map((config) => DateUtils.isSameOrAfterDate(config, 'MANAGE_PAYMENT_EFF_DATE', 'M/D/YYYY', asOfDate, asOfDateFormat))
            );
    }

    getMCSegmentationEffDate(policy: IPolicy):Observable<string>{
        return this.http.get<string>(`${environment.getEffPropsByKey}MCSEG_${policy.uwCompany}_${policy.state}`);
    }

    isFeatureEnabled(request: IFeatureEnableRequest):Observable<string>{
        return this.http.post<string>(this.apiUrl+'/isFeatureEnabled/', request);
    }
    
    private getAppConfig(): Observable<Map<string, string>> {
        return (this.appConfig) ? of(this.appConfig) : this.fetchAppConfig();
    }
}

export interface IGetPolicyConfigRequest {
    plf: string;
    zip: string;
    lob: string;
    channel: string;
    aff: string;
    iRefID: string;
    iRefClickID: string;
    agentId: string;
    sob: string;
    cmpid: string;
    publisherId: string;
    adhid: string;
    lbuid: string;
    lbrid: string;
    lbuky: string;
    sepId: string;
    cbrId: string;
    referralSourceCD: string;
    skipQuoteNumberGeneration: boolean;
}

export interface IGetPolicyConfigResponse {
    plf: string;
    channel: string;
    company: string;
    uwCompany: string;
    affCode: string;
    agent: IAgent;
    isExternalAgent: boolean;
    brand: string;
    lob: string;
    sob: string;
    servicePhone: string;
    campaignPhone: string;
    phone: string;
    affGroupName: string;
    policyTerm: string;
    isDirectBusiness: boolean;
    fromDate: { millis: number };
    toDate: { millis: number };
    fromMessage: string;
    toMessage: string;
    defaultBrand: boolean;
    declinePhone: string;
    jwt?: string;
    policynumber?: string;
    hoCrossSellEnabled?: boolean;
}

export interface IRefererRequest {
    referrer: string;
}

export interface IRefererResponse {
    valid: boolean;
}

export interface IFeatureEnableRequest{
   effectiveDate: string;
   state: string;
   uwCompany: string;
   feature: string;
}


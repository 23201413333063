/* tslint:disable:array-type */
export enum CoverageCode {
    BI = 'BI',
    PD = 'PD',
    UM = 'UM',
    UIM = 'UIM',
    UMD = 'UMD',
    UIMD = 'UIMD',
    UMUIM = 'UMUIM',
    UMPD = 'UMPD',
    COMPD = 'COMPD',
    COLLD = 'COLLD',
    RIDER_PLUS = 'RIDER_PLUS',
    RIDER_ACCS = 'RIDER_ACCS'
}

export const NO_COVERAGE = 'No Coverage';
export const STACKED = 'Stacked';
export const UNSTACKED = 'Unstacked';
export const STACKING_OPTIONS = [STACKED, UNSTACKED];

export const POPULAR_PACKAGE_WITH_LIMITATIONS = 'PACKAGE1';
export const POPULAR_PACKAGE_NO_LIMITATIONS = 'PACKAGE3';
export const CUSTOM_PACKAGE = 'PACKAGE4';

export interface ICoverage {
    coverageId?: number;
    vehicleId?: number;
    coverageName?: string;
    coverageCode?: string;
    coverageDesc?: string;
    text?:string;
    limit?: string;
    premium?: string;
    selectedCoverageIndicator?: string;
    deductibleVal?: string;
    options?: string;
    message?: string;
    waiverInd?: string;
    glassDeductOption?: string;
    packageTypeCode?: string;
}

export interface ILimitedCoverageSpecs {
    coverageCode: CoverageCode;
    min: string;
    max: string;
}

export function limitedLiabilityCoverageLimits(state: string): ILimitedCoverageSpecs[] {
    switch (state) {
        case 'NJ':
        case 'PA':
            return [
                {coverageCode: CoverageCode.BI, min: NO_COVERAGE, max: '15,000/30,000'},
                {coverageCode: CoverageCode.PD, min: NO_COVERAGE, max: '5,000'}
            ];
        default:
            return [] as any;
    }
}

export function limitedPhysicalDamageCoverageLimits(state: string): ILimitedCoverageSpecs[] {
    switch (state) {
        case 'NJ':
        case 'PA':
            return [
                {coverageCode: CoverageCode.COMPD, min: NO_COVERAGE, max: NO_COVERAGE},
                {coverageCode: CoverageCode.COLLD, min: NO_COVERAGE, max: NO_COVERAGE}
            ];
        default:
            return [] as any;
    }
}
/* tslint:disable:member-ordering */
import {Injectable} from '@angular/core';
import produce from 'immer';
import * as _ from 'lodash';
import {Driver, IDriverDiscounts, MARITAL_STATUS, RELATIONSHIP_TO_INSURED} from '../model/driver';
import {StorageBackedStore} from './storage-backed-store';

@Injectable({providedIn: 'root'})
export class DriversStore extends StorageBackedStore<Driver[]> {

    constructor() {
        super('drivers', []);
    }

    protected deserializeState(): Driver[] {
        return (super.deserializeState() || []).map((driver) => Object.assign(new Driver(), driver));
    }

    insertDriver(driver: Driver) {
        const nextState = produce(this.state, (draft: Driver[]) => {
            draft.push(driver);
            DriversStore.sequentializeSequenceNumbers(draft);
        });

        this.setState(nextState);
    }

    updateDriverBySeqNum(driverSeqNum: number, driver: Driver) {
        const nextState = produce(this.state, (draft: Driver[]) => {
            const index = draft.findIndex((d) => d.driverSeqNum === driverSeqNum);
            if (index > -1) {
                draft[index] = driver;
            }
        });

        this.setState(nextState);
    }

    deleteDriver(driver: Driver) {
        const nextState = produce(this.state, (draft: Driver[]) => {
            _.remove(draft, {driverSeqNum: driver.driverSeqNum});
            DriversStore.sequentializeSequenceNumbers(draft);
        });
        this.setState(nextState);
    }

    insertDriverDiscounts(driverDiscount: IDriverDiscounts) {
        const nextState = produce(this.state, (draft: Driver[]) => {
            const index = draft.findIndex((d) => d.id === driverDiscount.driverId);
            if (index > -1) {
                draft[index].discounts.push(driverDiscount.code);
            }
        });

        this.setState(nextState);
    }

    clearDriverDiscounts() {
        const nextState = produce(this.state, (draft: Driver[]) => {

            draft.forEach((e, i) => {
                draft[i].discounts = [];
            });
        });

        this.setState(nextState);
    }

    updateDriver(driver: Driver) {
        const nextState = produce(this.state, (draft: Driver[]) => {
            const index = draft.findIndex((d) => d.id === driver.id);
            if (index > -1) {
                draft[index] = driver;
            }
        });

        this.setState(nextState);
    }

    updatePrimaryDriverMaritalStatus(s: MARITAL_STATUS) {
        const nextState = produce(this.state, (draft: Driver[]) => {
            const primaryDriver = draft[0];
            primaryDriver.maritalStatus = s;
            draft[0] = primaryDriver;
        });

        this.setState(nextState);
    }

    /**
     * Sets each drivers's driverSeqNumber with the starting element set at 1, the next
     * element at 2, and so on
     */
    private static sequentializeSequenceNumbers(drivers: Driver[]) {
        if (!drivers || !drivers.length) {
            return;
        }

        drivers.forEach((driver, index) => driver.driverSeqNum = ++index);
    }

    updatePrimaryDriverEmail(email: string) { // need this for AI floe Audit call
        const nextState = produce(this.state, (draft: Driver[]) => {
            const primaryDriver = draft[0];
            primaryDriver.email = email;
            primaryDriver.relationshipToInsured = RELATIONSHIP_TO_INSURED.PRIMARY_INSURED;
            draft[0] = primaryDriver;
        });

        this.setState(nextState);
    }

}
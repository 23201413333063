import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbActiveModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AddressValidationModalComponent } from './components/address-validation-modal/address-validation-modal.component';
import { AlertmodalComponent } from './components/alertmodal/alertmodal.component';
import { ConfirmationModalComponent } from './components/confirmation-modal/confirmation-modal.component';
import { DeclinedPolicyModalComponent } from './components/declined-policy-modal/declined-policy-modal.component';
import { ErrortextComponent } from './components/errortext/errortext.component';
import { HelptextComponent } from './components/helptext/helptext/helptext.component';
import { StatePrinterComponent } from './components/state-printer/state-printer.component';
import { TechnicalErrorComponent } from './components/technical-error/technical-error.component';
import { UpperCaseDirective } from './directives/upper-case.directive';
import { httpInterceptorProviders } from './interceptors';
import { NoCopyPaste } from './directives/no-copy-paste';
import { SessionTimeoutWarningModalComponent } from './components/session-timeout-warning-modal/session-timeout-warning-modal.component';
import { ForceSelectionDirective } from './directives/force-selection.directive';
import { NBMoratoriumHold } from './components/nb-moratorium-hold-modal/nb-moratorium-hold-modal.component';

@NgModule({
    declarations: [
        StatePrinterComponent,
        HelptextComponent,
        AlertmodalComponent,
        ErrortextComponent,
        TechnicalErrorComponent,
        UpperCaseDirective,
        AddressValidationModalComponent,
        ConfirmationModalComponent,
        DeclinedPolicyModalComponent,
        NoCopyPaste,
        SessionTimeoutWarningModalComponent,
        ForceSelectionDirective,
        NBMoratoriumHold
    ],
    imports: [
        CommonModule,
        NgbModule
    ],
    exports: [
        StatePrinterComponent,
        HelptextComponent,
        AlertmodalComponent,
        ErrortextComponent,
        UpperCaseDirective,
        NoCopyPaste,
        ForceSelectionDirective
    ],
    providers: [
        NgbActiveModal,
        httpInterceptorProviders
    ]
})
export class SharedModule { }

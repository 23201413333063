import {Component} from '@angular/core';
import {AddressesFacade} from '../../stores/addresses-facade';
import {AgentFacade} from '../../stores/agent-facade';
import {CoveragesFacade} from '../../stores/coverages-facade';
import {DiscountsFacade} from '../../stores/discounts-facade';
import {DriversFacade} from '../../stores/drivers-facade';
import {EligibilitiesFacade} from '../../stores/eligibilities-facade';
import {IncidentsFacade} from '../../stores/incidents-facade';
import {PaymentFacade} from '../../stores/payment-facade';
import {PolicyFacade} from '../../stores/policy-facade';
import {PrefillVehiclesFacade} from '../../stores/prefillvehicles-facade';
import {PremiumsFacade} from '../../stores/premiums-facade';
import {RetrieveFacade} from '../../stores/retrieve-facade';
import {UIFacade} from '../../stores/ui-facade';
import {VehiclesFacade} from '../../stores/vehicles-facade';
import {EsignFacade} from '../../stores/esign-facade';
import {QuotePageFacade} from "../../stores/quote-page-facade";
import {AuthorizationFacade} from '../../stores/authorization-facade';

@Component({
    selector: 'app-state-printer',
    template: `
        <p class="small">
            <b>UI:</b> : {{ this.ui$ | async | json }} <br><br>
            <b>Agent</b> : {{ this.agent$ | async | json }}<br><br>
            <b>Policy</b> : {{ this.policy$ | async | json}}<br><br>
            <b>Addresses</b> : {{ this.addresses$ | async | json }}<br><br>
            <b>Drivers</b> : {{ this.drivers$ | async | json }}<br><br>
            <b>Incidents</b> : {{ this.incidents$ | async | json }}<br><br>
            <b>PrefillVehicles</b> : {{ this.prefillVehicles$ | async | json }}<br><br>
            <b>Vehicles</b> : {{ this.vehicles$ | async | json }}<br><br>
            <b>Coverages</b> : {{ this.coverages$ | async | json }}<br><br>
            <b>Premiums</b> : {{ this.premiums$ | async | json }}<br><br>
            <b>Discounts</b> : {{ this.discounts$ | async | json }}<br><br>
            <b>Eligibilities</b> : {{ this.eligibilities$ | async | json }}<br><br>
            <b>Payment</b> : {{ this.payment$ | async | json }}<br><br>
            <b>Retrieves</b> : {{ this.retrieves$ | async | json }}<br><br>
            <b>Esign</b> : {{ this.esign$ | async | json }}<br><br>
            <b>Quote Page</b> : {{ this.quotePage$ | async | json }}
        </p>
    `
})
export class StatePrinterComponent {
    agent$ = this.agentFacade.state;
    policy$ = this.policyFacade.state;
    addresses$ = this.addressesFacade.state;
    drivers$ = this.driversFacade.state;
    incidents$ = this.incidentsFacade.state;
    vehicles$ = this.vehiclesFacade.state;
    coverages$ = this.coveragesFacade.state;
    premiums$ = this.premiumsFacade.state;
    discounts$ = this.discountsFacade.state;
    eligibilities$ = this.eligibilitiesFacade.state;
    payment$ = this.paymentFacade.state;
    prefillVehicles$ = this.prefillvehicleFacade.state;
    retrieves$ = this.retrieveFacade.state;
    ui$ = this.uiFacade.state;
    esign$ = this.esignFacade.state;
    auth$ = this.authorizationFacade.state;
    quotePage$ = this.quotePageFacade.state;

    constructor(
        private agentFacade: AgentFacade,
        private policyFacade: PolicyFacade,
        private addressesFacade: AddressesFacade,
        private driversFacade: DriversFacade,
        private incidentsFacade: IncidentsFacade,
        private vehiclesFacade: VehiclesFacade,
        private coveragesFacade: CoveragesFacade,
        private premiumsFacade: PremiumsFacade,
        private discountsFacade: DiscountsFacade,
        private eligibilitiesFacade: EligibilitiesFacade,
        private paymentFacade: PaymentFacade,
        private prefillvehicleFacade: PrefillVehiclesFacade,
        private retrieveFacade: RetrieveFacade,
        private uiFacade: UIFacade,
        private esignFacade: EsignFacade,
        private authorizationFacade: AuthorizationFacade,
        private quotePageFacade: QuotePageFacade
    ) {
    }

}
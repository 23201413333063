import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { map, pluck } from 'rxjs/operators';
import { IIncident } from '../model/incident';
import { IPaymentOption } from '../model/payment';
import { AGGREGATOR_INCIDENTS_BY_DRIVER_SEQ_NUM_PATH, AGGREGATOR_INCIDENTS_IGNORED_DRIVER_SEQ_NUMS_PATH, IUIState } from '../model/ui-state';
import { Facade } from './facade';
import { UIStore } from './ui-store';

@Injectable({ providedIn: 'root' })
export class UIFacade extends Facade<IUIState> {

    constructor(protected store: UIStore) {
        super(store);
    }

    getInitialPaymentOptions(): Observable<IPaymentOption[]> {
        return this.store.state$.pipe(pluck('initialPaymentOptions'));
    }

    resetAggregatorIncidents(byDriverSequenceNumber: {[driverSeqNum: number]: IIncident[]} = {}): void {
        this.store.updateAggregatorIncidents(byDriverSequenceNumber);
        this.store.updateAggregatorIncidentsIgnoredDriverSequenceNumbers([]);
    }

    findAggregatorIncidentsByDriverSequenceNumber(driverSeqNum: number): Observable<IIncident[]> {
        return this.store.state$.pipe(
            map((uiState) => {
                const ignoreDriverAggregatorIncidents = _.get(uiState, AGGREGATOR_INCIDENTS_IGNORED_DRIVER_SEQ_NUMS_PATH, []).indexOf(driverSeqNum) !== -1;
                return ignoreDriverAggregatorIncidents ? [] : _.get(uiState, `${AGGREGATOR_INCIDENTS_BY_DRIVER_SEQ_NUM_PATH}.${driverSeqNum}`, []);
            })
        );
    }

    addToAggregatorIncidentsIgnoredDriverSequenceNumbers(driverSeqNumber: number): void {
        this.store.addToAggregatorIncidentsIgnoredDriverSequenceNumbers(driverSeqNumber);
    }

    removeInitialPaymentOptions(): void {
        this.store.removeInitialPaymentOptions();
    }

    addSuspiciousFormFieldAction(actionKey: string): void {
        this.store.addSuspiciousFormFieldAction(actionKey);
    }

}
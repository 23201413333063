<div class="modal-header">
  <h5 class="modal-title">Address Verification</h5>
</div>
<div class="modal-body">
  <h6 class="title">According to the US Postal Service, your address does not appear to be in the correct format.</h6>
  <div class="row">
    <div class="col-sm-6 col-12">
      <div class="address-window">
        <h6><strong>Suggested Address</strong></h6>
        <address>
          {{suggestedAddress?.address1 | uppercase}}<br>
          <div *ngIf="suggestedAddress?.address2">{{suggestedAddress?.address2 | uppercase }}</div>
          {{suggestedAddress?.city | uppercase }} <br>
          {{suggestedAddress?.state}}<br>
          {{suggestedAddress?.zip}}
        </address>
        <div *ngIf="originalAddress?.address2">
          <br>
        </div>
        <button class="next-btn addr-btn" data-dismiss="modal" (click)="onUseCorrectAddressClick()">Use USPS Address
        </button>
      </div>
    </div>
    <div class="col-sm-6 col-12">
      <div class="address-window">
        <h6> <strong>Address You Provided</strong></h6>
        <address>
          {{originalAddress?.address1 | uppercase}}<br>
          <div *ngIf="originalAddress?.address2">{{originalAddress?.address2 | uppercase}}</div>
          {{originalAddress?.city | uppercase}} <br>
          {{originalAddress?.state}}<br>
          {{originalAddress?.zip}}
        </address>
        <button class="back-btn addr-btn" data-dismiss="modal" (click)="onUseOriginalAddressClick()">Use Original
          Address
        </button>
      </div>
    </div>
  </div>
</div>

import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-alertmodal',
  templateUrl: './alertmodal.component.html',
  styleUrls: ['./alertmodal.component.scss']
})
export class AlertmodalComponent {
  @Input() title: string;
  @Input() text: string;
  @Input() hideCloseButton: boolean;
  @Input() showOKButton: boolean;

  constructor(public activeModal: NgbActiveModal) { }

}

import { Injectable } from '@angular/core';
import { IEsign } from '../model/esign';
import { StorageBackedStore } from './storage-backed-store';

@Injectable({ providedIn : 'root'})
export class ESignStore extends StorageBackedStore<IEsign> {

    constructor() {
        super('esignInfo', {});
    }

    setEsign(esign: IEsign): void {
        this.setState({...esign});
    }
}
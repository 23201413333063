import {HttpClient, HttpClientModule} from '@angular/common/http';
import {ErrorHandler, NgModule,APP_INITIALIZER} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {MissingTranslationHandler, TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {UserIdleModule} from 'angular-user-idle';
import {CookieService} from 'ngx-cookie-service';
import {DeviceDetectorModule} from 'ngx-device-detector';
import {LoggerModule} from 'ngx-logger';
import {environment} from '../environments/environment';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {FooterComponent} from './root/components/footer/footer.component';
import {HeaderComponent} from './root/components/header/header.component';
import {GlobalErrorHandler} from './root/error-handler/global-error-handler';
import * as logger from './root/logger/index';
import {BlankForMissingTranslationHandler} from './shared/services/blank-for-missing-translation-handler';
import {createTranslateLoader} from './shared/services/translate-loader-factory';
import {SharedModule} from './shared/shared.module';
import {UtilsModule} from './utils/utils.module';
import {Referrer} from './shared/model/referrer';

@NgModule({
  declarations: [AppComponent, HeaderComponent, FooterComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    SharedModule,
    UtilsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      },
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: BlankForMissingTranslationHandler
      }
    }),
    LoggerModule.forRoot(logger.loggerConfig),
    DeviceDetectorModule.forRoot(),
    UserIdleModule.forRoot({ idle: environment.sessionExpTime, timeout: environment.sessionExpWarningTime, ping: 10 })
  ],

  providers: [
    {
      provide: APP_INITIALIZER,
      multi: true,
      useFactory: () => () => {
        // 93752: Save the document.referrer in the Referrer object before any router navigation occurs, since IE does
        // not preserve document.referrer when calling history.replaceState like other browsers.
        // (see https://github.com/angular/angular/issues/38234#issuecomment-668767366). Any component that wants to
        // get the correct value of document.referrer should then call Referrer.of() instead of directly calling
        // document.referrer.
        Referrer.init();
      }
    },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    CookieService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

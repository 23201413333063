import { Injectable } from '@angular/core';
import { IRetrieve } from '../model/retrieve';
import { StorageBackedStore } from './storage-backed-store';

@Injectable({ providedIn : 'root'})
export class RetrieveStore extends StorageBackedStore<IRetrieve[]> {
    constructor() {
        super('retrieves', []);
    }

    insertRetrieves(retrieves: IRetrieve[]) {
       this.setState(retrieves);
    }

}
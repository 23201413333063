import * as _ from 'lodash';

export enum VehicleSource {
    RMV = 'RMV',
    AGGR = 'AGR',
    MANUAL = 'MAN',
    MVR = 'MVR'
}

export enum PrimaryUse {
    PERSONAL = 'PU',
    BUSINESS = 'BU'
}

export enum PurchaseMethod {
   PAID_FOR = 'PF',
   FINANCED = 'LH',
   LEASED = 'LC'
}

export enum SubClass {
    SPORT_OFF_ROAD = 'SPORT OFF ROAD',
    UTILITY = 'UTILITY',
    TRIKE = 'TRIKE',
    ATV = 'ATV',
    SIDECAR = 'SIDECAR',
    AUTOCYCLE = 'AUTOCYCLE',
    SNOWMOBILE = 'SNOWMOBILE',
    UTV = 'UTV'
}

export enum Engine {
    ELECTRIC = 'ELECTRIC'
}

export const MOPED_CC_MAX_LIMIT = 50;
export const MIN_CC = 1;
export const MAX_CC = 5000;

export interface IVehicle {
    vehicleId?: number;
    seqNum?: number; // server-generated
    primaryDriverId?: number; // Map to driver id of 'Who drives this vehicle the most?'
    source?: VehicleSource;
    make?: string; // HLDI
    makeDescription?: string; // HLDI
    body?: string; // HLDI
    cost?: number; // HLDI
    highTheftIndicator?: string;
    vehicleTypeCode?: string; // HLDI
    model?: string; // HLDI
    symbol?: string; // HLDI
    year?: number; // HLDI
    weight?: number; // HLDI
    safetyRestraintType?: string;
    antiLockBrakesCode?: string; // HLDI
    antiLockBrakesIndicator?: string; // Y/N/null Map to anti-lock brakes question in vehicle details
    daytimeRunningLightsCode?: string;
    daytimeRunningLightsIndicator?: string; // Y/N/null Map to daytime running lights question in vehicle details
    primaryUse?: PrimaryUse; // Map to 'How is this vehicle used?' question in vehicle details. Always PERSONAL for motorcycle.
    businessUse?: string; // Map to 'Type of Business' question in vehicle details. Not relevant to motorcycle - always null.
    purchaseMethod?: string | PurchaseMethod; // Map to 'Ownership Status' question in vehicle details
    originalOwnerIndicator?: string; // Map to 'Are you the original owner/lessee?' question in vehicle details
    annualMileage?: string;
    vehicleThirdPartyId?: number | null; // The associated vehicle third party id if vehicle is a prefill vehicle
    antiTheftDevices?: string[]; // Map to list of anti-theft device codes that are checked in vehicle details
    tncUsageIndicator?: string; // Map to 'Is this vehicle used to provide Transportation Network Services' question in vehicle details
    tncVehicleDriverName?: string; // Map to 'Which driver in your household uses this vehicle for a Transportation Network?' in vehicle details
    tncVehicleCoverageVersion?: string;
    vinNumber?: string; // Map to VIN number
    additionalInterestId?: number; // generated from server
    additionalInterestAddressId?: number; // Map to id of additional interest address in Addresses store
    loanLeaseInfo?: string; // Map to selected value in 'Loan and/or Leasing Information' question
    loanLeaseOtherInterestName?: string; // Map to text/label of the selected entry in 'Loan and/or Leasing Information' question
    altGaragingIndicator?: string; // Map to 'Is this vehicle garaged at a location other than your residence?'
    garagingAddressId?: number; // generated from server

    // Motorcycle-specific fields
    displacement?: number; // Map to HLDI (i.e. motorcycle lookup) response
    usedForRacingOrBusiness?: string; // [Y/N] Map to 'Is this vehicle used for racing or business?'
    isMoped?: string; // [Y/N/null] Map to 'Is this a moped?'
    isTrike?: string; // [Y/N/null] Map to 'Is this a trike?'
    trikeConversionKit?: string; // [Dropdown value from TRIKE_CONVERSION_KITS] Map to 'What trike conversion kit was used?'
    isSalvageOrHomeConstructed?: string; // [Y/N] Map to 'Is this a salvage or is it home-constructed?'
    wheelCount?: number; // [Dropdown value] Map to 'How many wheels does this vehicle have'
    passengerCapacity?: number; // [Dropdown value] Map to 'Including the driver, how many people can this vehicle accommodate?'
    hasDumpBed?: string; // [Y/N/null] Map to 'Does this vehicle have a dump bed?'
    hasAntiTheftDevice?: string; // [Y/N] 'Map to Does this vehicle have an anti-theft device?'
    subClassId?: number; // HLDI
    subClass?: string; // HLDI
    engine?: string;
    // End of motorcycle-specific fields
}

export function vehicleHasDetails(vehicle: IVehicle): boolean {
    // Vehicle.usedForRacingOrBusiness is required for all states and channels, so we can use it as a basis
    // for determining whether the vehicle has details. If usedForRacingOrBusiness becomes optional,
    // this solution needs to be re-visited
    return vehicle && !!vehicle.usedForRacingOrBusiness;
}

export function isUtilityVehicle(vehicle: IVehicle): boolean {
    return _.toUpper(_.trim(vehicle.subClass)) === SubClass.UTILITY;
}

export function isMotorcycle(vehicle: IVehicle): boolean {
    return !isATVOrUTV(vehicle);
}

export function isDisplacementCCLessThan(vehicle: IVehicle, maxCCLimit: number): boolean {
    return vehicle && vehicle.displacement && vehicle.displacement > 0 && vehicle.displacement < maxCCLimit;
}

export function isFinancedOrLeased(vehicle: IVehicle): boolean {
    return vehicle && vehicle.purchaseMethod === PurchaseMethod.FINANCED || vehicle.purchaseMethod === PurchaseMethod.LEASED;
}

export function isATVOrUTV(vehicle: IVehicle): boolean {
    return vehicle && (
        _.toUpper(_.trim(vehicle.subClass)) === SubClass.ATV
        || _.toUpper(_.trim(vehicle.subClass)) === SubClass.UTV
        || _.toUpper(_.trim(vehicle.subClass)) === SubClass.SPORT_OFF_ROAD
        || isUtilityVehicle(vehicle)
    );
}

export function isElectric(vehicle: IVehicle): boolean {
    return vehicle && _.toUpper(_.trim(vehicle.engine)) === Engine.ELECTRIC;
}
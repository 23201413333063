<footer class="footer">
  <div class="col-12">
    <nav class="navbar navbar-expand-md navbar-dark">
      <ul class="nav navbar-nav">
        <li class="nav-item active">
          <a class="nav-link" target="_blank" href="https://www.facebook.com/PlymouthRockAssurance/">
            <span> <img src="assets/svgs/fb.svg"></span>
          </a>
        </li>
        <li class="nav-item active">
          <a class="nav-link" target="_blank" href="https://twitter.com/plymouthrock">
            <span> <img src="assets/svgs/twitter.svg"></span>
          </a>
        </li>
        <li class="nav-item active">
          <a class="nav-link" target="_blank" href="https://www.instagram.com/plymouthrockassurance/">
            <span> <img src="assets/svgs/insta.svg"></span>
          </a>
        </li>
        <li class="nav-item active">
          <a class="nav-link" target="_blank" href="https://www.plymouthrock.com/blog">
            <span> <img src="assets/svgs/blog.svg"></span>
          </a>
        </li>
      </ul>
    </nav>
  </div>
  <div class="footer-links">
    <ul>
      <li>
        <a href="https://www.plymouthrock.com/privacy-policy" target="popup"
          onclick="window.open('https://www.plymouthrock.com/privacy-policy','popup','width=900,height=700,scrollbars=yes,resizable=yes'); return false;">
          Privacy & Security
        </a>
      </li>
      <li>
        <a href="https://www.plymouthrock.com/internet-policy" target="popup"
          onclick="window.open('https://www.plymouthrock.com/internet-policy','popup','width=900,height=700,scrollbars=yes,resizable=yes'); return false;">
          Terms & Conditions
        </a>
      </li>
      <li class="copy-right">
        &#169; {{year}} Plymouth Rock Assurance. All Rights Reserved.
      </li>
    </ul>
  </div>
  <span class="version">V:{{version}}</span>
</footer>

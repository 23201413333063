import * as _ from 'lodash';

// tslint:disable: function-name
export class StringUtils {

    /**
     * Prepends the prefix to the start of the string if the string does not already start with the prefix.
     * @param str The string
     * @param prefix The prefix to prepend to the start of the string
     */
    static prependIfMissing(str: string, prefix: string) {
        if (!str || !prefix || _.startsWith(str, prefix)) {
            return str;
        }

        return prefix + str;
    }

    /**
     * Generates a random string of the given length
     * @param len the desired length of the random string, defaults to 10
     */
    static randomString(len = 10): string {
        // tslint:disable-next-line:prefer-array-literal no-bitwise insecure-random
        return [...Array(len)].map(() => (~~(Math.random() * 36)).toString(36)).join('');
    }

    /**
     * Replaces the character in str at the given index with the replacement
     * @param str the string
     * @param index the index of the character to replace
     * @param replacement the replacement character
     */
    static replaceCharAt(str: string, index, replacement: string) {
        return str.substring(0, index) + replacement + str.substring(index + 1);
    }
}
// tslint:enable: function-name
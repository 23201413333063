import {HttpHeaders} from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { DeviceDetectorService } from 'ngx-device-detector';
import { NGXLogger } from 'ngx-logger';
import {Observable} from 'rxjs';
import {map, take, tap} from 'rxjs/operators';
import {PolicyFacade} from '../../shared/stores/policy-facade';

/**
 * Facade for the underlying logger implementation
 */
@Injectable({ providedIn: 'root' })
export class Logger {

    constructor(
        private logger: NGXLogger,
        private deviceService: DeviceDetectorService,
        private policyFacade: PolicyFacade
    ) { }

    debug(message: any, ...additional: any[]): void {
        this.log('debug', message, ...additional);
    }

    info(message: any, ...additional: any[]): void {
        this.log('info', message, ...additional);
    }

    warn(message: any, ...additional: any[]): void {
        this.log('warn', message, ...additional);
    }

    error(message: any, ...additional: any[]): void {
        const deviceInfo = this.getDeviceInfo();

        if (!deviceInfo) {
            this.log('error', message, ...additional);
            return;
        }

        if (_.isObjectLike(message)) {
            this.log('error', deviceInfo, message, ...additional);
            return;
        }

        this.log('error', deviceInfo + ' ' + message, ...additional);
    }

    private getDeviceInfo(): string {
        const deviceInfo = this.deviceService.getDeviceInfo();

        return !!deviceInfo ? 'Device: ' + deviceInfo.device
            + ', OS: ' + deviceInfo.os
            + ', OS_Version: ' + deviceInfo.os_version
            + ', Browser: ' + deviceInfo.browser
            + ', Browser_version: ' + deviceInfo.browser_version : null;
    }

    private log(level: string, message: any, ...additional: any[]) {
        this.getLoggerHttpHeaders().pipe(
            take(1),
            tap((headers) => {
                if (headers) {
                    this.logger.setCustomHttpHeaders(headers);
                }
            }),
            tap(() => {
                switch (level) {
                    case 'debug': this.logger.debug(message, ...additional); return;
                    case 'info': this.logger.info(message, ...additional); return;
                    case 'warn': this.logger.warn(message, ...additional); return;
                    case 'error': this.logger.error(message, ...additional); return;
                }
            })
        ).subscribe();
    }

    private getLoggerHttpHeaders(): Observable<HttpHeaders> {
        return this.policyFacade.state.pipe(
            take(1),
            map((policy) => policy ? new HttpHeaders({qu: policy.policyNumber}) : null)
        );
    }
}
/* tslint:disable:underscore-consistent-invocation */
import {Injectable} from '@angular/core';
import * as _ from 'lodash';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {IVehicle} from '../model/vehicle';
import {Facade} from './facade';
import {PrefillVehiclesStore} from './prefillvehicles-store';

@Injectable({ providedIn: 'root' })
export class PrefillVehiclesFacade extends Facade<IVehicle[]> {

    constructor(protected store: PrefillVehiclesStore) {
        super(store);
    }

    insertVehicle(vehicle: IVehicle): void {
        this.store.addVehicle(vehicle);
    }

    findVehicleByVin(vinNumber: string): Observable<IVehicle> {
        return this.state.pipe(map((vehicles) => _.find(vehicles, {vinNumber})));
    }
}
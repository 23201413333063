import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';
import { IEligibility, EligibilityQuestion } from '../model/eligibility';
import { EligibilitiesStore } from './eligibilities-store';
import { Facade } from './facade';
@Injectable({ providedIn : 'root'})
export class EligibilitiesFacade extends Facade<IEligibility[]> {

    constructor(protected store: EligibilitiesStore) {
        super(store);
    }

    /**
     * Finds an existing eligibility in the Eligibilities store whose question is equal to the
     * given question and sets its answer field to the given answer. If there is no existing
     * eligibility in the store whose question is equal to the given question, a new eligibility 
     * with the given question and answer is added to the Eligibilities store.
     */
    upsertEligibilityAnswer(question: EligibilityQuestion, answer: string): void {
        this.findEligibilityByQuestion(question).pipe(
            take(1),
            tap((currentEligibility) => this.store.upsertEligibility({...(currentEligibility || {}), question, answer}))
        ).subscribe();
    }

    deleteByQuestion(question: any) {
        this.store.deleteByQuestion(question);
    }

    findEligibilityByQuestion(question: string): Observable<IEligibility> {
        return this.store.state$.pipe(
            map((eligibilities) => eligibilities.find((eligibility) =>
            eligibility.question === question))
        );
    }
}
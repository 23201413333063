import {Injectable} from '@angular/core';
import {IPayment} from '../model/payment';
import {StorageBackedStore} from './storage-backed-store';

@Injectable({ providedIn : 'root'})
export class PaymentStore extends StorageBackedStore<IPayment> {
    constructor() {
        super('payment', {
            billingAddressZip: null,
            cardNumber: null,
            cardholderName: null,
            debit: null,
            downPayment: null,
            expirationDate: null,
            futureInstallmentAutomaticWithdrawal: null,
            installmentsAccountNumber: null,
            installmentsPaymentMethod: null,
            installmentsRoutingNumber: null,
            paymentMethod: null,
            paymentPlan: null,
            securityCode: null,
            paymentId: null,
            saveCard: false
        });
    }
}
<div class="modal-header">
  <h5 class="modal-title">{{title}}</h5>
  <button *ngIf="!hideCloseButton" type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <p [innerHTML]="text"></p>
</div>
<div *ngIf="showOKButton" class="btn-container text-center">
  <button (click)="activeModal.dismiss()" class="next-btn ok-btn">OK</button>
</div>


import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {Driver, IDriverDiscounts, MARITAL_STATUS, RELATIONSHIP_TO_INSURED} from '../model/driver';
import {DriversStore} from './drivers-store';
import {Facade} from './facade';

@Injectable({ providedIn: 'root' })
export class DriversFacade extends Facade<Driver[]> {

    constructor(protected store: DriversStore) {
        super(store);
    }

    patch(value: Driver[]) {
        super.patch((value || []).map((d) => d instanceof Driver ? d : Object.assign(new Driver(), d)));
    }

    insertDriver(driver: Driver): void {
        this.store.insertDriver(driver);
    }

    updateDriverBySeqNum(seqNum: number, driver: Driver): void {
        this.store.updateDriverBySeqNum(seqNum, driver);
    }

    updateDriver(driver: Driver): void {
        this.store.updateDriver(driver);
    }

    updatePrimaryDriverMaritalStatus(s: MARITAL_STATUS) {
        this.store.updatePrimaryDriverMaritalStatus(s);
    }

    deleteDriver(driver: Driver): void {
        this.store.deleteDriver(driver);
    }

    insertDriverDiscounts(driverDiscount: IDriverDiscounts): void {
        this.store.insertDriverDiscounts(driverDiscount);
    }

    clearDriverDiscounts(): void {
        this.store.clearDriverDiscounts();
    }

    updatePrimaryDriverEmail(email: string): void {
        this.store.updatePrimaryDriverEmail(email);
    }

    findPrimaryInsured(): Observable<Driver> {
        return this.findDriverByRelationshipToInsured(RELATIONSHIP_TO_INSURED.PRIMARY_INSURED);
    }

    findDriverByRelationshipToInsured(relationshipToInsured: RELATIONSHIP_TO_INSURED): Observable<Driver> {
        return this.state.pipe(
            map((drivers) => drivers.find((driver) =>
                driver.relationshipToInsured === relationshipToInsured))
        );
    }

    findDriverBySeqNum(driverSeqNum: number): Observable<Driver> { // 1 primary driver 2,3,4... following drivers
        return this.state.pipe(
            map((drivers) => drivers.find((driver) =>
                driver.driverSeqNum === driverSeqNum))
        );
    }

    findByDriverId(driverId: number): Observable<Driver> {
        return this.state.pipe(
            map((drivers) => drivers.find((driver) => driver.id === driverId))
        );
    }
}